import React, { Component } from "react";
import { FcManager } from "react-icons/fc";
import { Main_Color } from "../../Theme//Theme";
import All from "./All/All";
import Worker from "./Worker/Worker";
import Customer from "./Customer/Customer";
import Route from "./Route/Route";

export default class Report extends Component {
  constructor() {
    super();
    this.state = {
      city: "",
      selected: 1,
    };
  }

  render() {
    return (
      <div style={{ flex: 1, backgroundColor: "#f2f2f2" }}>
        <div
          style={{
            display: "flex",
            height: "100px",
            backgroundColor: "#ffffff",
            flex: 1,
            alignItems: "center",
          }}
        >
          <div
            style={{
              display: "flex",
              padding: 20,
              color: Main_Color,
              fontSize: 18,
              paddingLeft: 40,
              flex: 1,
              alignItems: "center",
              justifyContent: "flex-start",
            }}
          >
            <FcManager style={{ fontSize: 55 }} />
            <div style={{ paddingLeft: 20, fontSize: 22, flex: 1 }}>Report</div>
            <div style={{ flex: 1, display: "flex", alignItems: "center" }}>
              <div
                style={{
                  flex: 1,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <div
                  onClick={() => this.setState({ selected: 1 })}
                  style={{
                    height: "40px",
                    width: "100px",
                    backgroundColor:
                      this.state.selected == 1 ? Main_Color : "#f2f2f2",
                    borderRadius: 10,
                    color: this.state.selected == 1 ? "#ffffff" : "#585858",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  All
                </div>
              </div>
              <div
                style={{
                  flex: 1,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <div
                  onClick={() => this.setState({ selected: 2 })}
                  style={{
                    height: "40px",
                    width: "100px",
                    backgroundColor:
                      this.state.selected == 2 ? Main_Color : "#f2f2f2",
                    borderRadius: 10,
                    color: this.state.selected == 2 ? "#ffffff" : "#585858",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  Worker
                </div>
              </div>
              <div
                style={{
                  flex: 1,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <div
                  onClick={() => this.setState({ selected: 3 })}
                  style={{
                    height: "40px",
                    width: "100px",
                    backgroundColor:
                      this.state.selected == 3 ? Main_Color : "#f2f2f2",
                    borderRadius: 10,
                    color: this.state.selected == 3 ? "#ffffff" : "#585858",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  Customer
                </div>
              </div>
              {/* <div
                style={{
                  flex: 1,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <div
                  onClick={() => this.setState({ selected: 4 })}
                  style={{
                    height: "40px",
                    width: "100px",
                    backgroundColor:
                      this.state.selected == 4 ? Main_Color : "#f2f2f2",
                    borderRadius: 10,
                    color: this.state.selected == 4 ? "#ffffff" : "#585858",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  Route
                </div>
              </div> */}
            </div>
          </div>
        </div>
        <div style={{ padding: 10 }}>
          <div
            style={{
              flex: 1,
              height: "83vh",
              borderRadius: 10,
              overflowY: "scroll",
            }}
          >
            {this.state.selected == 1 ? <All /> : null}
            {this.state.selected == 2 ? <Worker /> : null}
            {this.state.selected == 3 ? <Customer /> : null}
            {this.state.selected == 4 ? <Route /> : null}
          </div>
        </div>
      </div>
    );
  }
}
