import React, { useEffect } from "react";
import { AiFillCloseCircle, AiOutlineSortAscending } from "react-icons/ai";
import { FiFilter } from "react-icons/fi";
import "./All.css";
import { Main_Color } from "../../../Theme/Theme";
import Backend from "../../../Backend/Backend";
import { Calendar } from "primereact/calendar";
import AllCard from "./AllCard";
import SummaryCard from "./SummaryCard";
import moment from "moment";
import * as XLSX from "xlsx";
import { useAlert } from "react-alert";
import { InputText } from "primereact/inputtext";
const backend = new Backend();

const All = () => {
  const [filter, setFilter] = React.useState(false);
  const [filter_id, setFilter_type] = React.useState(0);
  const [loading, setLoading] = React.useState(false);
  const [start, setStart] = React.useState(
    moment(new Date()).format("YYYY-MM-DD 00:00:00")
  );
  const [end, setEnd] = React.useState(
    moment(new Date()).format("YYYY-MM-DD 23:59:59")
  );
  const [data, setData] = React.useState([]);
  const [data_search, setData_Search] = React.useState([]);
  const [data_cash, setData_Cash] = React.useState([]);
  const [data_card, setData_Card] = React.useState([]);
  const [data_payment, setData_Payment] = React.useState([]);

  const [total, setTotal] = React.useState(0);
  const [summary, setSummay] = React.useState(false);
  const [summarydata, setSummayData] = React.useState([]);

  const [month, setMonth] = React.useState("");
  const [sort, setSort] = React.useState("");
  const [search, setSearch] = React.useState("");

  const alert = useAlert();
  useEffect(() => {
    loadconent();
  }, []);

  const loadconent = (a) => {
    var startdata, enddata;
    setMonth(a);
    if (a == "Today") {
      startdata = moment(new Date()).format("YYYY-MM-DD 00:00:00");
      enddata = moment(new Date()).format("YYYY-MM-DD 23:59:59");
      setStart(startdata);
      setEnd(enddata);
    } else if (a == "Yesterday") {
      startdata = moment().add(-1, "days").format("YYYY-MM-DD 00:00:00");
      enddata = moment().add(-1, "days").format("YYYY-MM-DD 23:59:59");
      setStart(startdata);
      setEnd(enddata);
    } else if (a == "Last 7 Days") {
      startdata = moment(new Date()).format("YYYY-MM-DD 00:00:00");
      enddata = moment().add(-7, "days").format("YYYY-MM-DD 23:59:59");
      setStart(startdata);
      setEnd(enddata);
    } else if (a == "This Month") {
      startdata = moment().startOf("month").format("YYYY-MM-DD 00:00:00");
      enddata = moment().endOf("month").format("YYYY-MM-DD 23:59:59");
      setStart(startdata);
      setEnd(enddata);
    } else if (a == "Last Month") {
      let lastmonth = moment(new Date())
        .subtract(1, "months")
        .format("YYYY-MM-DD");
      startdata = moment(lastmonth)
        .startOf("month")
        .format("YYYY-MM-DD 00:00:00");
      enddata = moment(lastmonth).endOf("month").format("YYYY-MM-DD 23:59:59");

      setStart(startdata);
      setEnd(enddata);
    } else if (a == "This Year") {
      startdata = moment(new Date())
        .subtract(1, "year")
        .format("YYYY-MM-DD 00:00:00");
      enddata = moment(new Date())
        .subtract(0, "year")
        .format("YYYY-MM-DD 23:59:59");
      setStart(startdata);
      setEnd(enddata);
    }

    setLoading(true);
    let data = {
      token: localStorage.getItem("token"),
      admin_id: localStorage.getItem("adminid"),
      datefrom: a != undefined ? startdata : start,
      dateto: a != undefined ? enddata : end,
    };
    backend
      .load_report(data)
      .then((r) => {
        var total = 0;
        r.data.map((x) => (total = parseFloat(total) + parseFloat(x.total)));

        var data_cash = r.data.filter(
          (x) => x.transaction_type == 0 && x.cash == 1
        );
        var data_card = r.data.filter(
          (x) => x.transaction_type == 0 && x.cash == 0
        );
        var payment_temp = 0;
        var data_payment = r.data.filter((x) => x.transaction_type == 1);
        data_payment.map(
          (x) => (payment_temp = parseFloat(payment_temp) + parseFloat(x.total))
        );

        var new_total = parseFloat(total) - parseFloat(payment_temp);

        setData(r.data);
        setData_Search(r.data);
        setData_Cash(data_cash);
        setData_Card(data_card);
        setData_Payment(data_payment);
        setSummayData(r.overview);
        setLoading(false);
        setTotal(new_total);
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  const filterarray = (a) => {
    if (a == "ALL") {
      setFilter_type(0);
      var total = 0;
      data_search.map((x) => (total = parseFloat(total) + parseFloat(x.total)));
      setData(data_search);
      setTotal(total);
    }
    if (a == "CASH") {
      setFilter_type(1);
      var total = 0;
      data_cash.map((x) => (total = parseFloat(total) + parseFloat(x.total)));
      setData(data_cash);
      setTotal(total);
    }
    if (a == "PAYMENT") {
      setFilter_type(3);
      var total = 0;
      data_payment.map(
        (x) => (total = parseFloat(total) + parseFloat(x.total))
      );
      setData(data_payment);
      setTotal(total);
    }
    if (a == "CARD") {
      setFilter_type(2);
      var total = 0;
      data_card.map((x) => (total = parseFloat(total) + parseFloat(x.total)));
      setData(data_card);
      setTotal(total);
    }
  };
  const Excel_donwload = () => {
    if (data.length > 0) {
      let datas = data;

      datas.map((i, t) => {
        delete datas[t].id;
        delete datas[t].admin_id;
        delete datas[t].worker_id;
        datas[t].Id = datas[t].customer_id;
        datas[t].Name = datas[t].name;
        datas[t].Date = moment(datas[t].date_created).format(
          "DD-MM-YYYY hh:mm"
        );
        datas[t].Total = datas[t].total;
        delete datas[t].customer_id;
        delete datas[t].name;
        delete datas[t].total;
        delete datas[t].cash;
        delete datas[t].date_created;
        delete datas[t].millisecond;
        delete datas[t].remarks;
        delete datas[t].route_id;
        delete datas[t].status;
        delete datas[t].transaction_type;
        delete datas[t].wname;
        delete datas[t].items;
      });

      var wb = XLSX.utils.book_new();
      var ws = XLSX.utils.json_to_sheet(datas);

      XLSX.utils.book_append_sheet(wb, ws, "All");

      XLSX.writeFile(wb, `All ${moment(new Date()).format("DD-MM-YYYY")}.xlsx`);
    } else {
      alert.error("No Report Found");
    }
  };

  // const Sorting = (a) => {
  //   if (a == "Id") {
  //     var newarry = [];
  //     setData(newarry);

  //     newarry = data_search.sort(function (a, b) {
  //       var x = a.customer_id;
  //       var y = b.customer_id;
  //       return x < y ? -1 : x > y ? 1 : 0;
  //     });
  //     setTimeout(() => {
  //       setData(newarry);
  //     }, 10);

  //     return false;
  //   } else if (a == "Name") {
  //     var newarry = [];
  //     setData(newarry);

  //     newarry = data_search.sort(function (a, b) {
  //       var x = a.name;
  //       var y = b.name;
  //       return x < y ? -1 : x > y ? 1 : 0;
  //     });
  //     setTimeout(() => {
  //       setData(data_sort);
  //     }, 10);
  //     return false;
  //   } else if (a == "Delivery") {
  //     var newarry = [];
  //     setData(newarry);

  //     newarry = data_search.sort(function (a, b) {
  //       var x = a.wname;
  //       var y = b.wname;
  //       return x < y ? -1 : x > y ? 1 : 0;
  //     });
  //     setTimeout(() => {
  //       setData(newarry);
  //     }, 10);
  //     return false;
  //   } else if (a == "Date") {
  //     var newarry = [];
  //     setData(newarry);
  //     newarry = data_search.sort(function (a, b) {
  //       var x = a.date_created;
  //       var y = b.date_created;
  //       return x < y ? -1 : x > y ? 1 : 0;
  //     });
  //     setTimeout(() => {
  //       setData(newarry);
  //     }, 10);
  //     return false;
  //   }
  // };

  const onSearch = (e) => {
    setSearch(e);
    if (filter_id == 0) {
      if (e.length > 1) {
        let filteredData = [];
        for (var i = 0; i < data_search.length; i++) {
          if (data_search[i].name.toLowerCase().includes(e.toLowerCase())) {
            filteredData.push(data_search[i]);
          } else if (
            data_search[i].customer_id.toLowerCase().includes(e.toLowerCase())
          ) {
            filteredData.push(data_search[i]);
          } else if (
            data_search[i].total.toLowerCase().includes(e.toLowerCase())
          ) {
            filteredData.push(data_search[i]);
          }
        }
        setData(filteredData);
      } else {
        setData(data);
      }
    } else if (filter_id == 1) {
      if (e.length > 1) {
        let filteredData = [];
        for (var i = 0; i < data_cash.length; i++) {
          if (data_cash[i].name.toLowerCase().includes(e.toLowerCase())) {
            filteredData.push(data_cash[i]);
          } else if (
            data_cash[i].customer_id.toLowerCase().includes(e.toLowerCase())
          ) {
            filteredData.push(data_cash[i]);
          } else if (
            data_cash[i].total.toLowerCase().includes(e.toLowerCase())
          ) {
            filteredData.push(data_cash[i]);
          }
        }
        setData(filteredData);
      } else {
        setData(data_cash);
      }
    } else if (filter_id == 2) {
      if (e.length > 1) {
        let filteredData = [];
        for (var i = 0; i < data_card.length; i++) {
          if (data_card[i].name.toLowerCase().includes(e.toLowerCase())) {
            filteredData.push(data_card[i]);
          } else if (
            data_card[i].customer_id.toLowerCase().includes(e.toLowerCase())
          ) {
            filteredData.push(data_card[i]);
          } else if (
            data_card[i].total.toLowerCase().includes(e.toLowerCase())
          ) {
            filteredData.push(data_card[i]);
          }
        }
        setData(filteredData);
      } else {
        setData(data_card);
      }
    } else if (filter_id == 3) {
      if (e.length > 1) {
        let filteredData = [];
        for (var i = 0; i < data_payment.length; i++) {
          if (data_payment[i].name.toLowerCase().includes(e.toLowerCase())) {
            filteredData.push(data_payment[i]);
          } else if (
            data_payment[i].customer_id.toLowerCase().includes(e.toLowerCase())
          ) {
            filteredData.push(data_payment[i]);
          } else if (
            data_payment[i].total.toLowerCase().includes(e.toLowerCase())
          ) {
            filteredData.push(data_payment[i]);
          }
        }
        setData(filteredData);
      } else {
        setData(data_payment);
      }
    }
  };

  return (
    <div>
      {filter == true ? (
        <div
          style={{
            height: "55px",
            backgroundColor: "#ffffff",
            display: "flex",
            justifyContent: "space-around",
            alignItems: "center",
            borderRadius: 10,
          }}
        >
          <div style={{ display: "flex", alignItems: "center" }}>
            <div style={{ paddingRight: 10 }}>Start Date</div>
            <div>
              <Calendar
                style={{ height: "40px" }}
                dateFormat="dd/mm/yy"
                value={start}
                onChange={(e) => {
                  setStart(moment(e.value).format("YYYY-MM-DD 00:00:00"));
                }}
                maxDate={new Date()}
              ></Calendar>
            </div>
          </div>
          <div style={{ display: "flex", alignItems: "center" }}>
            <div style={{ paddingRight: 10 }}>End Date</div>
            <div>
              <Calendar
                dateFormat="dd/mm/yy"
                style={{ height: "40px" }}
                onHide={() => loadconent()}
                value={end}
                onChange={(e) => {
                  setEnd(moment(e.value).format("YYYY-MM-DD 23:59:59"));
                }}
                maxDate={new Date()}
              ></Calendar>
            </div>
          </div>

          <div
            onClick={() => Excel_donwload()}
            style={{
              backgroundColor: "#ffffff",
              padding: 10,
              marginRight: 20,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              borderRadius: 10,
              marginTop: 5,
              height: "40px",
              width: "120px",
              borderStyle: "solid",
              borderColor: Main_Color,
            }}
          >
            <img
              src={require("../../../Image/excel.png")}
              style={{ height: "30px" }}
            />
            <div style={{ paddingLeft: 7 }}>Excel</div>
          </div>
          <div
            onClick={() => setFilter(false)}
            style={{
              backgroundColor: "#f2f2f2",
              padding: 7,
              borderRadius: 10,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <AiFillCloseCircle style={{ fontSize: 32, color: Main_Color }} />
          </div>
        </div>
      ) : (
        <div
          style={{
            height: "40px",
            backgroundColor: "#f2f2f2",
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
          }}
        >
          <div style={{ flex: 1, display: "flex" }}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                paddingLeft: "10px",
              }}
            >
              <div
                onClick={() => filterarray("ALL")}
                style={{
                  padding: 10,
                  backgroundColor: filter_id == 0 ? Main_Color : "#ffffff",
                  borderRadius: 10,
                  color: filter_id == 0 ? "#ffffff" : Main_Color,
                }}
              >
                ALL
              </div>
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                paddingLeft: "10px",
              }}
            >
              <div
                onClick={() => filterarray("CASH")}
                style={{
                  padding: 10,
                  backgroundColor: filter_id == 1 ? Main_Color : "#ffffff",
                  borderRadius: 10,
                  color: filter_id == 1 ? "#ffffff" : Main_Color,
                }}
              >
                CASH
              </div>
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                paddingLeft: "10px",
              }}
            >
              <div
                onClick={() => filterarray("CARD")}
                style={{
                  padding: 10,
                  backgroundColor: filter_id == 2 ? Main_Color : "#ffffff",
                  borderRadius: 10,
                  color: filter_id == 2 ? "#ffffff" : Main_Color,
                }}
              >
                CARD
              </div>
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                paddingLeft: "10px",
              }}
            >
              <div
                onClick={() => filterarray("PAYMENT")}
                style={{
                  padding: 10,
                  backgroundColor: filter_id == 3 ? Main_Color : "#ffffff",
                  borderRadius: 10,
                  color: filter_id == 3 ? "#ffffff" : Main_Color,
                }}
              >
                PAYMENTS
              </div>
            </div>
            <div class="dropdown">
              <div class="dropbtn">{month == null ? "Select" : month}</div>
              <div class="dropdown-content">
                <a onClick={() => loadconent("Today")}>Today</a>
                <a onClick={() => loadconent("Yesterday")}>Yesterday</a>
                <a onClick={() => loadconent("Last 7 Days")}>Last 7 Days</a>
                <a onClick={() => loadconent("This Month")}>This Month</a>
                <a onClick={() => loadconent("Last Month")}>Last Month</a>
                <a onClick={() => loadconent("This Year")}>This Year</a>
              </div>
            </div>
            {/* <div class="dropdown">
              <div class="dropbtn">
                {sort == null ? "Sort " : sort}
                <AiOutlineSortAscending />
              </div>
              <div class="dropdown-content">
                <a onClick={() => loadconent("This Month")}>
                  <AiOutlineSortAscending />
                  <div style={{ marginLeft: 5 }}>Customer Id</div>
                </a>
                <a onClick={() => loadconent("Last Month")}>
                  <AiOutlineSortAscending />{" "}
                  <div style={{ marginLeft: 5 }}>Customer Name</div>
                </a>
                <a onClick={() => loadconent("Last Month")}>
                  <AiOutlineSortAscending />{" "}
                  <div style={{ marginLeft: 5 }}>Delivery Boy</div>
                </a>
                <a onClick={() => loadconent("This Month")}>
                  <AiOutlineSortAscending />
                  <div style={{ marginLeft: 5 }}>Amount</div>
                </a>
              </div>
            </div> */}
          </div>
          <div
            onClick={() => setSummay(!summary)}
            style={{
              backgroundColor: Main_Color,
              padding: 10,
              marginRight: 20,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              borderRadius: 10,
              marginTop: 5,
              height: "40px",
              paddingLeft: "15px",
              paddingRight: "15px",
            }}
          >
            <div style={{ paddingLeft: 7, color: "#ffffff" }}>
              TOTAL - {parseFloat(total).toFixed(2)}
            </div>
          </div>
          <div
            onClick={() => setFilter(true)}
            style={{
              backgroundColor: "#ffffff",
              padding: 10,
              marginRight: 20,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              borderRadius: 10,
              marginTop: 5,
              height: "40px",
              width: "120px",
            }}
          >
            <FiFilter style={{ fontSize: 30, color: Main_Color }} />
            <div style={{ paddingLeft: 7 }}>Filter</div>
          </div>
        </div>
      )}

      <div
        style={{
          height: "55px",
          display: "flex",
          width: "100%",
          justifyContent: "flex-end",
          alignItems: "center",
        }}
      >
        <div
          style={{
            flex: 1,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div style={{ marginRight: "20px" }}>
            Start-Date : {moment(start).format("DD-MM-YYYY hh:ss")}
          </div>
          <div>End-Date : {moment(end).format("DD-MM-YYYY hh:ss")}</div>
        </div>
        <InputText
          placeholder="Search by id,name,amount"
          value={search}
          style={{
            width: "300px",
            borderRadius: 10,
            height: "40px",
            marginRight: "20px",
          }}
          onChange={(e) => onSearch(e.target.value)}
        />
      </div>
      {loading == true ? (
        <div
          style={{
            flex: 1,
            backgroundColor: "#fff",
            marginTop: 10,
            height: "78vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img src={require("../../../Image/loading.gif")} />
        </div>
      ) : data.length > 0 ? (
        <div style={{ display: "flex", flex: 1 }}>
          {summary ? (
            <div style={{ flex: 1, padding: 10 }}>
              <div
                className="summarycard"
                style={{
                  height: "74vh",
                  backgroundColor: "#ffffff",
                  borderRadius: 10,
                }}
              >
                <SummaryCard summarydata={summarydata} />
              </div>
            </div>
          ) : null}

          <div style={{ flex: 3 }}>
            {data.map((i) => {
              return <AllCard item={i} reload={() => loadconent()} />;
            })}
          </div>
        </div>
      ) : (
        <div
          style={{
            flex: 1,
            marginTop: 10,
            backgroundColor: "#ffffff",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "76vh",
          }}
        >
          <img
            src={require("../../../Image/noData.gif")}
            style={{ height: "50vh" }}
          />
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div style={{ fontWeight: "bold", color: "gray" }}>
              NO REPORT FOUND
            </div>
            <div
              onClick={() => loadconent()}
              style={{
                fontWeight: "bold",
                color: "gray",
                marginTop: 20,
                padding: 15,
                backgroundColor: Main_Color,
                borderRadius: 10,
                color: "#fff",
              }}
            >
              RELOAD
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default All;
