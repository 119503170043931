import React from "react";
import AddBottle from "./AddBottle";
export default function ProductCard(props) {
  const i = props.data;
  return (
    <div
      key={i.id}
      style={{
        height: 150,
        backgroundColor: "#f2f2f2",
        borderRadius: 10,
        marginBottom: 10,
        display: "flex",
        flexDirection: "row",
        flex: 1,
      }}
    >
      <div
        style={{
          flex: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <img
          src={i.image}
          height="100px"
          width="100px"
          style={{ backgroundColor: "#ffffff", padding: 10, borderRadius: 10 }}
        />
      </div>
      <div
        style={{
          flex: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <AddBottle data={i} />
      </div>
    </div>
  );
}
