import React from "react";
import { FiEdit } from "react-icons/fi";
import { FaProductHunt } from "react-icons/fa";
import { useAlert } from "react-alert";
import { HiDeviceTablet } from "react-icons/hi";
import Backend from "../../Backend/Backend";
import { Main_Color } from "../../Theme/Theme";
import ReactLoading from "react-loading";

const backend = new Backend();
const CaptainCard = (props) => {
  const i = props.item;

  const [loading, setLoading] = React.useState(false);

  const alert = useAlert();

  const clear_devices = () => {
    setLoading(true);
    var data = {
      token: localStorage.getItem("token"),
      admin_id: localStorage.getItem("adminid"),
      worker_id: i.id,
    };

    backend.clear_device(data).then((r) => {
      setLoading(false);
      if (r.error === "False") {
        alert.success(r.message);
      } else {
        alert.error(r.message);
      }
    });
  };

  return (
    <div key={i.id}>
      <div
        style={{
          display: "flex",
          flex: 1,
          backgroundColor: "#ffffff",
          justifyContent: "space-between",
          alignItems: "center",
          borderRadius: 10,
          padding: 10,
          marginTop: 10,
        }}
      >
        <div style={{ paddingLeft: 20, display: "flex", alignItems: "center" }}>
          <div style={{ paddingRight: 20, color: "#585858" }}>
            {props.index + 1}
          </div>
          <div>
            <div style={{ color: "#000000" }}>{i.name}</div>
            <div style={{ color: Main_Color, marginTop: 5 }}>{i.mobile}</div>
            <div
              style={{
                color: "#585858",
                marginTop: 5,
                fontWeight: "bold",
              }}
            >
              Route - {i.route == null || i.route == "" ? "All" : i.route}
            </div>
            <div
              style={{
                color: i.status == 0 ? "red" : "green",
                marginTop: 5,
                fontWeight: "bold",
              }}
            >
              {i.status == 0 ? "Deactive" : "Active"}
            </div>
          </div>
        </div>
        <div
          style={{
            marginRight: 40,
            display: "flex",
            justifyContent: "space-around",
            alignItems: "center",
          }}
        >
          <div
            onClick={() => props.edit_modal(i)}
            style={{
              height: "40px",
              width: "55px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <FiEdit style={{ fontSize: 30, color: "green" }} />
          </div>

          {loading === true ? (
            <div
              style={{
                height: "40px",
                width: "55px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <ReactLoading
                type={"spin"}
                color={Main_Color}
                height={30}
                width={30}
              />
            </div>
          ) : (
            <div
              onClick={clear_devices}
              style={{
                height: "40px",
                width: "55px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <HiDeviceTablet style={{ fontSize: 35, color: "red" }} />
            </div>
          )}
          <div
            onClick={() => props.product_modal(i)}
            style={{
              height: "40px",
              width: "55px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <FaProductHunt style={{ fontSize: 30, color: Main_Color }} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default CaptainCard;
