import React, { useEffect, useState } from "react";
import "./Add.css";
import { InputText } from "primereact/inputtext";
import { Main_Color } from "../../../Theme/Theme";
import { AiFillCloseCircle } from "react-icons/ai";
import Backend from "../../../Backend/Backend";
import ReactLoading from "react-loading";
import { useAlert } from "react-alert";
const backend = new Backend();
const Add = (props) => {
  const [name, setName] = useState("");
  const [unicode_name, setUnicode_name] = useState("");
  const [mobile, setMobile] = useState("");
  const [route, setRoute] = useState("");
  const [address, setAddress] = useState("");
  const [token, setToken] = useState(localStorage.getItem("token"));
  const [adminid, setAdminid] = useState(localStorage.getItem("adminid"));
  const [password, setPassword] = useState("");
  const [alert_type, setAlert_type] = useState("");
  const [loading, setLoading] = useState(false);
  const alert = useAlert();

  useEffect(() => {}, []);

  const _add_customer = () => {
    if (name === "") {
      setAlert_type(1);
      setTimeout(() => {
        setAlert_type(0);
      }, 5000);
    } else if (mobile === "") {
      setAlert_type(2);
      setTimeout(() => {
        setAlert_type(0);
      }, 5000);
    } else if (route === "") {
      setAlert_type(3);
      setTimeout(() => {
        setAlert_type(0);
      }, 5000);
    } else if (address === "") {
      setAlert_type(4);
      setTimeout(() => {
        setAlert_type(0);
      }, 5000);
    } else if (password === "") {
      setAlert_type(5);
      setTimeout(() => {
        setAlert_type(0);
      }, 5000);
    } else {
      let data = {
        token: token,
        admin_id: adminid,
        name: name,
        password: password,
        mobile: mobile,
        address: address,
        unicode: unicode_name,
        route: route,
      };
      setLoading(true);
      backend.add_customer(data).then((r2) => {
        setLoading(false);
        if (r2.error === "False") {
          props.close();
          setLoading(false);
        } else {
          alert.show(r2.message);
          setLoading(false);
        }
      });
    }
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flex: 1,
        height: "100vh",
      }}
    >
      <div
        style={{
          height: "95%",
          width: "60%",
          backgroundColor: "#f2f2f2",
          borderRadius: 10,
          display: "flex",
          alignSelf: "center",
          flexDirection: "column",
        }}
      >
        <div
          style={{
            padding: 20,
            justifyContent: "space-between",
            display: "flex",
            backgroundColor: Main_Color,
            borderTopLeftRadius: 10,
            borderTopRightRadius: 10,
            color: "#ffffff",
            alignItems: "center",
          }}
        >
          <div style={{ fontSize: 22 }}>Add Customer</div>
          <div onClick={() => props.close("a")}>
            <AiFillCloseCircle style={{ height: "40px", width: "40px" }} />
          </div>
        </div>

        <div style={{ display: "flex" }}>
          <div
            style={{
              flex: 1,
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div
              style={{
                padding: 10,
                justifyContent: "center",
                display: "flex",
                borderTopLeftRadius: 10,
                borderTopRightRadius: 10,
                color: "#ffffff",
              }}
            >
              <span style={{ display: "flex", flexDirection: "column" }}>
                <InputText
                  placeholder="Enter Customer Name"
                  value={name}
                  style={{
                    width: "500px",
                    marginTop: 5,
                    borderWidth: alert_type === 1 ? 2 : 1,
                    borderColor: alert_type === 1 ? "red" : "",
                  }}
                  onChange={(e) => setName(e.target.value)}
                />
                {alert_type === 1 ? (
                  <div style={{ marginTop: 5, paddingLeft: 10, color: "red" }}>
                    {"Invalid Customer Name"}
                  </div>
                ) : null}
              </span>
            </div>
            <div
              style={{
                padding: 10,
                justifyContent: "center",
                display: "flex",
                borderTopLeftRadius: 10,
                borderTopRightRadius: 10,
                color: "#ffffff",
              }}
            >
              <span style={{ display: "flex", flexDirection: "column" }}>
                <InputText
                  placeholder="Enter Unicode Name"
                  value={unicode_name}
                  style={{
                    width: "500px",
                    marginTop: 5,
                  }}
                  onChange={(e) => setUnicode_name(e.target.value)}
                />
              </span>
            </div>
            <div
              style={{
                padding: 10,
                justifyContent: "center",
                display: "flex",
                borderTopLeftRadius: 10,
                borderTopRightRadius: 10,
                color: "#ffffff",
              }}
            >
              <span style={{ display: "flex", flexDirection: "column" }}>
                <InputText
                  placeholder="Enter Mobile Number"
                  value={mobile}
                  style={{
                    width: "500px",
                    marginTop: 5,
                    borderWidth: alert_type === 2 ? 2 : 1,
                    borderColor: alert_type === 2 ? "red" : "",
                  }}
                  onChange={(e) => setMobile(e.target.value)}
                />
                {alert_type === 2 ? (
                  <div style={{ marginTop: 5, paddingLeft: 10, color: "red" }}>
                    {"Invalid Mobile Number"}
                  </div>
                ) : null}
              </span>
            </div>
            <div
              style={{
                padding: 10,
                justifyContent: "center",
                display: "flex",
                borderTopLeftRadius: 10,
                borderTopRightRadius: 10,
                color: "#ffffff",
              }}
            >
              <span style={{ display: "flex", flexDirection: "column" }}>
                <InputText
                  placeholder="Enter Customer Route"
                  value={route}
                  style={{
                    width: "500px",
                    marginTop: 5,
                    borderWidth: alert_type === 3 ? 2 : 1,
                    borderColor: alert_type === 3 ? "red" : "",
                  }}
                  onChange={(e) => setRoute(e.target.value)}
                />
                {alert_type === 3 ? (
                  <div style={{ marginTop: 5, paddingLeft: 10, color: "red" }}>
                    {"Invalid Route "}
                  </div>
                ) : null}
              </span>
            </div>
            <div
              style={{
                padding: 10,
                justifyContent: "center",
                display: "flex",
                borderTopLeftRadius: 10,
                borderTopRightRadius: 10,
                color: "#ffffff",
              }}
            >
              <span style={{ display: "flex", flexDirection: "column" }}>
                <InputText
                  placeholder="Enter Customer Address"
                  value={address}
                  style={{
                    width: "500px",
                    marginTop: 5,
                    borderWidth: alert_type === 4 ? 2 : 1,
                    borderColor: alert_type === 4 ? "red" : "",
                  }}
                  onChange={(e) => setAddress(e.target.value)}
                />
                {alert_type === 4 ? (
                  <div style={{ marginTop: 5, paddingLeft: 10, color: "red" }}>
                    {"Invalid Address "}
                  </div>
                ) : null}
              </span>
            </div>

            <div
              style={{
                padding: 10,
                justifyContent: "center",
                display: "flex",
                borderTopLeftRadius: 10,
                borderTopRightRadius: 10,
                color: "#ffffff",
              }}
            >
              <span style={{ display: "flex", flexDirection: "column" }}>
                <InputText
                  placeholder="Customer Password"
                  value={password}
                  style={{
                    width: "500px",
                    marginTop: 5,
                    borderWidth: alert_type === 5 ? 2 : 1,
                    borderColor: alert_type === 5 ? "red" : "",
                  }}
                  onChange={(e) => setPassword(e.target.value)}
                />
                {alert_type === 5 ? (
                  <div style={{ marginTop: 5, paddingLeft: 10, color: "red" }}>
                    {"Invalid Password "}
                  </div>
                ) : null}
              </span>
            </div>
            {loading ? (
              <div
                style={{
                  alignSelf: "center",
                  marginTop: 5,
                  height: "50px",
                  width: "200px",
                  backgroundColor: Main_Color,
                  borderRadius: 10,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  color: "#ffffff",
                  fontSize: 18,
                }}
              >
                <ReactLoading
                  type={"bars"}
                  color={"#fff"}
                  height={45}
                  width={45}
                />
              </div>
            ) : (
              <div
                onClick={_add_customer}
                style={{
                  alignSelf: "center",
                  marginTop: 5,
                  height: "50px",
                  width: "200px",
                  backgroundColor: Main_Color,
                  borderRadius: 10,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  color: "#ffffff",
                  fontSize: 18,
                }}
              >
                Submit
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Add;
