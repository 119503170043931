const checkcart = (item, cart) => {
  if (cart == null) {
    return "0";
  } else {
    var value = 0;
    for (var i = 0; i < cart.length; i++) {
      if (cart[i].id == item.id) {
        value = cart[i].filled;
      }
    }
    if (isNaN(value) == true) {
      return 0;
    } else {
      return value;
    }
  }
};
const checkcart2 = (item, cart) => {
  if (cart == null) {
    return "0";
  } else {
    var value = 0;
    for (var i = 0; i < cart.length; i++) {
      if (cart[i].id == item.id) {
        value = cart[i].empty;
      }
    }
    if (isNaN(value) == true) {
      return 0;
    } else {
      return value;
    }
  }
};

export { checkcart, checkcart2 };
