import React, { useEffect, useState } from "react";
import { InputText } from "primereact/inputtext";
import { AiFillCloseCircle } from "react-icons/ai";
import "primereact/resources/themes/lara-light-indigo/theme.css"; //theme
import "primereact/resources/primereact.min.css"; //core css
import "primeicons/primeicons.css";
import { Main_Color } from "../../Theme/Theme";
import moment from "moment";
import { useAlert } from "react-alert";
import Backend from "../../Backend/Backend";
import ReactLoading from "react-loading";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css";
const backend = new Backend();
export default function Bills(props) {
  const alert = useAlert();
  const [search, setSearch] = useState("");
  const [selcted, setSelcted] = useState("");
  const [month_data, setMonth_Date] = useState([]);
  const [month, setMonth] = useState("");
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loading_bill, setLoading_Bill] = useState(false);
  const [loading_bill_all, setLoading_Bill_All] = useState(false);
  const [loading_card, setLoading_card] = useState(false);
  const [loading_card_all, setLoading_card_all] = useState(false);

  useEffect(() => {
    setData(props.data);
    getMonths();
  }, [props.data]);

  const getMonths = () => {
    let months = [];
    let monthsRequired = 12;

    for (let i = 0; i <= monthsRequired; i++) {
      let name = moment().subtract(i, "months").format("MMM-YY");
      let first = moment()
        .subtract(i, "months")
        .startOf("month")
        .format("YYYY-MM-DD 00:00:00");
      let last = moment()
        .subtract(i, "months")
        .endOf("month")
        .format("YYYY-MM-DD 23:59:59");
      let data = {
        name: name,
        first: first,
        last: last,
      };
      months.push(data);
    }
    setMonth_Date(months);
  };

  const search_customer = (a) => {
    if (a.length > 1) {
      let data = [];

      data = props.data.filter(
        (x) => x.name.toLowerCase() == a.toLowerCase() || x.customer_id == a
      );
      setData(data);
    } else {
      setData(props.data);
    }
  };

  const _bill = () => {
    if (selcted == "") {
      alert.show("Select Customer First");
    } else if (month == "") {
      alert.show("Select Month First");
    } else {
      setLoading_Bill(true);
      let data = {
        token: localStorage.getItem("token"),
        admin_id: localStorage.getItem("adminid"),
        customer_id: selcted,
        from_date: month.first,
        invoice_date: new Date(),
      };

      backend.single_bill(data).then((r) => {
        setLoading_Bill(false);
        if (r.error == "False") {
          window.open(r.link);
        } else {
          alert.show(r.message);
        }
      });
    }
  };
  const _card = () => {
    if (selcted == "") {
      alert.show("Select Customer First");
    } else if (month == "") {
      alert.show("Select Month First");
    } else {
      setLoading_card(true);
      let data = {
        token: localStorage.getItem("token"),
        admin_id: localStorage.getItem("adminid"),
        customer_id: selcted,
        from_date: month.first,
      };
      backend.single_card(data).then((r) => {
        setLoading_card(false);
        if (r.error == "False") {
          window.open(r.link);
        } else {
          alert.show(r.message);
        }
      });
    }
  };
  const _bill_All = () => {
    if (month == "") {
      alert.show("Select Month First");
    } else {
      confirmAlert({
        title: "Confirm to generate all bill",
        message: "Are you sure generate.",
        buttons: [
          {
            label: "Yes",
            onClick: () => {
              setLoading_Bill_All(true);
              let data = {
                token: localStorage.getItem("token"),
                admin_id: localStorage.getItem("adminid"),
                from_date: month.first,
                invoice_date: new Date(),
              };

              backend.all_bill(data).then((r) => {
                setLoading_Bill_All(false);
                if (r.error == "False") {
                  window.open(r.link);
                } else {
                  alert.show(r.message);
                }
              });
            },
          },
          {
            label: "No",
            onClick: () => console.log("No"),
          },
        ],
      });
    }
  };
  const _card_All = () => {
    if (month == "") {
      alert.show("Select Month First");
    } else {
      confirmAlert({
        title: "Confirm to generate all card",
        message: "Are you sure generate.",
        buttons: [
          {
            label: "Yes",
            onClick: () => {
              setLoading_card_all(true);
              let data = {
                token: localStorage.getItem("token"),
                admin_id: localStorage.getItem("adminid"),
                from_date: month.first,
              };

              backend.all_card(data).then((r) => {
                setLoading_card_all(false);
                if (r.error == "False") {
                  window.open(r.link);
                } else {
                  alert.show(r.message);
                }
              });
            },
          },
          {
            label: "No",
            onClick: () => console.log("No"),
          },
        ],
      });
    }
  };

  return (
    <div style={{ display: "flex", flex: 1, padding: 15 }}>
      <div
        style={{
          flex: 0.7,
          backgroundColor: "#ffffff",
          borderRadius: 10,
          marginRight: 10,
        }}
      >
        <div
          style={{
            height: "60px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <InputText
            value={search}
            onChange={(e) => {
              setSearch(e.target.value);
              search_customer(e.target.value);
            }}
            style={{ width: "80%", height: "45px" }}
            placeholder="Search Customer by name and id"
          />
          {selcted != "" ? (
            <div onClick={() => setSelcted("")} style={{ padding: 10 }}>
              <AiFillCloseCircle size={30} color={"red"} />
            </div>
          ) : null}
        </div>
        <div style={{ padding: 10, height: "70vh", overflowY: "scroll" }}>
          {data.length > 0 ? (
            data.map((i) => {
              return (
                <div
                  onClick={() => setSelcted(i.customer_id)}
                  key={i.id}
                  style={{
                    height: "45px",
                    backgroundColor:
                      selcted == i.customer_id ? Main_Color : "#f2f2f2",
                    marginBottom: 5,
                    borderRadius: 10,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    borderStyle: "solid",
                    borderWidth: 2,
                    borderColor: i.status == 0 ? "red" : "#f2f2f2",
                    color: selcted == i.customer_id ? "#ffffff" : "#000000",
                  }}
                >
                  <div style={{ paddingLeft: 10 }}>{i.customer_id}</div>
                  <div style={{ flex: 1, paddingLeft: 10 }}>{i.name}</div>
                </div>
              );
            })
          ) : (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                paddingTop: "30px",
              }}
            >
              No customer found
            </div>
          )}
        </div>
      </div>
      <div
        style={{
          flex: 1,
          height: "80vh",
          backgroundColor: "#ffffff",
          borderRadius: 10,
          marginRight: 10,
        }}
      >
        <div
          style={{
            height: "60px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            borderStyle: "solid",
            borderWidth: 0,
            borderBottomWidth: 1,
            borderBottomColor: "rgba(0,0,0,0.1)",
          }}
        >
          <div style={{ fontSize: 18 }}>Bill Details</div>
        </div>

        <div
          style={{
            height: "60px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "row",
          }}
        >
          <div
            style={{
              fontSize: 18,
              flex: 1,
              textAlign: "center",
              paddingLeft: 20,
            }}
          >
            Select Months
          </div>
          {month != "" ? (
            <div onClick={() => setMonth("")} style={{ padding: 10 }}>
              <AiFillCloseCircle size={30} color={"red"} />
            </div>
          ) : (
            <div style={{ padding: 10 }}>
              <AiFillCloseCircle size={30} color={"#ffffff"} />
            </div>
          )}
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: 10,
            alignItems: "center",
            flexWrap: "wrap",
          }}
        >
          {month_data.map((i) => {
            return (
              <div
                onClick={() => setMonth(i)}
                style={{
                  height: 35,
                  width: 70,
                  backgroundColor:
                    month.name == i.name ? Main_Color : "#ffffff",
                  borderRadius: 10,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  color: month.name == i.name ? "#ffffff" : Main_Color,
                  borderStyle: "solid",
                  borderWidth: 2,
                  borderColor: month.name == i.name ? "#ffffff" : Main_Color,
                  margin: 20,
                }}
              >
                {i.name}
              </div>
            );
          })}
        </div>

        <div
          style={{
            display: "flex",
            justifyContent: "space-around",
            marginTop: 20,
            alignItems: "center",
            height: 45,
          }}
        >
          <div
            onClick={() => (loading_bill ? null : _bill())}
            style={{
              height: 45,
              width: 120,
              paddingLeft: 10,
              paddingRight: 10,
              backgroundColor: Main_Color,
              borderRadius: 10,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              color: "#ffffff",
            }}
          >
            {loading_bill ? (
              <ReactLoading type="bars" height={"40px"} width={"40px"} />
            ) : (
              "Generate Bill"
            )}
          </div>
          <div
            onClick={() => (loading_card ? null : _card())}
            style={{
              height: 45,
              paddingLeft: 10,
              paddingRight: 10,
              width: 150,
              backgroundColor: Main_Color,
              borderRadius: 10,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              color: "#ffffff",
            }}
          >
            {loading_card ? (
              <ReactLoading type="bars" height={"40px"} width={"40px"} />
            ) : (
              "Generate Card"
            )}
          </div>
        </div>

        <div
          style={{
            display: "flex",
            justifyContent: "space-around",
            marginTop: 20,
            alignItems: "center",
            height: 45,
          }}
        >
          <div
            onClick={() => (loading_bill_all ? null : _bill_All())}
            style={{
              height: 45,
              width: 220,
              backgroundColor: Main_Color,
              borderRadius: 10,
              display: "flex",
              paddingLeft: "10px",
              paddingRight: "10px",
              justifyContent: "center",
              alignItems: "center",
              color: "#ffffff",
            }}
          >
            {loading_bill_all ? (
              <ReactLoading type="bars" height={"40px"} width={"40px"} />
            ) : (
              "Generate Bill All Customer"
            )}
          </div>
          <div
            onClick={() => (loading_card_all ? null : _card_All())}
            style={{
              height: 45,
              width: 230,
              backgroundColor: Main_Color,
              borderRadius: 10,
              display: "flex",
              paddingLeft: "5px",
              paddingRight: "5px",
              justifyContent: "center",
              alignItems: "center",
              color: "#ffffff",
            }}
          >
            {loading_card_all ? (
              <ReactLoading type="bars" height={"40px"} width={"40px"} />
            ) : (
              "Generate Card All Customer"
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
