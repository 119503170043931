import React, { Component } from "react";
import { InputText } from "primereact/inputtext";
import { Main_Color } from "../../Theme/Theme";
import ReactLoading from "react-loading";
import Backend from "../../Backend/Backend";

const backend = new Backend();

export default class Password extends Component {
  constructor() {
    super();
    this.state = {
      password: "",
      admindata: JSON.parse(localStorage.getItem("admindata")),
      mobile: "",
      confirmpassword: "",
      loading: false,
      otpsummitted: false,
      otp: "",
    };
  }

  signup() {
    if (this.state.mobile == "" || this.state.mobile.length !== 10) {
      alert("Check Mobile Number");
    } else if (this.state.password == "" || this.state.password.length < 5) {
      alert("Ener Password Minimum 6 Character");
    } else if (this.state.password != this.state.confirmpassword) {
      alert("Confirm Password Dosen't Match");
    } else {
      this.setState({ loading: true });
      var data = {
        mobile: this.state.mobile,
        password: this.state.password,
        token: localStorage.getItem("token"),
        adminid: localStorage.getItem("adminid"),
      };

      backend.forgot_password(data).then((r) => {
        if (r.error == "False" || r.error == "true2") {
          this.setState({ otpsummitted: true, loading: false });
          alert(r.message);
        } else {
          alert(r.message);
          this.setState({ loading: true });
        }
      });
    }
  }

  otpverify2 = () => {
    this.setState({ loading: true });
    var data = {
      mobile: this.state.mobile,
      password: this.state.password,
      otp: this.state.otp,
    };

    backend.otpvarify2(data).then((r) => {
      if (r.error == "False" || r.error == "true2") {
        this.setState({ loading: false });
        alert(r.message);
      } else {
        alert(r.message);
      }
    });
  };

  render() {
    return (
      <div
        style={{
          padding: 10,
          display: "flex",
          flexDirection: "column",
          flex: 1,
        }}
      >
        <div
          style={{
            padding: 40,
            paddingLeft: 10,
            fontSize: 30,
            flex: 1,
            paddingBottom: 0,
          }}
        >
          Password
        </div>

        {this.state.otpsummitted == false ? (
          <>
            <div style={{ flex: 1, display: "flex" }}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  flex: 1,
                  paddingRight: 30,
                }}
              >
                <label htmlFor="in" style={{ marginBottom: 10 }}>
                  Password
                </label>
                <InputText
                  id="in"
                  value={this.state.password}
                  onChange={(e) => this.setState({ password: e.target.value })}
                />
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  flex: 1,
                  paddingRight: 30,
                }}
              >
                <label htmlFor="in" style={{ marginBottom: 10 }}>
                  Confirm Password
                </label>
                <InputText
                  id="in"
                  value={this.state.confirmpassword}
                  onChange={(e) =>
                    this.setState({ confirmpassword: e.target.value })
                  }
                />
              </div>
            </div>

            <div style={{ flex: 1, display: "flex" }}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  flex: 1,
                  paddingRight: 30,
                }}
              >
                <label htmlFor="in" style={{ marginBottom: 10 }}>
                  Phone Number
                </label>
                <InputText
                  id="in"
                  value={this.state.name}
                  onChange={(e) => this.setState({ name: e.target.value })}
                />
              </div>
            </div>
          </>
        ) : (
          <div style={{ flex: 1, display: "flex" }}>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                flex: 1,
                paddingRight: 30,
              }}
            >
              <label htmlFor="in" style={{ marginBottom: 10 }}>
                OTP
              </label>
              <InputText
                id="in"
                value={this.state.name}
                onChange={(e) => this.setState({ name: e.target.value })}
              />
            </div>
          </div>
        )}

        <div
          style={{
            flex: 4,
            display: "flex",
            justifyContent: "center",
            alignItems: "flex-start",
          }}
        >
          {this.state.loading == true ? (
            <div
              style={{
                height: "50px",
                width: "200px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: Main_Color,
                borderRadius: 10,
                color: "#ffffff",
              }}
            >
              <ReactLoading
                type={"bars"}
                color={"#ffffff"}
                height={40}
                width={40}
              />
            </div>
          ) : this.state.otpsummitted == true ? (
            <div
              onClick={() => this.otpverify2()}
              style={{
                height: "50px",
                width: "200px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: Main_Color,
                borderRadius: 10,
                color: "#ffffff",
              }}
            >
              Submit Otp
            </div>
          ) : (
            <div
              onClick={() => this.signup()}
              style={{
                height: "50px",
                width: "200px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: Main_Color,
                borderRadius: 10,
                color: "#ffffff",
              }}
            >
              Send Otp
            </div>
          )}
        </div>
      </div>
    );
  }
}
