import { InputText } from "primereact/inputtext";
import React from "react";

export default function RateCard(props) {
  const i = props.data;
  const [price, setPrice] = React.useState(i.price);
  return (
    <div
      style={{
        height: "55px",
        backgroundColor: "#ffffff",
        borderRadius: 10,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        marginTop: "10px",
      }}
    >
      <div style={{ paddingLeft: "10px" }}>
        <img src={i.image} style={{ height: "30px", width: "30px" }} />
      </div>
      <div style={{ flex: 1, paddingLeft: "5px" }}>{i.product_name}</div>
      <div
        style={{
          flex: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <InputText
          placeholder="Value "
          style={{
            width: "50%",
          }}
          value={price}
          onChange={(e) => {
            props.edit_rate(e.target.value, i.id);
            setPrice(e.target.value);
          }}
        />
      </div>
    </div>
  );
}
