import moment from "moment";
import React, { useState } from "react";
import { Main_Color } from "../../../Theme/Theme";
import { RiDeleteBinLine } from "react-icons/ri";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css";
import Backend from "../../../Backend/Backend";
import { useAlert } from "react-alert";
const backend = new Backend();

export default function CustomerCard(props) {
  const [token, setToken] = useState(localStorage.getItem("token"));
  const [adminid, setAdminid] = useState(localStorage.getItem("adminid"));
  const i = props.item;
  const alert = useAlert();

  const deleteBill = (i) => {
    confirmAlert({
      title: "Confirm to Delete",
      message: "Are you sure want to Delete ?",
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            let data = {
              token: token,
              admin_id: adminid,
              entry_id: i,
            };

            backend.delete_entry(data).then((r) => {
              if (r.error == "False") {
                alert.show(r.message);
                props.reload();
              } else {
                alert.error(r.message);
              }
            });
          },
        },
        {
          label: "No",
          onClick: () => console.log("No"),
        },
      ],
    });
  };

  return (
    <>
      <div
        key={i.id}
        style={{
          display: "flex",
          flex: 1,
          backgroundColor: "#ffffff",
          justifyContent: "space-between",
          alignItems: "center",
          borderTopLeftRadius: 10,
          borderTopRightRadius: 10,
          borderBottomLeftRadius: 0,
          borderBottomRightRadius: 0,
          padding: 10,
          marginTop: 10,
        }}
      >
        <div
          style={{
            paddingLeft: 20,
            display: "flex",
            alignItems: "center",
            flex: 1,
          }}
        >
          <div
            style={{
              color: "#000000",
              fontWeight: "bold",
              fontSize: 16,
              flex: 1,
            }}
          >
            {i.customer_id} - {i.name}
          </div>
          <div
            style={{
              color: "green",
              fontWeight: "bold",
              flex: 1,
              display: "flex",
              color: "#000000",
              justifyContent: "center",
              alignItems: "center",
              textAlign: "center",
            }}
          >
            Delivery Boy -{"  "}
            <div style={{ color: "#000000", marginLeft: 2 }}>{i.wname}</div>
          </div>
          <div
            style={{
              color:
                i.transaction_type == 1
                  ? "green"
                  : i.cash == 1
                  ? Main_Color
                  : "red",
              fontWeight: "bold",
              fontSize: 20,
              display: "flex",
              flex: 1,
              justifyContent: "center",
              alignItems: "flex-end",
              paddingRight: "10px",
              flexDirection: "column",
            }}
          >
            ₹{i.total}
            <div style={{ fontSize: 13, color: "gray" }}>
              {moment(i.date_created).format("DD-MM-YY hh:mm:ss A")}
            </div>
          </div>

          <div
            onClick={() => deleteBill(i.id)}
            style={{
              color: "red",
              fontWeight: "bold",
              fontSize: 20,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "50px",
              flexDirection: "column",
            }}
          >
            <RiDeleteBinLine style={{ fontSize: 30 }} />
          </div>
        </div>
      </div>
      {i.transaction_type == 0 ? (
        <div
          style={{
            backgroundColor: "#ffffff",
            justifyContent: "space-between",
            alignItems: "center",
            borderBottomLeftRadius: 10,
            borderBottomRightRadius: 10,
          }}
        >
          {i.items.map((i) => {
            return (
              <div
                key={i.id}
                style={{
                  display: "flex",
                  flex: 1,
                  backgroundColor: "#ffffff",
                  justifyContent: "space-between",
                  alignItems: "center",
                  borderBottomLeftRadius: 10,
                  borderBottomRightRadius: 10,
                }}
              >
                <div
                  style={{
                    paddingLeft: 20,
                    display: "flex",
                    alignItems: "center",
                    flex: 1,
                  }}
                >
                  <div style={{ paddingRight: 20, color: "#585858" }}>
                    <img src={i.image} style={{ height: 20, width: 20 }} />
                  </div>
                  <div
                    style={{
                      color: "#000000",
                      fontWeight: "bold",
                      fontSize: 14,
                      flex: 1,
                    }}
                  >
                    {i.product_name}
                  </div>
                  <div
                    style={{
                      color: "#585858",
                      fontWeight: "bold",
                      flex: 1,
                      fontSize: 14,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    F : {i.filled} E : {i.empty}
                  </div>
                  <div
                    style={{
                      color: "#585858",
                      fontWeight: "bold",
                      fontSize: 15,
                      display: "flex",
                      flex: 1,
                      justifyContent: "flex-end",
                      alignItems: "center",
                      paddingRight: "20px",
                    }}
                  >
                    <div>{" " + i.amount}</div>
                  </div>
                  <div
                    style={{
                      color: "#585858",
                      fontWeight: "bold",
                      fontSize: 15,
                      display: "flex",
                      justifyContent: "flex-end",
                      alignItems: "center",
                      width: "50px",
                    }}
                  >
                    <div> </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      ) : null}
    </>
  );
}
