import React, { Component } from "react";
import Backend from "../../Backend/Backend";
import * as XLSX from "xlsx";
const backend = new Backend();

class Download extends Component {
  constructor() {
    super();
    this.state = {
      admindata: JSON.parse(localStorage.getItem("admindata")),
      product: [],
      categories: [],
      table: [],
      report: [],
      loading: true,
    };
  }

  componentDidMount() {
    // this.loadproduct();
    // this.loadcategories();
    // this.loadtable();
    // this.loadReport();
  }

  loadproduct() {
    this.setState({
      loading: true,
    });
    var data = {
      token: localStorage.getItem("token"),
      adminid: localStorage.getItem("adminid"),
    };
    backend.productlist(data).then((r) => {
      if (r.error === "False") {
        var datass = r.data;
        datass.map((i, t) => {
          datass[t].price = datass[t].default_price;
          delete datass[t].id;
          delete datass[t].default_price;
        });

        this.setState({
          loading: true,
          product: datass,
        });
      } else {
        this.setState({
          loading: true,
        });
      }
    });
  }

  loadcategories() {
    this.setState({
      loading: true,
    });
    var data = {
      token: localStorage.getItem("token"),
      adminid: localStorage.getItem("adminid"),
    };
    backend.catagorieslist(data).then((r) => {
      if (r.error === "False") {
        this.setState({
          loading: true,
          categories: r.data,
        });
      } else {
        this.setState({
          loading: true,
        });
      }
    });
  }

  loadtable() {
    this.setState({
      loading: true,
    });
    var data = {
      token: this.state.token,
      adminid: this.state.adminid,
    };
    backend.tableslist(data).then((r) => {
      if (r.error === "False") {
        this.setState({
          loading: true,
          table: r.data,
        });
      } else {
        this.setState({
          loading: false,
        });
      }
    });
  }
  loadReport() {
    this.setState({
      loading: false,
    });
    let data = {
      token: localStorage.getItem("token"),
      adminid: localStorage.getItem("adminid"),
      datefrom: "2021-04-01 00:00:00",
      dateto: "2022-03-31 23:59:59",
      worker_id: "",
      payment_id: "",
    };
    backend
      .salesreport(data)
      .then((r) => {
        this.setState({
          loading: false,
          report: r.data,
        });
      })
      .catch((err) => {
        this.setState({
          loading: false,
        });
      });
  }

  Excel_donwload(a) {
    if (a == 1) {
      var wb = XLSX.utils.book_new();
      var ws = XLSX.utils.json_to_sheet(this.state.product);

      XLSX.utils.book_append_sheet(wb, ws, "Product");

      XLSX.writeFile(wb, "Product.xlsx");
    } else if (a == 2) {
      var wb = XLSX.utils.book_new();
      var ws = XLSX.utils.json_to_sheet(this.state.categories);

      XLSX.utils.book_append_sheet(wb, ws, "Categoies");

      XLSX.writeFile(wb, "Categoies.xlsx");
    } else if (a == 3) {
      var wb = XLSX.utils.book_new();
      var ws = XLSX.utils.json_to_sheet(this.state.table);

      XLSX.utils.book_append_sheet(wb, ws, "Table");

      XLSX.writeFile(wb, "Table.xlsx");
    } else if (a == 4) {
      var wb = XLSX.utils.book_new();
      var ws = XLSX.utils.json_to_sheet(this.state.report);

      XLSX.utils.book_append_sheet(wb, ws, "Report");

      XLSX.writeFile(wb, "Report.xlsx");
    }
  }

  render() {
    return (
      <div
        style={{
          padding: 10,
          display: "flex",
          flexDirection: "column",
          flex: 1,
        }}
      >
        <div
          style={{
            padding: 40,
            paddingLeft: 10,
            fontSize: 30,
            flex: 1,
            paddingBottom: 0,
          }}
        >
          Download Section
        </div>
        <div style={{ flex: 5, display: "flex", flexDirection: "column" }}>
          <div
            style={{
              height: "70px",
              backgroundColor: "#f2f2f2",
              borderStyle: "solid",
              borderWidth: 0,
              borderBottomWidth: 1,
              borderBottomColor: "#ffffff",
              display: "flex",
            }}
          >
            <div
              style={{
                flex: 1,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              Product
            </div>
            <div
              onClick={() => this.Excel_donwload(1)}
              style={{
                flex: 1,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <img
                src={require("../../Image/excel.png")}
                style={{ height: 50, borderRadius: 10 }}
              />
            </div>
          </div>
          <div
            style={{
              height: "70px",
              backgroundColor: "#f2f2f2",
              borderStyle: "solid",
              borderWidth: 0,
              borderBottomWidth: 1,
              borderBottomColor: "#ffffff",
              display: "flex",
            }}
          >
            <div
              style={{
                flex: 1,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              Categories
            </div>
            <div
              onClick={() => this.Excel_donwload(2)}
              style={{
                flex: 1,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <img
                src={require("../../Image/excel.png")}
                style={{ height: 50, borderRadius: 10 }}
              />
            </div>
          </div>
          <div
            style={{
              height: "70px",
              backgroundColor: "#f2f2f2",
              borderStyle: "solid",
              borderWidth: 0,
              borderBottomWidth: 1,
              borderBottomColor: "#ffffff",
              display: "flex",
            }}
          >
            <div
              style={{
                flex: 1,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              Table
            </div>
            <div
              onClick={() => this.Excel_donwload(3)}
              style={{
                flex: 1,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <img
                src={require("../../Image/excel.png")}
                style={{ height: 50, borderRadius: 10 }}
              />
            </div>
          </div>
          <div
            style={{
              height: "70px",
              backgroundColor: "#f2f2f2",
              borderStyle: "solid",
              borderWidth: 0,
              borderBottomWidth: 1,
              borderBottomColor: "#ffffff",
              display: "flex",
            }}
          >
            <div
              style={{
                flex: 1,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              Report
            </div>
            <div
              onClick={() => this.Excel_donwload(4)}
              style={{
                flex: 1,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <img
                src={require("../../Image/excel.png")}
                style={{ height: 50, borderRadius: 10 }}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Download;
