import React, { useEffect, useState } from "react";
import "./Edit.css";
import { InputText } from "primereact/inputtext";
import { Main_Color } from "../../../Theme/Theme";
import { AiFillCloseCircle } from "react-icons/ai";
import Backend from "../../../Backend/Backend";
import ReactLoading from "react-loading";
import { useAlert } from "react-alert";
import { Checkbox } from "primereact/checkbox";
const backend = new Backend();
const Edit = (props) => {
  const i = props.data;
  const [name, setName] = useState(i.name);
  const [unicode_name, setUnicode_name] = useState(i.unicode_name);
  const [mobile, setMobile] = useState(i.mobile);
  const [route, setRoute] = useState(i.route);
  const [address, setAddress] = useState(i.address);
  const [gst, setGst] = useState(i.gstnumber);
  const [status, setStatus] = useState(i.status == 1 ? true : false);
  const [token, setToken] = useState(localStorage.getItem("token"));
  const [adminid, setAdminid] = useState(localStorage.getItem("adminid"));
  const [password, setPassword] = useState("");
  const [alert_type, setAlert_type] = useState("");
  const [loading, setLoading] = useState(false);
  const alert = useAlert();

  const _edit_customer = () => {
    if (name === "") {
      setAlert_type(1);
      setTimeout(() => {
        setAlert_type(0);
      }, 5000);
    } else if (mobile === "") {
      setAlert_type(2);
      setTimeout(() => {
        setAlert_type(0);
      }, 5000);
    } else if (route === "") {
      setAlert_type(3);
      setTimeout(() => {
        setAlert_type(0);
      }, 5000);
    } else {
      let data = {
        token: token,
        admin_id: adminid,
        customer_name: name,
        password: password,
        mobile: mobile,
        address: address,
        unicode: unicode_name,
        route: route,
        status: status,
        gst: gst,
        customer_id: i.customer_id,
      };

      setLoading(true);
      backend.edit_customer(data).then((r2) => {
        setLoading(false);
        if (r2.error === "False") {
          props.close();
          setLoading(false);
        } else {
          alert.show(r2.message);
          setLoading(false);
        }
      });
    }
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flex: 1,
        height: "100vh",
      }}
    >
      <div
        style={{
          height: "90%",
          width: "60%",
          backgroundColor: "#f2f2f2",
          borderRadius: 10,
          display: "flex",
          alignSelf: "center",
          flexDirection: "column",
        }}
      >
        <div
          style={{
            padding: 20,
            justifyContent: "space-between",
            display: "flex",
            backgroundColor: Main_Color,
            borderTopLeftRadius: 10,
            borderTopRightRadius: 10,
            color: "#ffffff",
            alignItems: "center",
          }}
        >
          <div style={{ fontSize: 22 }}>Edit Customer</div>
          <div onClick={() => props.close("a")}>
            <AiFillCloseCircle style={{ height: "40px", width: "40px" }} />
          </div>
        </div>

        <div style={{ display: "flex" }}>
          <div
            style={{
              flex: 1,
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div
              style={{
                padding: 10,
                justifyContent: "center",
                display: "flex",
                borderTopLeftRadius: 10,
                borderTopRightRadius: 10,
                color: "#ffffff",
              }}
            >
              <span style={{ display: "flex", flexDirection: "column" }}>
                <InputText
                  placeholder="Customer Name"
                  value={name}
                  style={{
                    width: "500px",
                    marginTop: 10,
                    borderWidth: alert_type === 1 ? 2 : 1,
                    borderColor: alert_type === 1 ? Main_Color : "",
                  }}
                  onChange={(e) => setName(e.target.value)}
                />
                {alert_type === 1 ? (
                  <div
                    style={{ marginTop: 5, paddingLeft: 10, color: Main_Color }}
                  >
                    {"Customer Name Name "}
                  </div>
                ) : null}
              </span>
            </div>
            <div
              style={{
                padding: 10,
                justifyContent: "center",
                display: "flex",
                borderTopLeftRadius: 10,
                borderTopRightRadius: 10,
                color: "#ffffff",
              }}
            >
              <span style={{ display: "flex", flexDirection: "column" }}>
                <InputText
                  placeholder="Unicode Name"
                  value={unicode_name}
                  style={{
                    width: "500px",
                    marginTop: 10,
                    borderWidth: alert_type === 1 ? 2 : 1,
                    borderColor: alert_type === 1 ? Main_Color : "",
                  }}
                  onChange={(e) => setUnicode_name(e.target.value)}
                />
              </span>
            </div>
            <div
              style={{
                padding: 10,
                justifyContent: "center",
                display: "flex",
                borderTopLeftRadius: 10,
                borderTopRightRadius: 10,
                color: "#ffffff",
              }}
            >
              <span style={{ display: "flex", flexDirection: "column" }}>
                <InputText
                  placeholder="Mobile Number"
                  value={mobile}
                  style={{
                    width: "500px",
                    marginTop: 10,
                    borderWidth: alert_type === 1 ? 2 : 1,
                    borderColor: alert_type === 1 ? Main_Color : "",
                  }}
                  onChange={(e) => setMobile(e.target.value)}
                />
                {alert_type === 1 ? (
                  <div
                    style={{ marginTop: 5, paddingLeft: 10, color: Main_Color }}
                  >
                    {"Invalid Mobile Number"}
                  </div>
                ) : null}
              </span>
            </div>

            <div
              style={{
                padding: 10,
                justifyContent: "center",
                display: "flex",
                borderTopLeftRadius: 10,
                borderTopRightRadius: 10,
                color: "#ffffff",
              }}
            >
              <span style={{ display: "flex", flexDirection: "column" }}>
                <InputText
                  placeholder="Customer Address"
                  value={address}
                  style={{
                    width: "500px",
                    marginTop: 10,
                    borderWidth: alert_type === 1 ? 2 : 1,
                    borderColor: alert_type === 1 ? Main_Color : "",
                  }}
                  onChange={(e) => setAddress(e.target.value)}
                />
                {alert_type === 3 ? (
                  <div
                    style={{ marginTop: 5, paddingLeft: 10, color: Main_Color }}
                  >
                    {"Invalid Address "}
                  </div>
                ) : null}
              </span>
            </div>
            <div
              style={{
                padding: 10,
                justifyContent: "center",
                display: "flex",
                borderTopLeftRadius: 10,
                borderTopRightRadius: 10,
                color: "#ffffff",
              }}
            >
              <span style={{ display: "flex", flexDirection: "column" }}>
                <InputText
                  placeholder="Gst"
                  value={gst}
                  style={{
                    width: "500px",
                    marginTop: 10,
                    borderWidth: alert_type === 1 ? 2 : 1,
                    borderColor: alert_type === 1 ? Main_Color : "",
                  }}
                  onChange={(e) => setGst(e.target.value)}
                />
                {alert_type === 3 ? (
                  <div
                    style={{ marginTop: 5, paddingLeft: 10, color: Main_Color }}
                  >
                    {"Invalid Address "}
                  </div>
                ) : null}
              </span>
            </div>
            <div
              style={{
                padding: 10,
                justifyContent: "center",
                display: "flex",
                borderTopLeftRadius: 10,
                borderTopRightRadius: 10,
                color: "#ffffff",
              }}
            >
              <span style={{ display: "flex", flexDirection: "column" }}>
                <InputText
                  placeholder="Password - Leave Blank For No Change"
                  value={password}
                  style={{
                    width: "500px",
                    marginTop: 5,
                    borderWidth: alert_type === 1 ? 2 : 1,
                    borderColor: alert_type === 1 ? Main_Color : "",
                  }}
                  onChange={(e) => setPassword(e.target.value)}
                />
                {alert_type === 3 ? (
                  <div
                    style={{ marginTop: 5, paddingLeft: 10, color: Main_Color }}
                  >
                    {"Invalid Password "}
                  </div>
                ) : null}
              </span>
            </div>

            <div
              style={{
                padding: 10,
                display: "flex",
                justifyContent: "flex-end",

                alignItems: "center",
                width: "60%",
              }}
            >
              <div
                style={{
                  color: status == true ? "green" : "red",
                  paddingRight: "50px",
                }}
              >
                {status ? "Active" : "Deactive"}
              </div>
              <div style={{ paddingRight: "10px" }}>
                <Checkbox
                  onChange={(e) => setStatus(e.checked)}
                  checked={status}
                ></Checkbox>
              </div>
            </div>
            {loading ? (
              <div
                style={{
                  alignSelf: "center",
                  marginTop: 10,
                  height: "50px",
                  width: "200px",
                  backgroundColor: Main_Color,
                  borderRadius: 10,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  color: "#ffffff",
                  fontSize: 18,
                }}
              >
                <ReactLoading
                  type={"bars"}
                  color={"#fff"}
                  height={45}
                  width={45}
                />
              </div>
            ) : (
              <div
                onClick={_edit_customer}
                style={{
                  alignSelf: "center",
                  marginTop: 10,
                  height: "50px",
                  width: "200px",
                  backgroundColor: Main_Color,
                  borderRadius: 10,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  color: "#ffffff",
                  fontSize: 18,
                }}
              >
                Submit
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Edit;
