import React, { useEffect, useState } from "react";
import "./Login.css";
import { InputText } from "primereact/inputtext";
import ReactLoading from "react-loading";
import "primereact/resources/themes/lara-light-indigo/theme.css"; //theme
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import Backend from "../../Backend/Backend";
import { Main_Color } from "../../Theme/Theme";
import uuid from "react-uuid";
import { useAlert } from "react-alert";
import { Link } from "react-router-dom";

const backend = new Backend();

function Login() {
  const [uuids, setUuid] = useState();
  const [mobile, setMobile] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [alerts, setAlert] = useState(false);
  const [alert_msg, setAlert_Msg] = useState("");

  const alert = useAlert();

  useEffect(() => {
    setUuid(uuid());
  }, []);

  const _onLogin = () => {
    if (mobile == "") {
      var x = document.getElementsByClassName("mobile")[0]; //Get the first element with the class name
      x.style.borderColor = "red";
      setAlert(1);
      setAlert_Msg("Invalid Username Or Mobile");
      setTimeout(() => {
        var x = document.getElementsByClassName("mobile")[0]; //Get the first element with the class name
        x.style.borderColor = "";
        setAlert(0);
        setAlert_Msg("");
      }, 3000);
    } else if (password == "") {
      var x = document.getElementsByClassName("password")[0]; //Get the first element with the class name
      x.style.borderColor = "red";
      setAlert(2);
      setAlert_Msg("Invalid Pasdsword");
      setTimeout(() => {
        var x = document.getElementsByClassName("password")[0]; //Get the first element with the class name
        x.style.borderColor = "";
        setAlert(0);
        setAlert_Msg("");
      }, 3000);
    } else {
      setLoading(true);
      var data = {
        mobile: mobile,
        password: password,
        device_id: uuids,
      };
      backend.Login(data).then((r) => {
        setLoading(false);
        if (r.error == "False") {
          localStorage.setItem("admindata", JSON.stringify(r.data));
          localStorage.setItem("adminid", r.data.id);
          localStorage.setItem("token", r.auth);
          localStorage.setItem("isLogged", "1");
          window.location = "/";
        } else {
          alert.error(r.message);
        }
      });
    }
  };

  return (
    <div>
      <div className="MainDiv">
        <div className="imagebackground">
          <img
            src={require("../../Image/92d3ec1e4b581ba2f80e64778705fefa.jpeg")}
            className="imagelogin"
          />
        </div>
        <div
          style={{
            flex: 1.5,
            backgroundColor: "#ffffff",
            borderStyle: "solid",
            borderWidth: 0,
            height: "100vh",
            borderLeftWidth: 1,
            borderLeftColor: "rgba(0,0,0,0.2)",
            display: "flex",
          }}
        >
          <div
            style={{
              flex: 1,
              display: "flex",
              flexDirection: "column",
            }}
          >
            <div
              style={{
                flex: 1,
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <div style={{ flex: 1 }}>
                <img
                  src={require("../../Image/count.png")}
                  style={{
                    height: "90px",
                    width: "90px%",
                  }}
                />
              </div>
              <div
                style={{
                  flex: 1,
                  padding: 50,
                  paddingTop: 40,
                  display: "flex",
                  width: "100%",
                  flexDirection: "column",
                }}
              >
                <text style={{ fontSize: 27, color: Main_Color }}>Sign In</text>
                <text
                  style={{
                    fontSize: 15,
                    marginTop: 30,
                    color: "#9f9e9f",
                  }}
                >
                  WELCOME BACK TO YOUR ACCOUNT
                </text>
              </div>
            </div>

            <div style={{ flex: 1, padding: 30, paddingTop: 0 }}>
              <label>Mobile</label>
              <div
                className="p-inputgroup"
                style={{
                  paddingTop: 10,
                  paddingBottom: 10,
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <InputText
                  className="mobile"
                  onChange={(e) => {
                    setMobile(e.target.value);
                  }}
                  maxLength={10}
                  value={mobile}
                  placeholder="Mobile And Username"
                  style={{ width: "100%", borderRadius: 10 }}
                />
                {alerts == 1 ? (
                  <label style={{ marginTop: "10px", color: "red" }}>
                    {alert_msg}
                  </label>
                ) : null}
              </div>
              <label>Password</label>
              <div
                className="p-inputgroup"
                style={{
                  paddingTop: 10,
                  paddingBottom: 10,
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <InputText
                  className="password"
                  type={"password"}
                  onChange={(e) => {
                    setPassword(e.target.value);
                  }}
                  maxLength={256}
                  value={password}
                  placeholder="Password"
                  style={{ width: "100%", borderRadius: 10 }}
                />
                {alerts == 2 ? (
                  <label style={{ marginTop: "10px", color: "red" }}>
                    {alert_msg}
                  </label>
                ) : null}
              </div>
            </div>
            <div
              style={{
                flex: 1,
                alignItems: "center",
                display: "flex",
                flexDirection: "column",
              }}
            >
              {loading ? (
                <div
                  style={{
                    height: "55px",
                    width: "200px",
                    backgroundColor: Main_Color,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    borderRadius: 30,
                  }}
                >
                  <ReactLoading
                    type={"bars"}
                    color={"#fff"}
                    height={45}
                    width={45}
                  />
                </div>
              ) : (
                <div
                  onClick={() => _onLogin()}
                  style={{
                    height: "55px",
                    width: "200px",
                    backgroundColor: Main_Color,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    borderRadius: 30,
                  }}
                >
                  <text style={{ color: "#fff", fontWeight: "bold" }}>
                    Login Now
                  </text>
                </div>
              )}

              <div style={{ paddingTop: 30 }}>
                Don't have account ?{" "}
                <Link
                  to="register"
                  style={{ textDecoration: "none", color: "#585858" }}
                >
                  <text style={{ fontSize: 22, fontWeight: "bold" }}>
                    Sign Up
                  </text>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Login;
