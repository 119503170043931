import React, { useEffect, useState } from "react";
import { Main_Color } from "../../Theme/Theme";
import { FcManager } from "react-icons/fc";
import History from "./History";
import Bills from "./Bills";
import Backend from "../../Backend/Backend";
import { useAlert } from "react-alert";
const backend = new Backend();

export default function Bill() {
  const alert = useAlert();
  const [selected, setSelected] = useState(1);
  const [data, setData] = useState(0);
  const [loading, setLoading] = useState(0);
  useEffect(() => {
    loadconent();
  }, []);

  const loadconent = () => {
    setLoading(true);
    var data = {
      token: localStorage.getItem("token"),
      admin_id: localStorage.getItem("adminid"),
    };
    backend.load_customer(data).then((r) => {
      if (r.error === "False") {
        setData(r.data);
        setLoading(false);
      } else {
        alert.error("You just broke something!");
        setLoading(false);
      }
    });
  };
  return (
    <div style={{ flex: 1, backgroundColor: "#f2f2f2" }}>
      <div
        style={{
          display: "flex",
          height: "100px",
          backgroundColor: "#ffffff",
          flex: 1,
          alignItems: "center",
        }}
      >
        <div
          style={{
            display: "flex",
            padding: 20,
            color: Main_Color,
            fontSize: 22,
            paddingLeft: 40,
            flex: 1,
            alignItems: "center",
            justifyContent: "flex-start",
          }}
        >
          <FcManager style={{ fontSize: 55 }} />
          <div style={{ paddingLeft: 20, fontSize: 22 }}>Bill</div>
        </div>
        <div
          style={{
            flex: 1,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div
            onClick={() => setSelected(1)}
            style={{
              height: "40px",
              width: "100px",
              backgroundColor: selected == 1 ? Main_Color : "#f2f2f2",
              borderRadius: 10,
              color: selected == 1 ? "#ffffff" : "#585858",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            Bill
          </div>
        </div>
        <div
          style={{
            flex: 1,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div
            onClick={() => setSelected(2)}
            style={{
              height: "40px",
              width: "140px",
              backgroundColor: selected == 2 ? Main_Color : "#f2f2f2",
              borderRadius: 10,
              color: selected == 2 ? "#ffffff" : "#585858",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            Generated Bill
          </div>
        </div>
      </div>
      <div style={{ flex: 1 }}>
        {selected == 1 ? <Bills data={data} /> : <History />}
      </div>
    </div>
  );
}
