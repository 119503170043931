import React, { useEffect } from "react";
import { AiFillCloseCircle } from "react-icons/ai";
import { FiFilter } from "react-icons/fi";
import { Main_Color } from "../../../Theme/Theme";
import Backend from "../../../Backend/Backend";
import { Calendar } from "primereact/calendar";
import CustomerCard from "./CustomerCard";
import moment from "moment";
import { InputText } from "primereact/inputtext";
import * as XLSX from "xlsx";
import ReactLoading from "react-loading";
import SummaryCard from "./SummaryCard";
const backend = new Backend();
const Customer = () => {
  const [filter, setFilter] = React.useState(false);
  const [filter_id, setFilter_type] = React.useState(0);
  const [loading, setLoading] = React.useState(false);
  const [loading_customer, setLoadingCustomer] = React.useState(false);
  const [start, setStart] = React.useState(
    moment(new Date()).format("YYYY-MM-DD 00:00:00")
  );
  const [end, setEnd] = React.useState(
    moment(new Date()).format("YYYY-MM-DD 23:59:59")
  );
  const [data, setData] = React.useState([]);
  const [data_search, setData_Search] = React.useState([]);
  const [data_cash, setData_Cash] = React.useState([]);
  const [data_card, setData_Card] = React.useState([]);
  const [data_payment, setData_Payment] = React.useState([]);

  const [data_customer, setDataSearch_Customer] = React.useState([]);
  const [customer, setCustomer] = React.useState([]);
  const [total, setTotal] = React.useState(0);
  const [search, setSearch] = React.useState("");
  const [search_entry, setSearch_Entry] = React.useState("");
  const [customerid, setCustomerId] = React.useState("");
  const [sort, setSort] = React.useState(false);
  const [summary, setSummay] = React.useState(false);
  const [summarydata, setSummayData] = React.useState([]);
  const [month, setMonth] = React.useState("");

  useEffect(() => {
    loadconent();
    load_customer();
  }, []);

  const load_customer = () => {
    setLoadingCustomer(true);
    let data = {
      token: localStorage.getItem("token"),
      admin_id: localStorage.getItem("adminid"),
    };

    backend.load_customer(data).then((r) => {
      if (r.error == "False") {
        setCustomer(r.data);
        setDataSearch_Customer(r.data);
        setLoadingCustomer(false);
      } else {
        setLoadingCustomer(false);
      }
    });
  };

  const loadconent = (a) => {
    var startdata, enddata;
    setMonth(a);
    if (a == "Today") {
      startdata = moment(new Date()).format("YYYY-MM-DD 00:00:00");
      enddata = moment(new Date()).format("YYYY-MM-DD 23:59:59");
      setStart(startdata);
      setEnd(enddata);
    } else if (a == "Yesterday") {
      startdata = moment().add(-1, "days").format("YYYY-MM-DD 00:00:00");
      enddata = moment().add(-1, "days").format("YYYY-MM-DD 23:59:59");
      setStart(startdata);
      setEnd(enddata);
    } else if (a == "Last 7 Days") {
      startdata = moment(new Date()).format("YYYY-MM-DD 00:00:00");
      enddata = moment().add(-7, "days").format("YYYY-MM-DD 23:59:59");
      setStart(startdata);
      setEnd(enddata);
    } else if (a == "This Month") {
      startdata = moment().startOf("month").format("YYYY-MM-DD 00:00:00");
      enddata = moment().endOf("month").format("YYYY-MM-DD 23:59:59");
      setStart(startdata);
      setEnd(enddata);
    } else if (a == "Last Month") {
      let lastmonth = moment(new Date())
        .subtract(1, "months")
        .format("YYYY-MM-DD");
      startdata = moment(lastmonth)
        .startOf("month")
        .format("YYYY-MM-DD 00:00:00");
      enddata = moment(lastmonth).endOf("month").format("YYYY-MM-DD 23:59:59");

      setStart(startdata);
      setEnd(enddata);
    } else if (a == "This Year") {
      startdata = moment(new Date())
        .subtract(1, "year")
        .format("YYYY-MM-DD 00:00:00");
      enddata = moment(new Date())
        .subtract(0, "year")
        .format("YYYY-MM-DD 23:59:59");
      setStart(startdata);
      setEnd(enddata);
    }
    setLoading(true);
    let data = {
      token: localStorage.getItem("token"),
      admin_id: localStorage.getItem("adminid"),
      datefrom: a != undefined ? startdata : start,
      dateto: a != undefined ? enddata : end,
      customer_id: customerid,
    };

    backend
      .load_report_customer(data)
      .then((r) => {
        console.log(r);
        var total = 0;
        r.data.map((x) => {
          if (x.transaction_type == 1) {
          } else {
            total = parseFloat(total) + parseFloat(x.total);
          }
        });

        var data_cash = r.data.filter(
          (x) => x.transaction_type == 0 && x.cash == 1
        );
        var data_card = r.data.filter(
          (x) => x.transaction_type == 0 && x.cash == 0
        );
        var payment_temp = 0;
        var data_payment = r.data.filter((x) => x.transaction_type == 1);
        data_payment.map(
          (x) => (payment_temp = parseFloat(payment_temp) + parseFloat(x.total))
        );

        var new_total = parseFloat(total) - parseFloat(payment_temp);

        setData(r.data);
        setData_Search(r.data);
        setData_Cash(data_cash);
        setData_Card(data_card);
        setData_Payment(payment_temp);
        setSummayData(r.overview);
        setLoading(false);
        setTotal(new_total);
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  const filterarray = (a) => {
    if (a == "ALL") {
      setFilter_type(0);
      var total = 0;
      data_search.data.map((x) => {
        if (x.transaction_type == 1) {
        } else {
          total = parseFloat(total) + parseFloat(x.total);
        }
      });

      setData(data_search);
      setTotal(total);
    }
    if (a == "CASH") {
      setFilter_type(1);
      var total = 0;
      data_cash.map((x) => (total = parseFloat(total) + parseFloat(x.total)));
      setData(data_cash);
      setTotal(total);
    }

    if (a == "PAYMENT") {
      setFilter_type(3);
      var total = 0;
      data_payment.map(
        (x) => (total = parseFloat(total) + parseFloat(x.total))
      );
      setData(data_payment);
      setTotal(total);
    }
    if (a == "CARD") {
      setFilter_type(2);
      var total = 0;
      data_card.map((x) => (total = parseFloat(total) + parseFloat(x.total)));
      setData(data_card);
      setTotal(total);
    }
  };

  const onSearch = (e) => {
    setSearch(e);
    if (e.length > 1) {
      let filteredCustomer = [];
      for (var i = 0; i < data_customer.length; i++) {
        if (
          data_customer[i].customer_id.toLowerCase().includes(e.toLowerCase())
        ) {
          filteredCustomer.push(data_customer[i]);
        } else if (
          data_customer[i].name.toLowerCase().includes(e.toLowerCase())
        ) {
          filteredCustomer.push(data_customer[i]);
        }
      }
      setCustomer(filteredCustomer);
    } else {
      setCustomer(data_customer);
    }
  };

  const Excel_donwload = () => {
    if (data.length > 0) {
      var wb = XLSX.utils.book_new();
      var ws = XLSX.utils.json_to_sheet(data);

      XLSX.utils.book_append_sheet(wb, ws, "All");

      XLSX.writeFile(wb, `All ${moment(new Date()).format("DD-MM-YYYY")}.xlsx`);
    } else {
      alert.error("No Report Found");
    }
  };

  const Sorting = (a) => {
    if (a == "Id") {
      var newarry = [];
      setData(newarry);

      newarry = data_search.sort(function (a, b) {
        var x = a.customer_id;
        var y = b.customer_id;
        return x < y ? -1 : x > y ? 1 : 0;
      });
      setTimeout(() => {
        setData(newarry);
      }, 10);
      return false;
    } else if (a == "Name") {
      var newarry = [];
      setData(newarry);

      newarry = data_search.sort(function (a, b) {
        var x = a.name;
        var y = b.name;
        return x < y ? -1 : x > y ? 1 : 0;
      });
      setTimeout(() => {
        setData(newarry);
      }, 10);
      return false;
    } else if (a == "Delivery") {
      var newarry = [];
      setData(newarry);

      newarry = data_search.sort(function (a, b) {
        var x = a.wname;
        var y = b.wname;
        return x < y ? -1 : x > y ? 1 : 0;
      });
      setTimeout(() => {
        setData(newarry);
      }, 10);
      return false;
    } else if (a == "Date") {
      var newarry = [];
      setData(newarry);
      newarry = data_search.sort(function (a, b) {
        var x = a.date_created;
        var y = b.date_created;
        return x < y ? -1 : x > y ? 1 : 0;
      });
      setTimeout(() => {
        setData(newarry);
      }, 10);
      return false;
    }
  };

  const onSearch_Entry = (e) => {
    setSearch_Entry(e);
    if (search_entry.length > 1) {
      let filteredCustomer = [];
      for (var i = 0; i < data_search.length; i++) {
        if (data_search[i].name.toLowerCase().includes(e.toLowerCase())) {
          filteredCustomer.push(data_search[i]);
        } else if (
          data_search[i].customer_id.toLowerCase().includes(e.toLowerCase())
        ) {
          filteredCustomer.push(data_search[i]);
        } else if (
          data_search[i].total.toLowerCase().includes(e.toLowerCase())
        ) {
          filteredCustomer.push(data_search[i]);
        }
      }
      setData(filteredCustomer);
    } else {
      setData(data_search);
    }
  };

  const loadconent_customer = (a) => {
    setLoading(true);
    let data = {
      token: localStorage.getItem("token"),
      admin_id: localStorage.getItem("adminid"),
      datefrom: start,
      dateto: end,
      customer_id: a,
    };

    backend
      .load_report_customer(data)
      .then((r) => {
        console.log(r, "hihihihih");
        var total = 0;
        r.data.map((x) => (total = parseFloat(total) + parseFloat(x.total)));

        var data_cash = r.data.filter(
          (x) => x.transaction_type == 0 && x.cash == 1
        );
        var data_card = r.data.filter(
          (x) => x.transaction_type == 0 && x.cash == 0
        );
        var payment_temp = 0;
        var data_payment = r.data.filter((x) => x.transaction_type == 1);
        data_payment.map(
          (x) => (payment_temp = parseFloat(payment_temp) + parseFloat(x.total))
        );

        var new_total = parseFloat(total) - parseFloat(payment_temp);

        setData(r.data);
        setData_Search(r.data);
        setData_Cash(data_cash);
        setData_Card(data_card);
        setData_Payment(payment_temp);
        setSummayData(r.overview);
        setLoading(false);
        setTotal(new_total);
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  return (
    <div>
      {filter == true ? (
        <div
          style={{
            height: "55px",
            backgroundColor: "#ffffff",
            display: "flex",
            justifyContent: "space-around",
            alignItems: "center",
            borderRadius: 10,
          }}
        >
          <div style={{ display: "flex", alignItems: "center" }}>
            <div style={{ paddingRight: 10 }}>Start Date</div>
            <div>
              <Calendar
                style={{ height: "40px" }}
                dateFormat="dd/mm/yy"
                value={start}
                onChange={(e) => {
                  setStart(moment(e.value).format("YYYY-MM-DD 00:00:00"));
                }}
                maxDate={new Date()}
              ></Calendar>
            </div>
          </div>
          <div style={{ display: "flex", alignItems: "center" }}>
            <div style={{ paddingRight: 10 }}>End Date</div>
            <div>
              <Calendar
                dateFormat="dd/mm/yy"
                style={{ height: "40px" }}
                value={end}
                onHide={() => loadconent()}
                onChange={(e) => {
                  setEnd(moment(e.value).format("YYYY-MM-DD 23:59:59"));
                }}
                maxDate={new Date()}
              ></Calendar>
            </div>
          </div>
          <div
            onClick={() => Excel_donwload()}
            style={{
              backgroundColor: "#ffffff",
              padding: 10,
              marginRight: 20,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              borderRadius: 10,
              marginTop: 5,
              height: "40px",
              width: "120px",
              borderStyle: "solid",
              borderColor: Main_Color,
            }}
          >
            <img
              src={require("../../../Image/excel.png")}
              style={{ height: "30px" }}
            />
            <div style={{ paddingLeft: 7 }}>Excel</div>
          </div>
          <div
            onClick={() => setFilter(false)}
            style={{
              backgroundColor: "#f2f2f2",
              padding: 7,
              borderRadius: 10,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <AiFillCloseCircle style={{ fontSize: 32, color: Main_Color }} />
          </div>
        </div>
      ) : (
        <div
          style={{
            height: "40px",
            backgroundColor: "#f2f2f2",
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
          }}
        >
          <div style={{ flex: 1, display: "flex" }}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                paddingLeft: "10px",
              }}
            >
              <div
                onClick={() => filterarray("ALL")}
                style={{
                  padding: 10,
                  backgroundColor: filter_id == 0 ? Main_Color : "#ffffff",
                  borderRadius: 10,
                  color: filter_id == 0 ? "#ffffff" : Main_Color,
                }}
              >
                ALL
              </div>
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                paddingLeft: "10px",
              }}
            >
              <div
                onClick={() => filterarray("CASH")}
                style={{
                  padding: 10,
                  padding: 10,
                  backgroundColor: filter_id == 1 ? Main_Color : "#ffffff",
                  borderRadius: 10,
                  color: filter_id == 1 ? "#ffffff" : Main_Color,
                }}
              >
                CASH
              </div>
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                paddingLeft: "10px",
              }}
            >
              <div
                onClick={() => filterarray("CARD")}
                style={{
                  padding: 10,
                  padding: 10,
                  backgroundColor: filter_id == 2 ? Main_Color : "#ffffff",
                  borderRadius: 10,
                  color: filter_id == 2 ? "#ffffff" : Main_Color,
                }}
              >
                CARD
              </div>
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                paddingLeft: "10px",
              }}
            >
              <div
                onClick={() => filterarray("PAYMENT")}
                style={{
                  padding: 10,
                  padding: 10,
                  backgroundColor: filter_id == 3 ? Main_Color : "#ffffff",
                  borderRadius: 10,
                  color: filter_id == 3 ? "#ffffff" : Main_Color,
                }}
              >
                PAYMENTS
              </div>
            </div>
            <div class="dropdown">
              <div class="dropbtn">{month == null ? "Select" : month}</div>
              <div class="dropdown-content">
                <a onClick={() => loadconent("Today")}>Today</a>
                <a onClick={() => loadconent("Yesterday")}>Yesterday</a>
                <a onClick={() => loadconent("Last 7 Days")}>Last 7 Days</a>
                <a onClick={() => loadconent("This Month")}>This Month</a>
                <a onClick={() => loadconent("Last Month")}>Last Month</a>
                <a onClick={() => loadconent("This Year")}>This Year</a>
              </div>
            </div>
          </div>
          {/* <div
            onClick={() => setSort(!sort)}
            style={{
              backgroundColor: Main_Color,
              padding: 10,
              marginRight: 20,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              borderRadius: 10,
              marginTop: 5,
              height: "40px",
              paddingLeft: "15px",
              paddingRight: "15px",
            }}
          >
            <div style={{ color: "#ffffff" }}>SORTING</div>
          </div> */}
          <div
            onClick={() => setSummay(!summary)}
            style={{
              backgroundColor: Main_Color,
              padding: 10,
              marginRight: 20,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              borderRadius: 10,
              marginTop: 5,
              height: "40px",
              paddingLeft: "15px",
              paddingRight: "15px",
            }}
          >
            <div style={{ paddingLeft: 7, color: "#ffffff" }}>
              TOTAL - {parseFloat(total).toFixed(2)}
            </div>
          </div>
          <div
            onClick={() => setFilter(true)}
            style={{
              backgroundColor: "#ffffff",
              padding: 10,
              marginRight: 20,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              borderRadius: 10,
              marginTop: 5,
              height: "40px",
              width: "120px",
            }}
          >
            <FiFilter style={{ fontSize: 30, color: Main_Color }} />
            <div style={{ paddingLeft: 7 }}>Filter</div>
          </div>
        </div>
      )}

      <div style={{ display: "flex" }}>
        <div
          style={{
            flex: 1,
            backgroundColor: "#ffffff",
            margin: 10,
            height: "84vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            borderRadius: 10,
            display: "flex",
            flexDirection: "column",
          }}
        >
          <div
            style={{
              width: "90%",
              marginLeft: "40px",
              marginRight: "40px",
              margin: 10,
            }}
          >
            <InputText
              onChange={(e) => {
                setSearch(e.target.value);
                onSearch(e.target.value);
              }}
              maxLength={256}
              value={search}
              placeholder="Search Customer"
              style={{ width: "100%", borderRadius: 10 }}
            />
          </div>
          <div
            style={{
              width: "100%",
              marginLeft: "40px",
              marginRight: "40px",
              flex: 1,
              padding: 10,
              overflow: "scroll",
            }}
          >
            {summary ? (
              <div style={{ flex: 1, padding: 10 }}>
                <div
                  className="summarycard"
                  style={{
                    height: "74vh",
                    backgroundColor: "#ffffff",
                    borderRadius: 10,
                  }}
                >
                  <SummaryCard summarydata={summarydata} />
                </div>
              </div>
            ) : loading_customer ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flex: 1,
                  height: "100%",
                }}
              >
                <ReactLoading
                  type={"spin"}
                  color={Main_Color}
                  height={40}
                  width={40}
                />
              </div>
            ) : customer.length > 0 ? (
              customer.map((i, t) => {
                return (
                  <div
                    onClick={() => {
                      setCustomerId(i.customer_id);
                      loadconent_customer(i.customer_id);
                    }}
                    style={{
                      height: "40px",
                      backgroundColor:
                        customerid == i.customer_id ? Main_Color : "#f2f2f2",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      borderRadius: 10,
                      color:
                        customerid == i.customer_id ? "#ffffff" : "#000000",
                      marginTop: "5px",
                      flex: 1,
                    }}
                  >
                    <div
                      style={{
                        marginLeft: 10,
                        marginRight: 5,
                        color:
                          customerid == i.customer_id ? "#ffffff" : "green",
                      }}
                    >
                      {i.customer_id}
                    </div>
                    <div style={{ flex: 1 }}>{i.name}</div>
                  </div>
                );
              })
            ) : (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flex: 1,
                  height: "100%",
                }}
              >
                No Customer Found
              </div>
            )}
          </div>
        </div>
        <div style={{ flex: 3 }}>
          <div
            style={{
              height: "55px",
              display: "flex",
              width: "100%",
              justifyContent: "flex-end",
              alignItems: "center",
            }}
          >
            <div
              style={{
                flex: 1,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <div style={{ marginRight: "20px" }}>
                Start-Date : {moment(start).format("DD-MM-YYYY hh:ss")}
              </div>
              <div>End-Date : {moment(end).format("DD-MM-YYYY hh:ss")}</div>
            </div>
            <InputText
              placeholder="Search by id,name,amount"
              value={search_entry}
              style={{
                width: "300px",
                borderRadius: 10,
                height: "40px",
                marginRight: "20px",
              }}
              onChange={(e) => {
                onSearch_Entry(e.target.value);
              }}
            />
          </div>

          {sort ? (
            <div
              style={{
                padding: 10,
                backgroundColor: "#ffffff",
                marginTop: "10px",
                borderRadius: "10px",
                display: "flex",
                flex: 1,
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  marginRight: "20px",
                  alignItems: "center",
                }}
              >
                <div
                  onClick={() => Sorting("Id")}
                  style={{
                    backgroundColor: "#f2f2f2",
                    height: "35px",
                    width: "50px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    borderRadius: "10px",
                  }}
                >
                  Id
                </div>
              </div>
              <div
                style={{
                  flex: 1,
                  display: "flex",
                  justifyContent: "flex-start",
                  marginRight: "50px",
                  alignItems: "center",
                }}
              >
                <div
                  onClick={() => Sorting("Name")}
                  style={{
                    backgroundColor: "#f2f2f2",
                    height: "35px",
                    width: "100px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    borderRadius: "10px",
                  }}
                >
                  Name
                </div>
              </div>
              <div
                style={{
                  flex: 1,
                  display: "flex",
                  justifyContent: "center",
                  marginRight: "50px",
                  alignItems: "center",
                }}
              >
                <div
                  onClick={() => Sorting("Delivary")}
                  style={{
                    backgroundColor: "#f2f2f2",
                    height: "35px",
                    width: "140px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    borderRadius: "10px",
                  }}
                >
                  DELIVERY BOY
                </div>
              </div>
              <div
                style={{
                  flex: 1,
                  display: "flex",
                  justifyContent: "flex-end",
                  marginRight: "50px",
                  alignItems: "center",
                }}
              >
                <div
                  onClick={() => Sorting("Date")}
                  style={{
                    backgroundColor: "#f2f2f2",
                    height: "35px",
                    width: "100px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    borderRadius: "10px",
                  }}
                >
                  DATE
                </div>
              </div>
            </div>
          ) : null}
          {loading == true ? (
            <div
              style={{
                flex: 1,
                backgroundColor: "#ffffff",
                marginTop: 10,
                height: "74vh",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <img src={require("../../../Image/loading.gif")} />
            </div>
          ) : data.length > 0 ? (
            data.map((i) => {
              return <CustomerCard item={i} />;
            })
          ) : (
            <div
              style={{
                flex: 1,
                marginTop: 10,
                backgroundColor: "#ffffff",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "76vh",
              }}
            >
              <img
                src={require("../../../Image/noData.gif")}
                style={{ height: "50vh" }}
              />
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <div style={{ fontWeight: "bold", color: "gray" }}>
                  NO DATA FOUND
                </div>
                <div
                  onClick={() => loadconent()}
                  style={{
                    fontWeight: "bold",
                    color: "gray",
                    marginTop: 20,
                    padding: 15,
                    backgroundColor: Main_Color,
                    borderRadius: 10,
                    color: "#fff",
                  }}
                >
                  RELOAD
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Customer;
