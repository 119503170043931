import React, { Component } from "react";
import Backend from "../../Backend/Backend";
import { Checkbox } from "primereact/checkbox";
import { InputText } from "primereact/inputtext";
import { Main_Color } from "../../Theme/Theme";
const backend = new Backend();

class Print extends Component {
  constructor() {
    super();
    this.state = {
      barcode: false,
      logo: false,
      url: "",
    };
  }
  render() {
    return (
      <div
        style={{
          padding: 10,
          display: "flex",
          flexDirection: "column",
          flex: 1,
        }}
      >
        <div
          style={{
            padding: 40,
            paddingLeft: 10,
            fontSize: 30,
            flex: 1,
            paddingBottom: 0,
          }}
        >
          Print Setting
        </div>
        <div style={{ flex: 5, display: "flex", flexDirection: "column" }}>
          <div
            style={{
              height: "70px",
              backgroundColor: "#f2f2f2",
              borderStyle: "solid",
              borderWidth: 0,
              borderBottomWidth: 1,
              borderBottomColor: "#ffffff",
              display: "flex",
              borderRadius: 30,
            }}
          >
            <div
              style={{
                flex: 1,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              Show Logo
            </div>
            <div
              style={{
                flex: 1,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Checkbox
                onChange={(e) => this.setState({ logo: e.checked })}
                checked={this.state.logo}
              ></Checkbox>
            </div>
          </div>
          {this.state.logo == true ? (
            <div
              style={{
                height: "70px",
                backgroundColor: "#f2f2f2",
                marginTop: "20px",
                borderRadius: 30,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  flex: 1,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  color: Main_Color,
                }}
              >
                LOGO URL
              </div>
              <div
                style={{
                  flex: 1,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <InputText
                  onChange={(e) => {
                    this.setState({ url: e.target.value });
                  }}
                  value={this.state.url}
                  placeholder="Enter Logo Url"
                  style={{ width: "400px" }}
                />
              </div>
            </div>
          ) : null}
        </div>
      </div>
    );
  }
}

export default Print;
