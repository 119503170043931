import { Routes, Route, Link } from "react-router-dom";
import Login from "./Screen/Login/Login";
import Home from "./Screen/Home/Home";
import Report from "./Screen/Report/Report";
import SideBar from "./Screen/Auth/SideBar";
import Captain from "./Screen/Captain/Captain";
import Customer from "./Screen/Customer/Customer";
import Product from "./Screen/Product/Product";
import RouteS from "./Screen/Route/Route";
import ActivePlans from "./Screen/ActivePlans/ActivePlans";
import Register from "./Screen/Register/Register";
import Profile from "./Screen/Profile/Profile";
import Bill from "./Screen/Bill/Bill";
import Entry from "./Screen/Entry/Entry";
import { positions, Provider } from "react-alert";
import AlertTemplate from "react-alert-template-basic";
import { Offline, Online } from "react-detect-offline";
import "./App.css";
const options = {
  timeout: 5000,
  position: positions.TOP_RIGHT,
};
function App() {
  return (
    <div>
      {/* <Online> */}
      <div className="App">
        {localStorage.getItem("isLogged") == "1" ? (
          <div style={{ display: "flex" }}>
            <div
              style={{
                height: "100vh",
                width: 90,
                backgroundColor: "#f9f9f9",
              }}
            >
              <SideBar />
            </div>
            <Provider template={AlertTemplate} {...options}>
              <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/home" element={<Home />} />
                <Route path="/captain" element={<Captain />} />
                <Route path="/customer" element={<Customer />} />
                <Route path="/product" element={<Product />} />
                <Route path="/route" element={<RouteS />} />
                <Route path="/plan" element={<ActivePlans />} />
                <Route path="/bill" element={<Bill />} />
                <Route path="/entry" element={<Entry />} />
                <Route path="/report" element={<Report />} />
                <Route path="/profile" element={<Profile />} />
                <Route path="*" element={<Home />} />
              </Routes>
            </Provider>
          </div>
        ) : (
          <Provider template={AlertTemplate} {...options}>
            <Routes>
              <Route path="/login" element={<Login />} />
              <Route path="/register" element={<Register />} />
              <Route path="/" element={<Login />} />
              <Route path="*" element={<Login />} />
            </Routes>
          </Provider>
        )}
      </div>
      {/* </Online> */}

      {/* <Offline>
        <div
          style={{
            flex: 1,
            backgroundColor: "#000000",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img
            src={require("./Image/NoInternet.gif")}
            style={{ height: "100vh" }}
          />
        </div>
      </Offline> */}
    </div>
  );
}

export default App;
