import React, { useEffect, useState } from "react";
import "./Route.css";
import { InputText } from "primereact/inputtext";
import { Main_Color } from "../../../Theme/Theme";
import { AiFillCloseCircle } from "react-icons/ai";
import Backend from "../../../Backend/Backend";
import ReactLoading from "react-loading";
import { useAlert } from "react-alert";
const backend = new Backend();
const Route = (props) => {
  const i = props.data;
  const [token, setToken] = useState(localStorage.getItem("token"));
  const [adminid, setAdminid] = useState(localStorage.getItem("adminid"));
  const [route, setRoute] = useState("");
  const [alert_type, setAlert_type] = useState("");
  const [loading, setLoading] = useState(false);
  const alert = useAlert();

  const _chnage_route = () => {
    if (route == "") {
      setAlert_type(1);
      setTimeout(() => {
        setAlert_type(0);
      }, 1000);
    } else {
      let data = {
        token: token,
        admin_id: adminid,
        customer_id: i.customer_id,
        route: route,
      };

      setLoading(true);

      backend.edit_customer_route(data).then((r) => {
        if (r.error == "False") {
          alert.show(r.message);
          props.close();
          setLoading(false);
        } else {
          alert.error(r.message);
          setLoading(false);
        }
      });
    }
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flex: 1,
        height: "100vh",
      }}
    >
      <div
        style={{
          height: "50%",
          width: "40%",
          backgroundColor: "#f2f2f2",
          borderRadius: 10,
          display: "flex",
          alignSelf: "center",
          flexDirection: "column",
        }}
      >
        <div
          style={{
            padding: 20,
            justifyContent: "space-between",
            display: "flex",
            backgroundColor: Main_Color,
            borderTopLeftRadius: 10,
            borderTopRightRadius: 10,
            color: "#ffffff",
            alignItems: "center",
          }}
        >
          <div style={{ fontSize: 22 }}>Chnage Customer Route - {i.route}</div>
          <div onClick={() => props.close("a")}>
            <AiFillCloseCircle style={{ height: "40px", width: "40px" }} />
          </div>
        </div>

        <div style={{ display: "flex" }}>
          <div
            style={{
              flex: 1,
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div
              style={{
                padding: 10,
                justifyContent: "center",
                display: "flex",
                borderTopLeftRadius: 10,
                borderTopRightRadius: 10,
                color: "#ffffff",
              }}
            >
              <span style={{ display: "flex", flexDirection: "column" }}>
                <InputText
                  placeholder="Enter New Route"
                  value={route}
                  style={{
                    width: "500px",
                    marginTop: 5,
                    borderWidth: alert_type === 1 ? 2 : 1,
                    borderColor: alert_type === 1 ? "red" : "",
                  }}
                  onChange={(e) => setRoute(e.target.value)}
                />
                {alert_type === 1 ? (
                  <div style={{ marginTop: 5, paddingLeft: 10, color: "red" }}>
                    {"Invalid Route"}
                  </div>
                ) : null}
              </span>
            </div>
            {loading === true ? (
              <div
                style={{
                  alignSelf: "center",
                  marginTop: 10,
                  height: "50px",
                  width: "200px",
                  backgroundColor: Main_Color,
                  borderRadius: 10,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  color: "#ffffff",
                  fontSize: 18,
                }}
              >
                <ReactLoading
                  type={"bars"}
                  color={"#fff"}
                  height={45}
                  width={45}
                />
              </div>
            ) : (
              <div
                onClick={() => _chnage_route()}
                style={{
                  alignSelf: "center",
                  marginTop: 10,
                  height: "50px",
                  width: "200px",
                  backgroundColor: Main_Color,
                  borderRadius: 10,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  color: "#ffffff",
                  fontSize: 18,
                }}
              >
                Submit
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Route;
