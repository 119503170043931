import React, { useEffect } from "react";
import { useState } from "react";
import { AiFillCloseCircle, AiFillCheckCircle } from "react-icons/ai";
import Backend from "../../../Backend/Backend";
import ReactLoading from "react-loading";
import { Main_Color } from "../../../Theme/Theme";
const backend = new Backend();

export default function Image(props) {
  const [loading, setLoading] = useState(false);
  const [image, setImage] = useState([]);
  const [image_name, setImage_Name] = useState("");
  const [token, setToken] = React.useState(localStorage.getItem("token"));
  const [adminid, setAdminid] = React.useState(localStorage.getItem("adminid"));

  useEffect(() => {
    load_product();
  }, []);

  const load_product = () => {
    setLoading(true);
    var data = {
      token: token,
      admin_id: adminid,
    };
    backend.load_image(data).then((r) => {
      setLoading(false);
      if (r.error === "False") {
        setImage(r.data);
      } else {
        alert(r.message);
      }
    });
  };

  const edit_product = () => {
    if (image_name == "") {
      alert("Image is Empty");
    } else {
      setLoading(true);
      let data = {
        token: token,
        admin_id: adminid,
        product_name: props.data.product_name,
        default_price: props.data.default_price,
        allowed_empty: props.data.allowed_empty,
        status: props.data.status,
        image: image_name,
        product_id: props.data.id,
      };
      backend.edit_product(data).then((r) => {
        setLoading(false);
        if (r.error == "False") {
          props.close("Image");
        } else {
          alert(r.message);
        }
      });
    }
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flex: 1,
        height: "100vh",
      }}
    >
      <div
        style={{
          height: "70%",
          width: "60%",
          backgroundColor: "#f2f2f2",
          borderRadius: 10,
          display: "flex",
          alignSelf: "center",
          flexDirection: "column",
        }}
      >
        <div
          style={{
            padding: 20,
            justifyContent: "space-between",
            display: "flex",
            backgroundColor: Main_Color,
            borderTopLeftRadius: 10,
            borderTopRightRadius: 10,
            color: "#ffffff",
            alignItems: "center",
          }}
        >
          <div style={{ fontSize: 22, flex: 1 }}>
            Edit Image - {props.data.product_name}{" "}
          </div>
          <div onClick={() => edit_product()}>
            <AiFillCheckCircle
              style={{ height: "40px", width: "40px", marginRight: "30px" }}
            />
          </div>
          <div onClick={() => props.close("a")}>
            <AiFillCloseCircle style={{ height: "40px", width: "40px" }} />
          </div>
        </div>

        <div
          style={{
            height: "100%",
            backgroundColor: "#ffffff",
            display: "flex",
            flexWrap: "wrap",
            overflowY: "scroll",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {image.map((i) => {
            return (
              <div
                onClick={() => setImage_Name(i.image_name)}
                style={{
                  margin: "10px",
                  padding: "10px",
                  backgroundColor:
                    image_name == i.image_name ? "green" : "#f2f2f2",
                  borderRadius: "10px",
                }}
              >
                <img
                  src={i.path}
                  height="100px"
                  width={"100px"}
                  style={{
                    backgroundColor:
                      image_name == i.image_name ? "green" : "#f2f2f2",
                    borderRadius: "10px",
                  }}
                />
              </div>
            );
          })}
        </div>
        <div
          style={{
            display: "flex",
            flex: 1,
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {/* <div style={{ flex: 1, display: "flex", flexDirection: "column" }}>
            {loading === true ? (
              <div
                style={{
                  alignSelf: "center",
                  marginTop: 40,
                  height: "50px",
                  width: "200px",
                  backgroundColor: Main_Color,
                  borderRadius: 10,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  color: "#ffffff",
                  fontSize: 18,
                }}
              >
                <ReactLoading
                  type={"bars"}
                  color={"#fff"}
                  height={45}
                  width={45}
                />
              </div>
            ) : (
              <div
                style={{
                  alignSelf: "center",
                  marginTop: 40,
                  height: "50px",
                  width: "200px",
                  backgroundColor: Main_Color,
                  borderRadius: 10,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  color: "#ffffff",
                  fontSize: 18,
                }}
              >
                Submit
              </div>
            )}
          </div> */}
        </div>
      </div>
    </div>
  );
}
