import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Main_Color } from "../../../Theme/Theme";
import { FcManager } from "react-icons/fc";
export default class TopCard extends Component {
  render() {
    return (
      <div
        style={{
          height: "150px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div style={{ padding: 20, paddingLeft: 40, flex: 1 }}>
          <div
            style={{
              fontSize: 22,
              color: "#000000",
              fontWeight: "bold",
              fontFamily: "Nexa",
              display: "flex",
              alignItems: "flex-start",
              flexDirection: "column",
            }}
          >
            <div style={{ fontSize: 18, color: "#000000" }}>
              Welcome {this.props.data.name} !
            </div>
            <div style={{ marginTop: 5, fontSize: 30, color: "#000000" }}>
              {this.props.data.company_name} !
            </div>
            <div style={{ fontSize: 18, marginTop: 5, color: "#000000" }}>
              Master Id : {this.props.data.id}
            </div>
          </div>
        </div>
        <Link
          to="/profile"
          style={{ textDecoration: "none", marginRight: "20px" }}
        >
          <div
            style={{
              marginRight: "40px",
              backgroundColor: Main_Color,
              padding: 7,
              borderRadius: 50,
            }}
          >
            <FcManager style={{ fontSize: 55 }} />
          </div>
        </Link>
      </div>
    );
  }
}
