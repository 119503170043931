import React, { useEffect } from "react";
import { AiFillCloseCircle } from "react-icons/ai";
import { FiFilter } from "react-icons/fi";
import { Main_Color } from "../../../Theme/Theme";
import Backend from "../../../Backend/Backend";
import { Calendar } from "primereact/calendar";
import RouteCard from "./RouteCard";
import moment from "moment";
import { Dropdown } from "primereact/dropdown";
const backend = new Backend();
const Route = () => {
  const [filter, setFilter] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [start, setStart] = React.useState(
    moment(new Date()).format("YYYY-MM-DD 00:00:00")
  );
  const [end, setEnd] = React.useState(
    moment(new Date()).format("YYYY-MM-DD 23:59:59")
  );
  const [data, setData] = React.useState([]);
  const [data_search, setDataSearch] = React.useState([]);
  const [route, setRoute] = React.useState([]);
  const [total, setTotal] = React.useState(0);
  const [route_id, setRoute_Id] = React.useState("");

  useEffect(() => {
    loadconent();
    load_route();
  }, []);

  const load_route = () => {
    let data = {
      token: localStorage.getItem("token"),
      admin_id: localStorage.getItem("adminid"),
    };

    backend.load_route(data).then((r) => {
      if (r.error == "False") {
        let arrayObj = r.data;
        arrayObj = arrayObj.map((item) => {
          return {
            value: item.id,
            label: item.route,
          };
        });
        setRoute(arrayObj);
        setLoading(false);
      } else {
        setLoading(false);
      }
    });
  };

  const loadconent = () => {
    setLoading(true);
    let data = {
      token: localStorage.getItem("token"),
      admin_id: localStorage.getItem("adminid"),
      datefrom: start,
      dateto: end,
      route: route_id,
    };

    backend
      .load_route_report(data)
      .then((r) => {
        var total = 0;
        r.data.map((x) => (total = parseFloat(total) + parseFloat(x.total)));
        setData(r.data);
        setDataSearch(r.data);
        setLoading(false);
        setTotal(total);
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  const filterarray = (a) => {
    if (a == "ALL") {
      var total = 0;
      data_search.map((x) => (total = parseFloat(total) + parseFloat(x.total)));
      setData(data_search);
      setTotal(total);
    }
    if (a == "CASH") {
      var data = data_search.filter(
        (x) => x.transaction_type == 0 && x.cash == 1
      );
      var total = 0;
      data.map((x) => (total = parseFloat(total) + parseFloat(x.total)));
      setData(data);
      setTotal(total);
    }

    if (a == "PAYMENT") {
      var data = data_search.filter((x) => x.transaction_type == 1);
      var total = 0;
      data.map((x) => (total = parseFloat(total) + parseFloat(x.total)));

      setData(data);
      setTotal(total);
    }
    if (a == "CARD") {
      var data = data_search.filter(
        (x) => x.transaction_type == 0 && x.cash == 0
      );
      var total = 0;
      data.map((x) => (total = parseFloat(total) + parseFloat(x.total)));
      setData(data);
      setTotal(total);
    }
  };

  return (
    <div>
      {filter == true ? (
        <div
          style={{
            height: "55px",
            backgroundColor: "#ffffff",
            display: "flex",
            justifyContent: "space-around",
            alignItems: "center",
            borderRadius: 10,
          }}
        >
          <div style={{ display: "flex", alignItems: "center" }}>
            <div
              onClick={() => filterarray("ALL")}
              style={{
                padding: 10,
                backgroundColor: Main_Color,
                borderRadius: 10,
                color: "#fff",
              }}
            >
              ALL
            </div>
          </div>
          <div style={{ display: "flex", alignItems: "center" }}>
            <div
              onClick={() => filterarray("CASH")}
              style={{
                padding: 10,
                padding: 10,
                backgroundColor: Main_Color,
                borderRadius: 10,
                color: "#fff",
              }}
            >
              CASH
            </div>
          </div>
          <div style={{ display: "flex", alignItems: "center" }}>
            <div
              onClick={() => filterarray("CARD")}
              style={{
                padding: 10,
                padding: 10,
                backgroundColor: Main_Color,
                borderRadius: 10,
                color: "#fff",
              }}
            >
              CARD
            </div>
          </div>
          <div style={{ display: "flex", alignItems: "center" }}>
            <div
              onClick={() => filterarray("PAYMENT")}
              style={{
                padding: 10,
                padding: 10,
                backgroundColor: Main_Color,
                borderRadius: 10,
                color: "#fff",
              }}
            >
              PAYMENTS
            </div>
          </div>
          <div style={{ display: "flex", alignItems: "center" }}>
            <div style={{ paddingRight: 10 }}>Customer</div>
            <div>
              <Dropdown
                value={route_id}
                options={route}
                onShow={() => setRoute_Id("")}
                onChange={(e) => {
                  setRoute_Id(e.value);
                  loadconent();
                }}
                placeholder="Select a Category"
              />
            </div>
          </div>
          <div style={{ display: "flex", alignItems: "center" }}>
            <div style={{ paddingRight: 10 }}>Start Date</div>
            <div>
              <Calendar
                style={{ height: "40px" }}
                dateFormat="dd/mm/yy"
                value={start}
                onChange={(e) => {
                  setStart(moment(e.value).format("DD-MM-YYYY 00:00:00"));
                }}
                maxDate={new Date()}
              ></Calendar>
            </div>
          </div>
          <div style={{ display: "flex", alignItems: "center" }}>
            <div style={{ paddingRight: 10 }}>End Date</div>
            <div>
              <Calendar
                dateFormat="dd/mm/yy"
                style={{ height: "40px" }}
                value={end}
                onChange={(e) => {
                  setEnd(moment(e.value).format("DD-MM-YYYY 23:59:59"));
                  loadconent();
                }}
                maxDate={new Date()}
              ></Calendar>
            </div>
          </div>
          <div
            onClick={() => setFilter(false)}
            style={{
              backgroundColor: "#f2f2f2",
              padding: 7,
              borderRadius: 10,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <AiFillCloseCircle style={{ fontSize: 32, color: Main_Color }} />
          </div>
        </div>
      ) : (
        <div
          style={{
            height: "40px",
            backgroundColor: "#f2f2f2",
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
          }}
        >
          <div
            onClick={() => setFilter(true)}
            style={{
              backgroundColor: "#ffffff",
              padding: 10,
              marginRight: 20,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              borderRadius: 10,
              marginTop: 5,
              height: "40px",
              width: "120px",
            }}
          >
            <FiFilter style={{ fontSize: 30, color: Main_Color }} />
            <div style={{ paddingLeft: 7 }}>Filter</div>
          </div>
        </div>
      )}
      {loading == true ? (
        <div
          style={{
            flex: 1,
            backgroundColor: "#fff",
            marginTop: 10,
            height: "78vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img src={require("../../../Image/loading.gif")} />
        </div>
      ) : data.length > 0 ? (
        <>
          {data.map((i) => {
            return <RouteCard item={i} />;
          })}
          <div
            style={{
              height: "55px",
              padding: 10,
              backgroundColor: Main_Color,
              marginTop: "20px",
              borderRadius: 20,
              color: "#fff",
              fontSize: 30,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            Total - {total}
          </div>
        </>
      ) : (
        <div
          style={{
            flex: 1,
            marginTop: 10,
            backgroundColor: "#ffffff",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "76vh",
          }}
        >
          <img
            src={require("../../../Image/noData.gif")}
            style={{ height: "50vh" }}
          />
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div style={{ fontWeight: "bold", color: "gray" }}>
              NO DATA FOUND
            </div>
            <div
              onClick={() => loadconent()}
              style={{
                fontWeight: "bold",
                color: "gray",
                marginTop: 20,
                padding: 15,
                backgroundColor: Main_Color,
                borderRadius: 10,
                color: "#fff",
              }}
            >
              RELOAD
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Route;
