import React, { useEffect } from "react";
import { AiFillCloseCircle } from "react-icons/ai";
import { FiFilter } from "react-icons/fi";
import { Main_Color } from "../../../Theme/Theme";
import Backend from "../../../Backend/Backend";
import { Calendar } from "primereact/calendar";
import { InputText } from "primereact/inputtext";
import WorkerCard from "./WorkerCard";
import ReactLoading from "react-loading";
import moment from "moment";
import * as XLSX from "xlsx";
const backend = new Backend();
const Worker = () => {
  const [filter, setFilter] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [loading_worker, setLoadingWorker] = React.useState(false);
  const [start, setStart] = React.useState(
    moment(new Date()).format("YYYY-MM-DD 00:00:00")
  );
  const [end, setEnd] = React.useState(
    moment(new Date()).format("YYYY-MM-DD 23:59:59")
  );
  const [data, setData] = React.useState([]);
  const [data_search, setData_Search] = React.useState([]);
  const [data_cash, setData_Cash] = React.useState([]);
  const [data_card, setData_Card] = React.useState([]);
  const [data_payment, setData_Payment] = React.useState([]);

  const [summary, setSummay] = React.useState(false);
  const [summarydata, setSummayData] = React.useState([]);
  const [worker, setWorker] = React.useState([]);
  const [worker_filter, setWorker_Filter] = React.useState([]);
  const [total, setTotal] = React.useState(0);
  const [filter_id, setFilter_type] = React.useState(0);
  const [search, setSearch] = React.useState("");
  const [search_entry, setSearch_Entry] = React.useState("");
  const [worker_id, setWorker_id] = React.useState("");
  const [month, setMonth] = React.useState("");

  useEffect(() => {
    loadconent();
    load_worker();
  }, []);

  const load_worker = () => {
    setLoadingWorker(true);
    let data = {
      token: localStorage.getItem("token"),
      admin_id: localStorage.getItem("adminid"),
    };

    backend.load_worker(data).then((r) => {
      if (r.error == "False") {
        setWorker(r.data);
        setWorker_Filter(r.data);
        setLoadingWorker(false);
      } else {
        setLoadingWorker(false);
      }
    });
  };

  const loadconent = (a) => {
    var startdata, enddata;
    setMonth(a);
    if (a == "Today") {
      startdata = moment(new Date()).format("YYYY-MM-DD 00:00:00");
      enddata = moment(new Date()).format("YYYY-MM-DD 23:59:59");
      setStart(startdata);
      setEnd(enddata);
    } else if (a == "Yesterday") {
      startdata = moment().add(-1, "days").format("YYYY-MM-DD 00:00:00");
      enddata = moment().add(-1, "days").format("YYYY-MM-DD 23:59:59");
      setStart(startdata);
      setEnd(enddata);
    } else if (a == "Last 7 Days") {
      startdata = moment(new Date()).format("YYYY-MM-DD 00:00:00");
      enddata = moment().add(-7, "days").format("YYYY-MM-DD 23:59:59");
      setStart(startdata);
      setEnd(enddata);
    } else if (a == "This Month") {
      startdata = moment().startOf("month").format("YYYY-MM-DD 00:00:00");
      enddata = moment().endOf("month").format("YYYY-MM-DD 23:59:59");
      setStart(startdata);
      setEnd(enddata);
    } else if (a == "Last Month") {
      let lastmonth = moment(new Date())
        .subtract(1, "months")
        .format("YYYY-MM-DD");
      startdata = moment(lastmonth)
        .startOf("month")
        .format("YYYY-MM-DD 00:00:00");
      enddata = moment(lastmonth).endOf("month").format("YYYY-MM-DD 23:59:59");

      setStart(startdata);
      setEnd(enddata);
    } else if (a == "This Year") {
      startdata = moment(new Date())
        .subtract(1, "year")
        .format("YYYY-MM-DD 00:00:00");
      enddata = moment(new Date())
        .subtract(0, "year")
        .format("YYYY-MM-DD 23:59:59");
      setStart(startdata);
      setEnd(enddata);
    }
    setLoading(true);
    let data = {
      token: localStorage.getItem("token"),
      admin_id: localStorage.getItem("adminid"),
      datefrom: a != undefined ? startdata : start,
      dateto: a != undefined ? enddata : end,
      worker_id: worker_id,
    };
    backend
      .load_report_worker(data)
      .then((r) => {
        var total = 0;
        r.data.map((x) => (total = parseFloat(total) + parseFloat(x.total)));

        var data_cash = r.data.filter(
          (x) => x.transaction_type == 0 && x.cash == 1
        );
        var data_card = r.data.filter(
          (x) => x.transaction_type == 0 && x.cash == 0
        );
        var data_payment = r.data.filter((x) => x.transaction_type == 1);

        setData(r.data);
        setData_Search(r.data);
        setData_Cash(data_cash);
        setData_Card(data_card);
        setData_Payment(data_payment);
        setSummayData(r.overview);
        setLoading(false);
        setTotal(total);
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  const filterarray = (a) => {
    if (a == "ALL") {
      setFilter_type(0);
      var total = 0;
      data_search.map((x) => (total = parseFloat(total) + parseFloat(x.total)));
      setData(data_search);
      setTotal(total);
    }
    if (a == "CASH") {
      setFilter_type(1);
      var total = 0;
      data_cash.map((x) => (total = parseFloat(total) + parseFloat(x.total)));
      setData(data_cash);
      setTotal(total);
    }

    if (a == "PAYMENT") {
      setFilter_type(3);
      var total = 0;
      data_payment.map(
        (x) => (total = parseFloat(total) + parseFloat(x.total))
      );
      setData(data_payment);
      setTotal(total);
    }
    if (a == "CARD") {
      setFilter_type(2);
      var total = 0;
      data_card.map((x) => (total = parseFloat(total) + parseFloat(x.total)));
      setData(data_card);
      setTotal(total);
    }
  };

  const onSearch = (e) => {
    setSearch(e);
    if (search.length > 1) {
      let filteredCustomer = [];
      for (var i = 0; i < worker_filter.length; i++) {
        if (worker_filter[i].name.toLowerCase().includes(e.toLowerCase())) {
          filteredCustomer.push(worker_filter[i]);
        }
      }
      setWorker(filteredCustomer);
    } else {
      setWorker(worker_filter);
    }
  };

  const onSearch_Entry = (e) => {
    setSearch_Entry(e);
    if (search_entry.length > 1) {
      let filteredCustomer = [];
      for (var i = 0; i < data_search.length; i++) {
        if (data_search[i].name.toLowerCase().includes(e.toLowerCase())) {
          filteredCustomer.push(data_search[i]);
        } else if (
          data_search[i].customer_id.toLowerCase().includes(e.toLowerCase())
        ) {
          filteredCustomer.push(data_search[i]);
        } else if (
          data_search[i].total.toLowerCase().includes(e.toLowerCase())
        ) {
          filteredCustomer.push(data_search[i]);
        }
      }
      setData(filteredCustomer);
    } else {
      setData(data_search);
    }
  };

  const Excel_donwload = () => {
    if (data.length > 0) {
      var wb = XLSX.utils.book_new();
      var ws = XLSX.utils.json_to_sheet(data);

      XLSX.utils.book_append_sheet(wb, ws, "All");

      XLSX.writeFile(wb, `All ${moment(new Date()).format("DD-MM-YYYY")}.xlsx`);
    } else {
      alert.error("No Report Found");
    }
  };

  return (
    <div>
      {filter == true ? (
        <div
          style={{
            height: "55px",
            backgroundColor: "#ffffff",
            display: "flex",
            justifyContent: "space-around",
            alignItems: "center",
            borderRadius: 10,
          }}
        >
          <div style={{ display: "flex", alignItems: "center" }}>
            <div style={{ paddingRight: 10 }}>Start Date</div>
            <div>
              <Calendar
                style={{ height: "40px" }}
                dateFormat="dd/mm/yy"
                value={start}
                onChange={(e) => {
                  setStart(moment(e.value).format("YYYY-MM-DD 00:00:00"));
                }}
                maxDate={new Date()}
              ></Calendar>
            </div>
          </div>
          <div style={{ display: "flex", alignItems: "center" }}>
            <div style={{ paddingRight: 10 }}>End Date</div>
            <div>
              <Calendar
                dateFormat="dd/mm/yy"
                style={{ height: "40px" }}
                value={end}
                onHide={() => loadconent()}
                onChange={(e) => {
                  setEnd(moment(e.value).format("YYYY-MM-DD23:59:59"));
                }}
                maxDate={new Date()}
              ></Calendar>
            </div>
          </div>
          <div
            onClick={() => Excel_donwload()}
            style={{
              backgroundColor: "#ffffff",
              padding: 10,
              marginRight: 20,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              borderRadius: 10,
              marginTop: 5,
              height: "40px",
              width: "120px",
              borderStyle: "solid",
              borderColor: Main_Color,
            }}
          >
            <img
              src={require("../../../Image/excel.png")}
              style={{ height: "30px" }}
            />
            <div style={{ paddingLeft: 7 }}>Excel</div>
          </div>
          <div
            onClick={() => setFilter(false)}
            style={{
              backgroundColor: "#f2f2f2",
              padding: 7,
              borderRadius: 10,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <AiFillCloseCircle style={{ fontSize: 32, color: Main_Color }} />
          </div>
        </div>
      ) : (
        <div
          style={{
            height: "40px",
            backgroundColor: "#f2f2f2",
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
          }}
        >
          <div style={{ flex: 1, display: "flex" }}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                paddingLeft: "10px",
              }}
            >
              <div
                onClick={() => filterarray("ALL")}
                style={{
                  padding: 10,
                  backgroundColor: filter_id == 0 ? Main_Color : "#ffffff",
                  borderRadius: 10,
                  color: filter_id == 0 ? "#ffffff" : Main_Color,
                }}
              >
                ALL
              </div>
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                paddingLeft: "10px",
              }}
            >
              <div
                onClick={() => filterarray("CASH")}
                style={{
                  padding: 10,
                  padding: 10,
                  backgroundColor: filter_id == 1 ? Main_Color : "#ffffff",
                  borderRadius: 10,
                  color: filter_id == 1 ? "#ffffff" : Main_Color,
                }}
              >
                CASH
              </div>
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                paddingLeft: "10px",
              }}
            >
              <div
                onClick={() => filterarray("CARD")}
                style={{
                  padding: 10,
                  padding: 10,
                  backgroundColor: filter_id == 2 ? Main_Color : "#ffffff",
                  borderRadius: 10,
                  color: filter_id == 2 ? "#ffffff" : Main_Color,
                }}
              >
                CARD
              </div>
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                paddingLeft: "10px",
              }}
            >
              <div
                onClick={() => filterarray("PAYMENT")}
                style={{
                  padding: 10,
                  padding: 10,
                  backgroundColor: filter_id == 3 ? Main_Color : "#ffffff",
                  borderRadius: 10,
                  color: filter_id == 3 ? "#ffffff" : Main_Color,
                }}
              >
                PAYMENTS
              </div>
            </div>
            <div class="dropdown">
              <div class="dropbtn">{month == null ? "Select" : month}</div>
              <div class="dropdown-content">
                <a onClick={() => loadconent("Today")}>Today</a>
                <a onClick={() => loadconent("Yesterday")}>Yesterday</a>
                <a onClick={() => loadconent("Last 7 Days")}>Last 7 Days</a>
                <a onClick={() => loadconent("This Month")}>This Month</a>
                <a onClick={() => loadconent("Last Month")}>Last Month</a>
                <a onClick={() => loadconent("This Year")}>This Year</a>
              </div>
            </div>
          </div>

          <div
            style={{
              backgroundColor: Main_Color,
              padding: 10,
              marginRight: 20,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              borderRadius: 10,
              marginTop: 5,
              height: "40px",
              paddingLeft: "15px",
              paddingRight: "15px",
            }}
          >
            <div style={{ paddingLeft: 7, color: "#ffffff" }}>
              TOTAL - {parseFloat(total).toFixed(2)}
            </div>
          </div>
          <div
            onClick={() => setFilter(true)}
            style={{
              backgroundColor: "#ffffff",
              padding: 10,
              marginRight: 20,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              borderRadius: 10,
              marginTop: 5,
              height: "40px",
              width: "120px",
            }}
          >
            <FiFilter style={{ fontSize: 30, color: Main_Color }} />
            <div style={{ paddingLeft: 7 }}>Filter</div>
          </div>
        </div>
      )}
      <div style={{ display: "flex" }}>
        <div style={{ flex: 1 }}>
          <div style={{ height: "55px" }} />
          <div
            style={{
              flex: 1,
              backgroundColor: "#ffffff",
              margin: 10,
              height: "76vh",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              borderRadius: 10,
              display: "flex",
              flexDirection: "column",
              flex: 1,
              overflow: "scroll",
            }}
          >
            <div
              style={{
                width: "90%",
                marginLeft: "40px",
                marginRight: "40px",
                margin: 10,
              }}
            >
              <InputText
                onChange={(e) => {
                  setSearch(e.target.value);
                  onSearch(e.target.value);
                }}
                maxLength={256}
                value={search}
                placeholder="Search Worker"
                style={{ width: "100%", borderRadius: 10 }}
              />
            </div>
            <div
              style={{
                width: "100%",
                marginLeft: "40px",
                marginRight: "40px",
                flex: 1,
                padding: 10,
                overflow: "scroll",
              }}
            >
              {loading_worker ? (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flex: 1,
                    height: "100%",
                  }}
                >
                  <ReactLoading
                    type={"spin"}
                    color={Main_Color}
                    height={40}
                    width={40}
                  />
                </div>
              ) : worker.length > 0 ? (
                worker.map((i, t) => {
                  return (
                    <div
                      onClick={() => {
                        setWorker_id(i.id);
                        loadconent();
                      }}
                      style={{
                        height: "40px",
                        backgroundColor:
                          worker_id == i.id ? Main_Color : "#f2f2f2",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        borderRadius: 10,
                        color: worker_id == i.id ? "#ffffff" : "#000000",
                        marginTop: "5px",
                        flex: 1,
                      }}
                    >
                      {i.name}
                    </div>
                  );
                })
              ) : (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flex: 1,
                    height: "100%",
                  }}
                >
                  No Woker Found
                </div>
              )}
            </div>
          </div>
        </div>
        <div style={{ flex: 3 }}>
          <div
            style={{
              height: "55px",
              display: "flex",
              width: "100%",
              justifyContent: "flex-end",
              alignItems: "center",
            }}
          >
            <div
              style={{
                flex: 1,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <div style={{ marginRight: "20px" }}>
                Start-Date : {moment(start).format("DD-MM-YYYY hh:ss")}
              </div>
              <div>End-Date : {moment(end).format("DD-MM-YYYY hh:ss")}</div>
            </div>
            <InputText
              placeholder="Search by id,name,amount"
              value={search_entry}
              style={{
                width: "300px",
                borderRadius: 10,
                height: "40px",
                marginRight: "20px",
              }}
              onChange={(e) => {
                onSearch_Entry(e.target.value);
              }}
            />
          </div>
          {loading == true ? (
            <div
              style={{
                flex: 1,
                backgroundColor: "#ffffff",
                marginTop: 10,
                height: "76vh",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                borderRadius: 10,
              }}
            >
              <img src={require("../../../Image/loading.gif")} />
            </div>
          ) : data.length > 0 ? (
            <div
              style={{
                overflow: "scroll",
                height: "76vh",
                borderRadius: 10,
                marginTop: "10px",
              }}
            >
              {data.map((i) => {
                return <WorkerCard item={i} />;
              })}
            </div>
          ) : (
            <div
              style={{
                flex: 1,
                marginTop: 10,
                backgroundColor: "#ffffff",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "76vh",
                borderRadius: 10,
              }}
            >
              <img
                src={require("../../../Image/noData.gif")}
                style={{ height: "50vh" }}
              />
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <div style={{ fontWeight: "bold", color: "gray" }}>
                  NO WORKER REPORT FOUND
                </div>
                <div
                  onClick={() => loadconent()}
                  style={{
                    fontWeight: "bold",
                    color: "gray",
                    marginTop: 20,
                    padding: 15,
                    backgroundColor: Main_Color,
                    borderRadius: 10,
                    color: "#fff",
                  }}
                >
                  RELOAD
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Worker;
