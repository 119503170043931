import React from "react";
import { AiFillCaretDown, AiFillCaretUp, AiFillIdcard } from "react-icons/ai";
import { SiSamsungpay } from "react-icons/si";
import { BsCash } from "react-icons/bs";
import { Main_Color } from "../../../Theme/Theme";
export default function RouteCard(props) {
  const [show, setShow] = React.useState(false);
  const i = props.item;
  return (
    <>
      <div
        key={i.id}
        onClick={() => setShow(!show)}
        style={{
          display: "flex",
          flex: 1,
          backgroundColor: "#ffffff",
          justifyContent: "space-between",
          alignItems: "center",
          borderTopLeftRadius: 10,
          borderTopRightRadius: 10,
          borderBottomLeftRadius: show ? 0 : 10,
          borderBottomRightRadius: show ? 0 : 10,
          padding: 10,
          marginTop: 10,
        }}
      >
        <div
          style={{
            paddingLeft: 20,
            display: "flex",
            alignItems: "center",
            flex: 1,
          }}
        >
          <div style={{ paddingRight: 20, color: "#585858" }}>{i.id}</div>
          <div
            style={{
              color: "#000000",
              fontWeight: "bold",
              fontSize: 18,
              flex: 1,
            }}
          >
            {i.customer_id} - {i.name}
          </div>
          <div
            style={{
              color: "green",
              fontWeight: "bold",
              flex: 1,
            }}
          >
            Total - {i.total}
          </div>
          <div
            style={{
              color: "#000",
              fontWeight: "bold",
              fontSize: 20,
              display: "flex",
              flex: 1,
            }}
          >
            Delivery Boy -<div style={{ color: "green" }}>{" " + i.wname}</div>
          </div>
          <div
            style={{
              color: "#000",
              fontWeight: "bold",
              fontSize: 20,
              display: "flex",
            }}
          >
            {i.transaction_type == 0 ? (
              <div
                style={{
                  backgroundColor: Main_Color,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  padding: 5,
                  borderRadius: 10,
                  color: "#fff",
                  marginRight: 10,
                }}
              >
                {i.cash == 1 ? (
                  <BsCash style={{ color: "#ffffff", fontSize: 25 }} />
                ) : null}
                {i.transaction_type == 0 && i.cash == 0 ? (
                  <AiFillIdcard style={{ color: "#ffffff", fontSize: 25 }} />
                ) : null}
              </div>
            ) : null}

            <div
              style={{
                backgroundColor: i.transaction_type == 0 ? Main_Color : "#fff",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                padding: 5,
                borderRadius: 10,
                color: "#fff",
              }}
            >
              {i.transaction_type == 0 ? (
                show == true ? (
                  <AiFillCaretUp style={{ fontSize: 25 }} />
                ) : (
                  <AiFillCaretDown style={{ fontSize: 25 }} />
                )
              ) : (
                <SiSamsungpay style={{ color: Main_Color, fontSize: 25 }} />
              )}
            </div>
          </div>
        </div>
      </div>
      {i.transaction_type == 0 ? (
        show ? (
          <div
            style={{
              backgroundColor: "#ffffff",
              justifyContent: "space-between",
              alignItems: "center",
              padding: 10,
              borderBottomLeftRadius: 10,
              borderBottomRightRadius: 10,
            }}
          >
            {i.items.map((i) => {
              return (
                <div
                  key={i.id}
                  onClick={() => setShow(!show)}
                  style={{
                    display: "flex",
                    flex: 1,
                    backgroundColor: "#ffffff",
                    justifyContent: "space-between",
                    alignItems: "center",
                    padding: 10,
                    borderBottomLeftRadius: 10,
                    borderBottomRightRadius: 10,
                  }}
                >
                  <div
                    style={{
                      paddingLeft: 20,
                      display: "flex",
                      alignItems: "center",
                      flex: 1,
                    }}
                  >
                    <div style={{ paddingRight: 20, color: "#585858" }}>
                      <img src={i.image} style={{ height: 30, width: 30 }} />
                    </div>
                    <div
                      style={{
                        color: "#000000",
                        fontWeight: "bold",
                        fontSize: 18,
                        flex: 1,
                      }}
                    >
                      {i.product_name}
                    </div>
                    <div
                      style={{
                        color: "#585858",
                        fontWeight: "bold",
                        flex: 1,
                      }}
                    >
                      F : {i.filled} E : {i.empty}
                    </div>
                    <div
                      style={{
                        color: i.cash == 1 ? "blue" : "red",
                        fontWeight: "bold",
                        fontSize: 20,
                        display: "flex",
                        flex: 1,
                      }}
                    >
                      <div>{" " + i.amount}</div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        ) : null
      ) : null}
    </>
  );
}
