const iState = {
  cart: [],
};

const Login_Reducer = (state = iState, action) => {
  switch (action.type) {
    case "REMOVE_CART":
      return {
        ...state,
        cart: [],
      };
    case "DECREMENT":
      return state - 1;

    case "ADD_FILLED":
      var newcart = state.cart;
      if (newcart.length == 0) {
        newcart = [];
        action.data.filled = 1;
        newcart.push(action.data);
        return {
          ...state,
          cart: newcart,
        };
      } else {
        var found = "";
        for (var i = 0; i < newcart.length; i++) {
          if (newcart[i].id == action.data.id) {
            found = i;
            continue;
          }
        }

        if (found === "") {
          action.data.filled = 1;
          newcart.push(action.data);
        } else if (isNaN(newcart[found].filled) == true) {
          newcart[found].filled = 1;
        } else {
          newcart[found].filled += 1;
        }
        return {
          ...state,
          cart: newcart,
        };
      }

    case "ADD_FILLED_QTY":
      var newcart = state.cart;
      if (newcart.length == 0) {
        newcart = [];
        action.data.filled = action.qty;
        newcart.push(action.data);
        return {
          ...state,
          cart: newcart,
        };
      } else {
        var found = "";
        for (var i = 0; i < newcart.length; i++) {
          if (newcart[i].id == action.data.id) {
            found = i;
            continue;
          }
        }

        if (found === "") {
          action.data.filled = action.qty;
          newcart.push(action.data);
        } else {
          newcart[found].filled = action.qty;
        }
        return {
          ...state,
          cart: newcart,
        };
      }

    case "ADD_EMPTY":
      var newcart = state.cart;
      if (newcart.length == 0) {
        action.data.empty = 1;
        newcart.push(action.data);
        return {
          ...state,
          cart: newcart,
        };
      } else {
        var found = "";
        for (var i = 0; i < newcart.length; i++) {
          if (newcart[i].id == action.data.id) {
            found = i;
            continue;
          }
        }

        if (found === "") {
          action.data.empty = 1;
          newcart.push(action.data);
        } else if (isNaN(newcart[found].empty) == true) {
          newcart[found].empty = 1;
        } else {
          newcart[found].empty += 1;
        }

        return {
          ...state,
          cart: newcart,
        };
      }

    case "ADD_EMPTY_QTY":
      var newcart = state.cart;
      if (newcart.length == 0) {
        action.data.empty = action.qty;
        newcart.push(action.data);
        return {
          ...state,
          cart: newcart,
        };
      } else {
        var found = "";
        for (var i = 0; i < newcart.length; i++) {
          if (newcart[i].id == action.data.id) {
            found = i;
            continue;
          }
        }

        if (found === "") {
          action.data.empty = action.qty;
          newcart.push(action.data);
        } else {
          newcart[found].empty = action.qty;
        }
        return {
          ...state,
          cart: newcart,
        };
      }

    case "REMOVE_EMPTY":
      var newcart = state.cart;
      if (Array.isArray(newcart) == false) {
        return {
          ...state,
        };
      } else {
        var found = "";
        for (var i = 0; i < newcart.length; i++) {
          if (newcart[i].id == action.data.id) {
            found = i;
            continue;
          }
        }

        if (found === "") {
        } else {
          if (newcart[found].empty <= 1) {
            newcart[found].empty = 0;
          } else {
            newcart[found].empty -= 1;
          }
          if (newcart[found].filled == 0 && newcart[found].empty == 0) {
            newcart.splice(found, 1);
          }
        }
        return {
          ...state,
          cart: newcart,
        };
      }

    case "REMOVE_FILLED":
      var newcart = state.cart;
      if (!Array.isArray(newcart)) {
        return {
          ...state,
        };
      } else {
        var found = "";
        for (var i = 0; i < newcart.length; i++) {
          if (newcart[i].id == action.data.id) {
            found = i;
            continue;
          }
        }

        if (found === "") {
        } else {
          if (newcart[found].filled <= 0) {
            newcart[found].filled = 0;
          } else {
            newcart[found].filled -= 1;
          }
          if (newcart[found].filled == 0 && newcart[found].empty == 0) {
            newcart.splice(found, 1);
          }
        }
        return {
          ...state,
          cart: newcart,
        };
      }
    default:
      return state;
  }
};

export default Login_Reducer;
