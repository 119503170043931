import React from "react";
import { connect } from "react-redux";
import useForceUpdate from "use-force-update";
import { checkcart, checkcart2 } from "./checkcart";
function AddBottle(props) {
  const forceUpdate = useForceUpdate();

  const showplues = () => {
    let data = window.prompt("Plesase Enter Qty");
    if (parseFloat(data) < 0) {
      alert("Invalid Qty");
    } else {
      props.add_filled_qty(props.data, parseInt(data));
      forceUpdate();
    }
  };

  const showmunes = () => {
    let data = window.prompt("Plesase Enter Qty");
    if (parseFloat(data) < 0) {
      alert("Invalid Qty");
    } else {
      props.add_empty_qty(props.data, parseInt(data));
      forceUpdate();
    }
  };

  return (
    <div
      style={{
        flex: 1,
        justifyContent: "center",
        alignItems: "center",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flex: 1,
          width: "100%",
        }}
      >
        <div style={{ flex: 0.5, fontWeight: "bold", color: "gray" }}>
          Filled
        </div>
        <div
          style={{
            display: "flex",
            flex: 1,
            justifyContent: "space-around",
            alignItems: "center",
            marginRight: 15,
          }}
        >
          <div
            onClick={() => {
              props.remove_filled(props.data);
              forceUpdate();
            }}
          >
            <img
              src={require("../../Image/minus.png")}
              style={{ height: "30px", width: "30px" }}
            />
          </div>
          <div onClick={showplues} style={{ fontWeight: "bold", fontSize: 16 }}>
            {checkcart(props.data, props.cart)}
          </div>
          <div
            onClick={() => {
              props.add_filled(props.data);
              forceUpdate();
            }}
          >
            <img
              src={require("../../Image/plus.png")}
              style={{ height: "30px", width: "30px" }}
            />
          </div>
        </div>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flex: 1,
          width: "100%",
          marginTop: "45px",
        }}
      >
        {props.data.allowed_empty == 1 ? (
          <div
            style={{
              display: "flex",
              flex: 1,
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div style={{ flex: 0.5, fontWeight: "bold", color: "gray" }}>
              {props.data.allowed_empty == 1 ? "Empty" : null}
            </div>
            <div
              style={{
                display: "flex",
                flex: 1,
                justifyContent: "space-around",
                alignItems: "center",
                marginRight: 15,
              }}
            >
              <div
                onClick={() => {
                  props.remove_empty(props.data);
                  forceUpdate();
                }}
              >
                <img
                  src={require("../../Image/minus.png")}
                  style={{ height: "30px", width: "30px" }}
                />
              </div>
              <div
                onClick={showmunes}
                style={{ fontWeight: "bold", fontSize: 16 }}
              >
                {checkcart2(props.data, props.cart)}
              </div>
              <div
                onClick={() => {
                  props.add_empty(props.data);
                  forceUpdate();
                }}
              >
                <img
                  src={require("../../Image/plus.png")}
                  style={{ height: "30px", width: "30px" }}
                />
              </div>
            </div>
          </div>
        ) : (
          <div style={{ flex: 1 }} />
        )}
      </div>
    </div>
  );
}

const MapStateToProps = (state) => {
  console.log(state.cart, "jkljkl");
  return {
    cart: state.cart,
  };
};

const MapDispatchToProps = (dispatch) => {
  return {
    add_filled: (id) => {
      dispatch({ type: "ADD_FILLED", data: id });
    },
    add_filled_qty: (id, qty) => {
      dispatch({ type: "ADD_FILLED_QTY", data: id, qty: qty });
    },
    remove_filled: (id) => {
      dispatch({ type: "REMOVE_FILLED", data: id });
    },
    add_empty: (id) => {
      dispatch({ type: "ADD_EMPTY", data: id });
    },
    add_empty_qty: (id, qty) => {
      dispatch({ type: "ADD_EMPTY_QTY", data: id, qty: qty });
    },
    remove_empty: (id) => {
      dispatch({ type: "REMOVE_EMPTY", data: id });
    },
  };
};

export default connect(MapStateToProps, MapDispatchToProps)(AddBottle);
