import React, { useEffect } from "react";

export default function SummaryCard(props) {
  return (
    <div style={{ padding: "10px" }}>
      {props.summarydata.map((i, t) => {
        return (
          <div
            style={{
              height: "55px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div
              style={{
                flex: 1,
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
                fontSize: 12,
              }}
            >
              <img src={i.image} style={{ height: 30, width: 30 }} />
              {i.product_name}
            </div>
            <div style={{ paddingRight: "10px" }}>
              {i.filled} | {i.empty}
            </div>
          </div>
        );
      })}
    </div>
  );
}
