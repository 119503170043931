import React, { useEffect, useState } from "react";
import "./Rate.css";
import { InputText } from "primereact/inputtext";
import { Main_Color } from "../../../Theme/Theme";
import { AiFillCheckCircle, AiFillCloseCircle } from "react-icons/ai";
import Backend from "../../../Backend/Backend";
import ReactLoading from "react-loading";
import { useAlert } from "react-alert";
import { Checkbox } from "primereact/checkbox";
import RateCard from "./RateCard";
const backend = new Backend();
const Rate = (props) => {
  const [token, setToken] = useState(localStorage.getItem("token"));
  const [adminid, setAdminid] = useState(localStorage.getItem("adminid"));
  const [alert_type, setAlert_type] = useState("");
  const [products, setProducts] = useState([]);
  const [new_product, setNew_Product] = useState([]);
  const [loading, setLoading] = useState(false);
  const alert = useAlert();

  useEffect(() => {
    load_customer_single();
  }, []);

  const load_customer_single = () => {
    setLoading(true);
    let data = {
      token: token,
      admin_id: adminid,
      customer_id: props.data.customer_id,
    };

    backend.load_customer_single(data).then((r) => {
      setLoading(false);
      if (r.error == "False") {
        setProducts(r.data.products);
      } else {
        alert.show(r.message);
      }
    });
  };

  const edit_customer_rate = () => {
    let data = {
      token: token,
      admin_id: adminid,
      mobile: props.data.mobile,
      customer_id: props.data.customer_id,
      products: new_product,
    };
    setLoading(true);
    backend.edit_customer_products(data).then((r2) => {
      setLoading(false);
      if (r2.error === "False") {
        props.close();
      } else {
        alert.show(r2.message);
      }
    });
  };

  const edit_rate = (a, b) => {
    var newarry = products;
    products.map((i, t) => {
      if (i.id == b) {
        newarry[t].rate = a;
      }
    });

    setNew_Product(newarry);
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flex: 1,
        height: "100vh",
      }}
    >
      <div
        style={{
          height: "80%",
          width: "60%",
          backgroundColor: "#f2f2f2",
          borderRadius: 10,
          display: "flex",
          alignSelf: "center",
          flexDirection: "column",
        }}
      >
        <div
          style={{
            padding: 20,
            justifyContent: "space-between",
            display: "flex",
            backgroundColor: Main_Color,
            borderTopLeftRadius: 10,
            borderTopRightRadius: 10,
            color: "#ffffff",
            alignItems: "center",
          }}
        >
          <div style={{ fontSize: 22 }}>Edit Rate</div>
          <div style={{ display: "flex" }}>
            {loading ? (
              <div style={{ marginRight: "20px" }}>
                <ReactLoading
                  type="spin"
                  color="#ffffff"
                  height={"30px"}
                  width={"30px"}
                />
              </div>
            ) : (
              <div
                onClick={() => edit_customer_rate()}
                style={{ marginRight: "20px" }}
              >
                <AiFillCheckCircle
                  style={{
                    height: "40px",
                    width: "40px",
                  }}
                />
              </div>
            )}

            <div onClick={() => props.close("a")}>
              <AiFillCloseCircle style={{ height: "40px", width: "40px" }} />
            </div>
          </div>
        </div>

        <div style={{ padding: 10, overflow: "scroll", height: "100%" }}>
          {products.length > 0 ? (
            products.map((i) => {
              return (
                <RateCard data={i} edit_rate={(a, b) => edit_rate(a, b)} />
              );
            })
          ) : (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100%",
              }}
            >
              No Customer Products Found
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Rate;
