import React, { useEffect, useState } from "react";
import "./Stock.css";
import { InputText } from "primereact/inputtext";
import { Main_Color } from "../../../Theme/Theme";
import { AiFillCheckCircle, AiFillCloseCircle } from "react-icons/ai";
import Backend from "../../../Backend/Backend";
import ReactLoading from "react-loading";
import { useAlert } from "react-alert";
const backend = new Backend();
const Stock = (props) => {
  const [token, setToken] = useState(localStorage.getItem("token"));
  const [adminid, setAdminid] = useState(localStorage.getItem("adminid"));
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(false);
  const alert = useAlert();

  useEffect(() => {
    load_customer_single();
  }, []);

  const load_customer_single = () => {
    setLoading(true);
    let data = {
      token: token,
      admin_id: adminid,
      customer_id: props.data.customer_id,
    };

    backend.load_customer_single(data).then((r) => {
      setLoading(false);
      if (r.error == "False") {
        setProducts(r.data.products);
      } else {
        alert.show(r.message);
      }
    });
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flex: 1,
        height: "100vh",
      }}
    >
      <div
        style={{
          height: "80%",
          width: "60%",
          backgroundColor: "#f2f2f2",
          borderRadius: 10,
          display: "flex",
          alignSelf: "center",
          flexDirection: "column",
        }}
      >
        <div
          style={{
            padding: 20,
            justifyContent: "space-between",
            display: "flex",
            backgroundColor: Main_Color,
            borderTopLeftRadius: 10,
            borderTopRightRadius: 10,
            color: "#ffffff",
            alignItems: "center",
          }}
        >
          <div style={{ fontSize: 22 }}>Customer Stock</div>
          <div style={{ display: "flex" }}>
            <div onClick={() => props.close("a")}>
              <AiFillCloseCircle style={{ height: "40px", width: "40px" }} />
            </div>
          </div>
        </div>

        <div style={{ padding: 10, overflow: "scroll", height: "100%" }}>
          {loading ? (
            <div
              style={{
                height: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <ReactLoading
                type={"spin"}
                color={Main_Color}
                height={45}
                width={45}
              />
            </div>
          ) : products.length > 0 ? (
            products.map((i) => {
              return (
                <div
                  style={{
                    height: "55px",
                    backgroundColor: "#ffffff",
                    borderRadius: 10,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    marginTop: "10px",
                  }}
                >
                  <div style={{ paddingLeft: "10px" }}>
                    <img
                      src={i.image}
                      style={{ height: "30px", width: "30px" }}
                    />
                  </div>
                  <div style={{ flex: 1, paddingLeft: "5px" }}>
                    {i.product_name}
                  </div>
                  <div
                    style={{
                      flex: 1,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <div style={{ color: i.stock > 0 ? "green" : "red" }}>
                      {i.stock}
                    </div>
                  </div>
                </div>
              );
            })
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default Stock;
