import React, { useEffect, useState } from "react";
import { InputText } from "primereact/inputtext";
import "primereact/resources/themes/lara-light-indigo/theme.css"; //theme
import "primereact/resources/primereact.min.css"; //core css
import "primeicons/primeicons.css";
import { Main_Color } from "../../Theme/Theme";
import { AiFillCloseCircle } from "react-icons/ai";
import { useAlert } from "react-alert";
import ProductCard from "./ProductCard";
import moment from "moment";
import Backend from "../../Backend/Backend";
import { connect } from "react-redux";

import Select from "react-select";

import "./EntryCard.css";

const backend = new Backend();

function EntryCard(props) {
  const alert = useAlert();
  const [search, setSearch] = useState("");
  const [selcted, setSelcted] = useState("");
  const [customer, setCustomer] = useState([]);
  const [worker_data, setWorker_Data] = useState("");
  const [product, setProduct] = useState([]);
  const [loading, setLoading] = useState(false);
  const [worker_id_tran, setWorker_id_tran] = useState("");
  const [worker_id_pay, setWorker_id_pay] = useState("");
  const [amount, setAmount] = useState("");

  useEffect(() => {
    setCustomer(props.customer);
    setProduct(props.product);
  }, [props.customer, props.product]);

  const search_customer = (a) => {
    if (a.length > 1) {
      let data = [];
      data = props.customer.filter(
        (x) =>
          x.name.toLowerCase().includes(a.toLowerCase()) || x.customer_id == a
      );
      setCustomer(data);
    } else {
      setCustomer(props.customer);
    }
  };

  const send_Transacation = () => {
    if (selcted == "") {
      alert.error("Invalid Customer id");
    } else if (worker_id_tran == "") {
      alert.error("Invalid Worker id");
    } else if (props.cart.length <= 0) {
      alert.error("Cart Is Empty");
    } else {
      setLoading(true);
      let data = {
        admin_id: localStorage.getItem("adminid"),
        worker_id: worker_id_tran,
        customer_id: selcted,
        bottles: props.cart,
        date: moment(new Date()),
        milliseconds: moment(new Date()).unix(),
        cash: 0,
      };
      backend.addTransaction(data).then((r) => {
        props.remove();
        setLoading(false);
        if (r.error == "False") {
          alert.success(r.message);
        } else {
          alert.error(r.message);
        }
      });
    }
  };

  const Add_Payment = () => {
    if (selcted == "") {
      alert.error("Invalid Customer id");
    } else if (amount == "") {
      alert.error("Payment Amount is Blank");
    } else {
      setLoading(true);
      let data = {
        admin_id: localStorage.getItem("adminid"),
        worker_id: worker_id_pay,
        customer_id: selcted,
        pay_amt: amount,
        date: moment(new Date()),
        milliseconds: moment(new Date()).unix(),
      };
      console.log(data);
      backend.addpayment(data).then((r) => {
        console.log(r);
        setLoading(false);
        if (r.error == "False") {
          alert.success(r.message);
          setAmount("");
        } else {
          alert.error(r.message);
        }
      });
    }
  };

  var options = props.worker.map((rec) => {
    return {
      value: rec.id,
      label: rec.name,
    };
  });

  function logChange(val) {
    setWorker_Data(JSON.stringify(val));
    setWorker_id_tran(val.value);
    // setWorker_id_tran("hi");
    console.log(JSON.stringify(val));
  }
  function Change(val) {
    setWorker_id_pay(val.value);
    // setWorker_id_tran("hi");
    console.log(worker_id_pay);
  }
  return (
    <div style={{ display: "flex", flex: 1, padding: 15 }}>
      <div
        style={{
          flex: 0.7,
          backgroundColor: "#ffffff",
          borderRadius: 10,
          marginRight: 10,
        }}
      >
        <div
          style={{
            height: "60px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <InputText
            value={search}
            onChange={(e) => {
              setSearch(e.target.value);
              search_customer(e.target.value);
            }}
            style={{ width: "70%", height: "45px" }}
            placeholder="Search Customer by name and id"
          />
          <div onClick={() => setSelcted("")} style={{ padding: 10 }}>
            <AiFillCloseCircle size={30} color={"red"} />
          </div>
        </div>
        <div style={{ padding: 10, height: "70vh", overflowY: "scroll" }}>
          {customer.length > 0 ? (
            customer.map((i) => {
              return (
                <div
                  onClick={() => setSelcted(i.customer_id)}
                  key={i.id}
                  style={{
                    height: "45px",
                    backgroundColor:
                      selcted == i.customer_id ? Main_Color : "#f2f2f2",
                    marginBottom: 5,
                    borderRadius: 10,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    borderStyle: "solid",
                    borderWidth: 2,
                    borderColor: i.status == 0 ? "red" : "#f2f2f2",
                    color: selcted == i.customer_id ? "#ffffff" : "#000000",
                  }}
                >
                  <div style={{ paddingLeft: 10 }}>{i.customer_id}</div>
                  <div style={{ flex: 1, paddingLeft: 10 }}>{i.name}</div>
                </div>
              );
            })
          ) : (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                paddingTop: "30px",
              }}
            >
              No customer found
            </div>
          )}
        </div>
      </div>
      <div
        style={{
          flex: 1,
          height: "80vh",
          backgroundColor: "#ffffff",
          borderRadius: 10,
          marginRight: 10,
        }}
      >
        <div
          style={{
            height: "60px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            borderStyle: "solid",
            borderWidth: 0,
            borderBottomWidth: 1,
            borderBottomColor: "rgba(0,0,0,0.1)",
          }}
        >
          <div style={{ fontSize: 18, flex: 1, paddingLeft: "20px" }}>
            Transaction
          </div>
          <div
            onClick={() => send_Transacation()}
            style={{
              fontSize: 18,
              padding: 10,
              marginRight: "20px",
              backgroundColor: Main_Color,
              borderRadius: 10,
              color: "#ffffff",
            }}
          >
            Submit
          </div>
        </div>

        <div
          style={{
            height: "60px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Select
            name="form-field-name"
            // value={worker_data}
            className="list"
            options={options}
            inputValue={worker_data.label}
            onChange={logChange}
          />
          <div onClick={() => setWorker_id_tran("")} style={{ padding: 10 }}>
            <AiFillCloseCircle size={30} color={"red"} />
          </div>
        </div>

        <div
          style={{
            padding: 10,
            overflowY: "scroll",
            height: "60vh",
          }}
        >
          {product.map((i) => {
            return <ProductCard data={i} />;
          })}
        </div>
      </div>
      <div
        style={{
          flex: 1,
          height: "80vh",
          backgroundColor: "#ffffff",
          borderRadius: 10,
          marginRight: 10,
        }}
      >
        <div
          style={{
            height: "60px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            borderStyle: "solid",
            borderWidth: 0,
            borderBottomWidth: 1,
            borderBottomColor: "rgba(0,0,0,0.1)",
          }}
        >
          <div style={{ fontSize: 18 }}>Payment</div>
        </div>

        <div
          style={{
            height: "60px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Select
            name="form-field-name"
            // value="one"
            options={options}
            className="list"
            onChange={Change}
            inputValue={worker_data.label}
          />
          <div onClick={() => setWorker_id_pay("")} style={{ padding: 10 }}>
            <AiFillCloseCircle size={30} color={"red"} />
          </div>
        </div>
        <div
          style={{
            padding: 10,
            overflowY: "scroll",
            height: "60vh",
          }}
        >
          <div
            style={{
              padding: "10px",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
            }}
          >
            <InputText
              value={amount}
              onChange={(e) => {
                setAmount(e.target.value);
              }}
              style={{ width: "70%", height: "45px", marginTop: "30px" }}
              placeholder="Enter Amount"
            />

            <div
              onClick={() => Add_Payment()}
              style={{
                paddingLeft: 30,
                paddingRight: 30,
                padding: 12,
                backgroundColor: Main_Color,
                marginTop: "45px",
                borderRadius: 10,
                color: "#ffffff",
              }}
            >
              Submit
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

const MapStateToProps = (state) => {
  return {
    cart: state.cart,
  };
};

const MapDispatchToProps = (dispatch) => {
  return {
    remove: (id) => {
      dispatch({ type: "REMOVE_CART", data: id });
    },
  };
};

export default connect(MapStateToProps, MapDispatchToProps)(EntryCard);
