import React, { Component } from "react";
import { BsPlusSquareFill } from "react-icons/bs";
import { FcManager } from "react-icons/fc";
import { AiFillCheckCircle } from "react-icons/ai";
import { Main_Color } from "../../Theme/Theme";
import Backend from "../../Backend/Backend";
import { useAlert } from "react-alert";
import RouteCard from "./RouteCard";
import RouteSequencerIn from "./RouteSequencerIn";

const backend = new Backend();
const Route = () => {
  const [data, setData] = React.useState([]);
  const [loading, setLoading] = React.useState(true);
  const [token, setToken] = React.useState(localStorage.getItem("token"));
  const [adminid, setAdminid] = React.useState(localStorage.getItem("adminid"));
  const [route, setRoute] = React.useState("");
  const [newroute, setNewRoute] = React.useState("");

  const [dataRoute, setRouteData] = React.useState([]);
  const alert = useAlert();

  React.useEffect(() => {
    loadconent();
  }, []);

  const loadconent = () => {
    setLoading(true);
    var data = {
      token: token,
      admin_id: adminid,
    };
    backend.load_route_sequence(data).then((r) => {
      setLoading(false);
      if (r.error === "False") {
        setData(r.data);
      } else {
        alert.error("You just broke something!");
      }
    });
  };

  const setRoutes = (a) => {
    setRoute(a);
    var data = {
      token: token,
      admin_id: adminid,
      route: a,
    };
    backend.load_customer_sequence(data).then((r) => {
      if (r.error === "False") {
        setRouteData(r.data);
      } else {
        alert.error("You just broke something!");
      }
    });
  };

  const UpdateRoute = (a) => {
    setNewRoute(a);
  };

  const onUpdateRoute = () => {
    var data = {
      token: token,
      admin_id: adminid,
      newdata: newroute,
    };
    backend.new_sequencer(data).then((r) => {
      if (r.error === "False") {
        alert.error(r.message);
        loadconent();
      } else {
        alert.error(r.message);
      }
    });
  };

  return (
    <div style={{ flex: 1, backgroundColor: "#f2f2f2" }}>
      <div
        style={{
          display: "flex",
          height: "100px",
          backgroundColor: "#ffffff",
          flex: 1,
          alignItems: "center",
        }}
      >
        <div
          style={{
            display: "flex",
            padding: 20,
            color: Main_Color,
            fontSize: 22,
            paddingLeft: 40,
            flex: 1,
            alignItems: "center",
            justifyContent: "flex-start",
          }}
        >
          <FcManager style={{ fontSize: 55 }} />
          <div style={{ paddingLeft: 20, fontSize: 22, flex: 1 }}>Route</div>
          <div style={{ paddingRight: "50px", fontSize: 25 }}>
            Route - {route}
          </div>
          {route ? (
            <div
              onClick={() => onUpdateRoute()}
              style={{ paddingRight: "50px", fontSize: 25 }}
            >
              <AiFillCheckCircle style={{ fontSize: 40 }} />
            </div>
          ) : null}
        </div>
      </div>

      <div style={{ padding: 10 }}>
        <div
          style={{
            flex: 1,
            height: "82vh",
            borderRadius: 10,
            overflowY: "scroll",
          }}
        >
          {loading ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flex: 1,
                backgroundColor: "#ffffff",
                height: "100%",
              }}
            >
              <img src={require("../../Image/loading.gif")} />
            </div>
          ) : (
            <div style={{ display: "flex", flex: 1, height: "100%" }}>
              <div style={{ flex: 1, height: "82vh", overflow: "scroll" }}>
                {data.length > 0 ? (
                  data.map((i, t) => {
                    return (
                      <RouteCard
                        item={i}
                        index={t}
                        setRoutes={(a) => setRoutes(a)}
                      />
                    );
                  })
                ) : (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      height: "98%",
                      backgroundColor: "#ffffff",
                      marginTop: "10px",
                      borderRadius: "10px",
                    }}
                  >
                    No Route List Found
                  </div>
                )}
              </div>
              <div style={{ flex: 4 }}>
                {route == "" ? (
                  <div style={{ padding: 10 }}>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "80vh",
                        backgroundColor: "#ffffff",
                        borderRadius: 10,
                      }}
                    >
                      Select Route To Continue
                    </div>
                  </div>
                ) : dataRoute.length > 0 ? (
                  <RouteSequencerIn
                    dataRoute={dataRoute}
                    key={route}
                    UpdateRoute={(a) => UpdateRoute(a)}
                  />
                ) : (
                  <div
                    style={{
                      padding: 10,
                    }}
                  >
                    <div
                      style={{
                        height: "80vh",
                        backgroundColor: "#ffffff",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        borderRadius: 10,
                      }}
                    >
                      No Customer In This Route
                    </div>
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Route;
