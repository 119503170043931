import React, { useState } from "react";
import { InputText } from "primereact/inputtext";
import { Main_Color } from "./../../../Theme/Theme";
import { AiFillCloseCircle } from "react-icons/ai";
import Backend from "../../../Backend/Backend";
import ReactLoading from "react-loading";
import { useAlert } from "react-alert";
const backend = new Backend();

const Add = (props) => {
  const [name, setName] = useState("");
  const [mobile, setMobile] = useState("");
  const [password, setPassword] = useState("");
  const [token, setToken] = useState(localStorage.getItem("token"));
  const [adminid, setAdminid] = useState(localStorage.getItem("adminid"));
  const [alert_type, setAlert_type] = useState(0);
  const [loading, setLoading] = useState(false);

  const alert = useAlert();

  const _add_worker = () => {
    if (name === "") {
      setAlert_type(1);
      setTimeout(() => {
        setAlert_type(0);
      }, 5000);
    } else if (mobile.length < 10) {
      setAlert_type(2);
      setTimeout(() => {
        setAlert_type(0);
      }, 5000);
    } else if (password.length < 3) {
      setAlert_type(3);
      setTimeout(() => {
        setAlert_type(0);
      }, 5000);
    } else {
      let data = {
        name: name,
        mobile: mobile,
        password: password,
        token: token,
        admin_id: adminid,
      };
      setLoading(true);
      backend.add_worker(data).then((r2) => {
        setLoading(false);
        if (r2.error === "False") {
          props.close();
        } else {
          alert.show(r2.message);
        }
      });
    }
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flex: 1,
        height: "100vh",
      }}
    >
      <div
        style={{
          height: "70%",
          width: "55%",
          backgroundColor: "#f2f2f2",
          borderRadius: 10,
          display: "flex",
          alignSelf: "center",
          flexDirection: "column",
        }}
      >
        <div
          style={{
            padding: 20,
            justifyContent: "space-between",
            display: "flex",
            backgroundColor: Main_Color,
            borderTopLeftRadius: 10,
            borderTopRightRadius: 10,
            color: "#ffffff",
            alignItems: "center",
          }}
        >
          <div style={{ fontSize: 22 }}>Create Delivery Boy</div>
          <div onClick={() => props.close("a")}>
            <AiFillCloseCircle style={{ height: "40px", width: "40px" }} />
          </div>
        </div>
        <div
          style={{
            padding: 10,
            justifyContent: "center",
            display: "flex",
            borderTopLeftRadius: 10,
            borderTopRightRadius: 10,
            color: "#ffffff",
          }}
        >
          <span style={{ display: "flex", flexDirection: "column" }}>
            <InputText
              placeholder="Worker Name "
              value={name}
              style={{
                width: "500px",
                marginTop: 10,
                borderWidth: alert_type === 1 ? 2 : 1,
                borderColor: alert_type === 1 ? "red" : "",
              }}
              onChange={(e) => setName(e.target.value)}
            />
            {alert_type === 1 ? (
              <div style={{ marginTop: 5, paddingLeft: 10, color: "red" }}>
                {"Invalid Worker Name "}
              </div>
            ) : null}
          </span>
        </div>
        <div
          style={{
            padding: 10,
            justifyContent: "center",
            display: "flex",
            borderTopLeftRadius: 10,
            borderTopRightRadius: 10,
            color: "#ffffff",
          }}
        >
          <span style={{ display: "flex", flexDirection: "column" }}>
            <InputText
              placeholder="Mobile Number"
              maxLength={10}
              min="0"
              max="10"
              value={mobile}
              pattern="[0-9]{2}"
              style={{
                width: "500px",
                marginTop: 10,
                borderWidth: alert_type === 2 ? 2 : 1,
                borderColor: alert_type === 2 ? "red" : "",
              }}
              onChange={(e) => setMobile(e.target.value)}
            />
            {alert_type === 2 ? (
              <div style={{ marginTop: 5, paddingLeft: 10, color: "red" }}>
                {"Invalid Mobile Number "}
              </div>
            ) : null}
          </span>
        </div>
        <div
          style={{
            padding: 10,
            justifyContent: "center",
            display: "flex",
            borderTopLeftRadius: 10,
            borderTopRightRadius: 10,
            color: "#ffffff",
          }}
        >
          <span style={{ display: "flex", flexDirection: "column" }}>
            <InputText
              placeholder="Password"
              type="password"
              value={password}
              style={{
                width: "500px",
                marginTop: 10,
                borderWidth: alert_type === 3 ? 2 : 1,
                borderColor: alert_type === 3 ? "red" : "",
              }}
              onChange={(e) => setPassword(e.target.value)}
            />
            {alert_type === 3 ? (
              <div style={{ marginTop: 5, paddingLeft: 10, color: "red" }}>
                {"Invalid  Password "}
              </div>
            ) : null}
          </span>
        </div>
        {loading === true ? (
          <div
            style={{
              alignSelf: "center",
              marginTop: 40,
              height: "50px",
              width: "200px",
              backgroundColor: Main_Color,
              borderRadius: 10,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              color: "#ffffff",
              fontSize: 18,
            }}
          >
            <ReactLoading type={"bars"} color={"#fff"} height={45} width={45} />
          </div>
        ) : (
          <div
            onClick={() => _add_worker()}
            style={{
              alignSelf: "center",
              marginTop: 40,
              height: "50px",
              width: "200px",
              backgroundColor: Main_Color,
              borderRadius: 10,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              color: "#ffffff",
              fontSize: 18,
            }}
          >
            Submit
          </div>
        )}
      </div>
    </div>
  );
};

export default Add;
