import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Main_Color } from "../../Theme/Theme";
import { MdLibraryAdd } from "react-icons/md";
import { ImUsers } from "react-icons/im";
import { IoIdCard } from "react-icons/io5";
import { FaProductHunt } from "react-icons/fa";
import { BsFillFileEarmarkBarGraphFill } from "react-icons/bs";
import "./SideBar.css";
function SideBar() {
  const [selected, setSelcted] = useState(0);

  return (
    <div style={{ flexDirection: "column", display: "flex" }}>
      <Link
        to={"/home"}
        style={{
          textDecoration: "none",
          color:
            window.location.pathname === "/home" ||
            window.location.pathname === "/"
              ? Main_Color
              : "#000",
        }}
      >
        <div
          onClick={() => setSelcted(1)}
          style={{
            height: "70px",
            width: "100%",
            justifyContent: "center",
            alignItems: "center",
            display: "flex",
            borderStyle: "solid",
            borderRightWidth: 0,
            borderTopWidth: 0,
            borderBottomWidth: 0,
            borderLeftWidth:
              window.location.pathname === "/home" ||
              window.location.pathname === "/"
                ? 5
                : 0,
            borderColor: Main_Color,
            backgroundColor:
              window.location.pathname === "/home" ||
              window.location.pathname === "/"
                ? "#fff"
                : null,
          }}
        >
          {window.location.pathname === "/home" ||
          window.location.pathname === "/" ? (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <img
                src={require("../../Image/SideBar/Home.png")}
                style={{ height: 30, width: 30 }}
              />
            </div>
          ) : (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <img
                src={require("../../Image/SideBar/Home.png")}
                style={{ height: 30, width: 30 }}
              />
              <div
                style={{
                  fontSize: 12,
                  marginTop: 5,
                  fontWeight: "bold",
                  color: "#585858",
                }}
              >
                Home
              </div>
            </div>
          )}
        </div>
      </Link>
      <Link
        to={"/captain"}
        style={{
          textDecoration: "none",
          color: window.location.pathname === "/captain" ? Main_Color : "#000",
        }}
      >
        <div
          onClick={() => setSelcted(2)}
          style={{
            height: "70px",
            width: "100%",
            justifyContent: "center",
            alignItems: "center",
            display: "flex",
            borderStyle: "solid",
            borderRightWidth: 0,
            borderTopWidth: 0,
            borderBottomWidth: 0,
            borderLeftWidth: window.location.pathname === "/captain" ? 5 : 0,
            borderColor: Main_Color,
            backgroundColor:
              window.location.pathname === "/captain" ? "#fff" : null,
          }}
        >
          {window.location.pathname === "/captain" ? (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <img
                src={require("../../Image/SideBar/Captain.png")}
                style={{ height: 30, width: 30 }}
              />
            </div>
          ) : (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <img
                src={require("../../Image/SideBar/Captain.png")}
                style={{ height: 30, width: 30 }}
              />
              <div
                style={{
                  fontSize: 12,
                  marginTop: 5,
                  fontWeight: "bold",
                  color: "#585858",
                }}
              >
                Worker
              </div>
            </div>
          )}
        </div>
      </Link>
      <Link
        to={"/customer"}
        style={{
          textDecoration: "none",
          color: window.location.pathname === "/customer" ? Main_Color : "#000",
        }}
      >
        <div
          onClick={() => setSelcted(3)}
          style={{
            height: "70px",
            width: "100%",
            justifyContent: "center",
            alignItems: "center",
            display: "flex",
            borderStyle: "solid",
            borderRightWidth: 0,
            borderTopWidth: 0,
            borderBottomWidth: 0,
            borderLeftWidth: window.location.pathname === "/customer" ? 5 : 0,
            borderColor: Main_Color,
            backgroundColor:
              window.location.pathname === "/customer" ? "#fff" : null,
          }}
        >
          {window.location.pathname === "/customer" ? (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <ImUsers style={{ color: Main_Color, fontSize: 25 }} />
            </div>
          ) : (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <ImUsers style={{ color: Main_Color, fontSize: 25 }} />

              <div
                style={{
                  fontSize: 12,
                  marginTop: 5,
                  fontWeight: "bold",
                  color: "#585858",
                }}
              >
                Customers
              </div>
            </div>
          )}
        </div>
      </Link>
      <Link
        to={"/route"}
        style={{
          textDecoration: "none",
          color: window.location.pathname === "/route" ? Main_Color : "#000",
        }}
      >
        <div
          onClick={() => setSelcted(4)}
          style={{
            height: "70px",
            width: "100%",
            justifyContent: "center",
            alignItems: "center",
            display: "flex",
            borderStyle: "solid",
            borderRightWidth: 0,
            borderTopWidth: 0,
            borderBottomWidth: 0,
            borderLeftWidth: window.location.pathname === "/route" ? 5 : 0,
            backgroundColor:
              window.location.pathname === "/route" ? "#fff" : null,
          }}
        >
          {window.location.pathname === "/route" ? (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <IoIdCard style={{ color: Main_Color, fontSize: 25 }} />
            </div>
          ) : (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <IoIdCard style={{ color: Main_Color, fontSize: 25 }} />

              <div
                style={{
                  fontSize: 12,
                  marginTop: 5,
                  fontWeight: "bold",
                  color: "#585858",
                }}
              >
                Route
              </div>
            </div>
          )}
        </div>
      </Link>
      <Link
        to={"/product"}
        style={{
          textDecoration: "none",
          color: window.location.pathname === "/product" ? Main_Color : "#000",
        }}
      >
        <div
          onClick={() => setSelcted(5)}
          style={{
            height: "70px",
            width: "100%",
            justifyContent: "center",
            alignItems: "center",
            display: "flex",
            borderStyle: "solid",
            borderRightWidth: 0,
            borderTopWidth: 0,
            borderBottomWidth: 0,
            borderLeftWidth: window.location.pathname === "/product" ? 5 : 0,
            borderColor: Main_Color,
          }}
        >
          {window.location.pathname === "/product" ? (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <FaProductHunt style={{ color: Main_Color, fontSize: 25 }} />
            </div>
          ) : (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <FaProductHunt style={{ color: Main_Color, fontSize: 25 }} />
              <div
                style={{
                  fontSize: 12,
                  marginTop: 5,
                  fontWeight: "bold",
                  color: "#585858",
                }}
              >
                Product
              </div>
            </div>
          )}
        </div>
      </Link>

      <Link
        to={"/entry"}
        style={{
          textDecoration: "none",
          color: window.location.pathname === "/entry" ? Main_Color : "#000",
        }}
      >
        <div
          onClick={() => setSelcted(6)}
          style={{
            height: "70px",
            width: "100%",
            justifyContent: "center",
            alignItems: "center",
            display: "flex",
            borderStyle: "solid",
            borderRightWidth: 0,
            borderTopWidth: 0,
            borderBottomWidth: 0,
            borderLeftWidth: window.location.pathname === "/entry" ? 5 : 0,
            borderColor: Main_Color,
          }}
        >
          {window.location.pathname === "/entry" ? (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <MdLibraryAdd style={{ color: Main_Color, fontSize: 25 }} />
            </div>
          ) : (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <MdLibraryAdd style={{ color: Main_Color, fontSize: 25 }} />
              <div
                style={{
                  fontSize: 12,
                  marginTop: 5,
                  fontWeight: "bold",
                  color: "#585858",
                }}
              >
                Entry
              </div>
            </div>
          )}
        </div>
      </Link>
      <Link
        to={"/report"}
        style={{
          textDecoration: "none",
          color: window.location.pathname === "/report" ? Main_Color : "#000",
        }}
      >
        <div
          onClick={() => setSelcted(7)}
          style={{
            height: "70px",
            width: "100%",
            justifyContent: "center",
            alignItems: "center",
            display: "flex",
            borderStyle: "solid",
            borderRightWidth: 0,
            borderTopWidth: 0,
            borderBottomWidth: 0,
            borderLeftWidth: window.location.pathname === "/report" ? 5 : 0,
            borderColor: Main_Color,
          }}
        >
          {window.location.pathname === "/report" ? (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <img
                src={require("../../Image/SideBar/RawItem.png")}
                style={{ height: 30, width: 30 }}
              />
            </div>
          ) : (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <img
                src={require("../../Image/SideBar/RawItem.png")}
                style={{ height: 30, width: 30 }}
              />
              <div
                style={{
                  fontSize: 12,
                  marginTop: 5,
                  fontWeight: "bold",
                  color: "#585858",
                }}
              >
                Report
              </div>
            </div>
          )}
        </div>
      </Link>

      <Link
        to={"/bill"}
        style={{
          textDecoration: "none",
          color: window.location.pathname === "/bill" ? Main_Color : "#000",
        }}
      >
        <div
          onClick={() => setSelcted(8)}
          style={{
            height: "70px",
            width: "100%",
            justifyContent: "center",
            alignItems: "center",
            display: "flex",
            borderStyle: "solid",
            borderRightWidth: 0,
            borderTopWidth: 0,
            borderBottomWidth: 0,
            borderLeftWidth: window.location.pathname === "/bill" ? 5 : 0,
            borderColor: Main_Color,
            backgroundColor:
              window.location.pathname === "/bill" ? "#fff" : null,
          }}
        >
          {window.location.pathname === "/bill" ? (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <BsFillFileEarmarkBarGraphFill
                style={{ color: Main_Color, fontSize: 27 }}
              />
            </div>
          ) : (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <BsFillFileEarmarkBarGraphFill
                style={{ color: Main_Color, fontSize: 27 }}
              />
              <div
                style={{
                  fontSize: 12,
                  marginTop: 5,
                  fontWeight: "bold",
                  color: "#585858",
                }}
              >
                Bill
              </div>
            </div>
          )}
        </div>
      </Link>

      <Link
        to={"/plan"}
        style={{
          textDecoration: "none",
          color: window.location.pathname === "/plan" ? Main_Color : "#000",
        }}
      >
        <div
          onClick={() => setSelcted(9)}
          style={{
            height: "70px",
            width: "100%",
            justifyContent: "center",
            alignItems: "center",
            display: "flex",
            borderStyle: "solid",
            borderRightWidth: 0,
            borderTopWidth: 0,
            borderBottomWidth: 0,
            borderLeftWidth: window.location.pathname === "/plan" ? 5 : 0,
            borderColor: Main_Color,
            backgroundColor:
              window.location.pathname === "/plan" ? "#fff" : null,
          }}
        >
          {window.location.pathname === "/plan" ? (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <img
                src={require("../../Image/SideBar/Report.png")}
                style={{ height: 30, width: 30 }}
              />
            </div>
          ) : (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <img
                src={require("../../Image/SideBar/Report.png")}
                style={{ height: 30, width: 30 }}
              />
              <div
                style={{
                  fontSize: 12,
                  marginTop: 5,
                  fontWeight: "bold",
                  color: "#585858",
                }}
              >
                Plans
              </div>
            </div>
          )}
        </div>
      </Link>
    </div>
  );
}

export default SideBar;
