import React, { useEffect, useState } from "react";
import { Main_Color } from "../../Theme/Theme";
import { FcManager } from "react-icons/fc";
import EntryCard from "./EntryCard";
import Backend from "../../Backend/Backend";
const backend = new Backend();

export default function Entry() {
  const [customer, setCustomer] = useState([]);
  const [worker, setWorker] = useState([]);
  const [product, setProduct] = useState([]);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    load_customer();
    load_product();
    loadconent();
  }, []);

  const load_customer = () => {
    setLoading(true);
    var data = {
      token: localStorage.getItem("token"),
      admin_id: localStorage.getItem("adminid"),
    };
    backend.load_customer(data).then((r) => {
      if (r.error === "False") {
        setCustomer(r.data);
        setLoading(false);
      } else {
        alert.error("You just broke something!");
        setLoading(false);
      }
    });
  };

  const load_product = () => {
    setLoading(true);
    var data = {
      token: localStorage.getItem("token"),
      admin_id: localStorage.getItem("adminid"),
    };
    backend.load_product(data).then((r) => {
      if (r.error === "False") {
        setProduct(r.data);
        setLoading(false);
      } else {
        alert.error("You just broke something!");
        setLoading(false);
      }
    });
  };

  const loadconent = () => {
    setLoading(true);
    var data = {
      token: localStorage.getItem("token"),
      admin_id: localStorage.getItem("adminid"),
    };
    backend.load_worker(data).then((r) => {
      console.log(r, "jhlkjlkjlk");
      if (r.error === "False") {
        setWorker(r.data);
        setLoading(false);
      } else {
        alert.error(r.message);
        setLoading(false);
      }
    });
  };

  return (
    <div style={{ flex: 1, backgroundColor: "#f2f2f2" }}>
      <div
        style={{
          display: "flex",
          height: "100px",
          backgroundColor: "#ffffff",
          flex: 1,
          alignItems: "center",
        }}
      >
        <div
          style={{
            display: "flex",
            padding: 20,
            color: Main_Color,
            fontSize: 22,
            paddingLeft: 40,
            flex: 1,
            alignItems: "center",
            justifyContent: "flex-start",
          }}
        >
          <FcManager style={{ fontSize: 55 }} />
          <div style={{ paddingLeft: 20, fontSize: 22 }}>Entry</div>
        </div>
      </div>

      {loading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flex: 1,
            backgroundColor: "#ffffff",
            height: "87%",
          }}
        >
          <img src={require("../../Image/loading.gif")} />
        </div>
      ) : (
        <EntryCard worker={worker} customer={customer} product={product} />
      )}
    </div>
  );
}
