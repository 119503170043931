import React from "react";
import { FiEdit } from "react-icons/fi";
const RouteCard = (props) => {
  const i = props.item;
  return (
    <div key={props.index}>
      <div
        onClick={() => props.setRoutes(i.route)}
        style={{
          display: "flex",
          flex: 1,
          backgroundColor: "#ffffff",
          justifyContent: "space-between",
          alignItems: "center",
          borderRadius: 10,
          padding: 15,
          marginTop: 10,
        }}
      >
        <div style={{ paddingLeft: 20, display: "flex", alignItems: "center" }}>
          <div style={{ paddingRight: 20, color: "#585858" }}>
            {props.index + 1}
          </div>
          <div>
            <div style={{ color: "#000000", fontSize: 18 }}>{i.route}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RouteCard;
