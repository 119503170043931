import React, { Component } from "react";
import TopCard from "./TopCard/TopCard";
import { BiRegistered } from "react-icons/bi";
import { FaUserSlash, FaUsersSlash } from "react-icons/fa";
import { GiCaptainHatProfile } from "react-icons/gi";
import { FcExpired, FcLowBattery } from "react-icons/fc";
import { FiUsers } from "react-icons/fi";
import { BsJournalBookmark } from "react-icons/bs";
import Backend from "../../Backend/Backend";
import moment from "moment";
import "./Home.css";
const backend = new Backend();
export default class Home extends Component {
  constructor() {
    super();
    this.state = {
      loading: true,
      token: localStorage.getItem("token"),
      adminid: localStorage.getItem("adminid"),
      admin_data: JSON.parse(localStorage.getItem("admindata")),
      worker: "",
      customer: "",
      transaction: "",
      payment: "",
      low_bal: "",
      expired: "",
      data: "",
      days: "",
    };
  }

  componentDidMount() {
    this.loadconent();
  }

  logoutnew() {
    localStorage.clear();
    window.location = "/Login";
  }

  loadconent() {
    this.setState({ loading: true });

    let data = {
      token: this.state.token,
      admin_id: this.state.admin_data.id,
    };
    backend.dashboard(data).then((r) => {
      if (r.error == "False") {
        this.setState({
          worker: r.worker,
          customer: r.customer,
          transaction: r.transaction,
          payment: r.payment,
          low_bal: r.low_bal,
          expired: r.expired,
          data: r.data,
          days: r.days,
          loading: false,
        });
      } else {
        this.setState({ loading: false });
      }
    });
  }

  render() {
    return this.state.loading === true ? (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flex: 1,
        }}
      >
        <img src={require("../../Image/loading.gif")} />
      </div>
    ) : (
      <div className="mainview">
        <div>
          <TopCard data={this.state.admin_data} />
        </div>

        <div style={{ display: "flex", justifyContent: "space-around" }}>
          <div className="card">
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <div>
                <GiCaptainHatProfile className="icon" />
              </div>
              <div className="cardname">{this.state.worker}</div>
              <div className="cardfooter">Total Workers</div>
            </div>
          </div>
          <div className="card">
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <div>
                <FiUsers className="icon" />
              </div>
              <div className="cardname">{this.state.customer}</div>
              <div className="cardfooter">Total Customers</div>
            </div>
          </div>
          <div className="card">
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <div>
                <BsJournalBookmark className="icon" />
              </div>
              <div className="cardname">{this.state.transaction}</div>
              <div className="cardfooter">Today's Transactions</div>
            </div>
          </div>
          <div className="card">
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <div>
                <BiRegistered className="icon" />
              </div>
              <div className="cardname">{this.state.payment}</div>
              <div className="cardfooter">Today's Recharges</div>
            </div>
          </div>
        </div>

        <div
          style={{
            display: "flex",
            justifyContent: "space-around",
            marginTop: "30PX",
          }}
        >
          <div className="card">
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <div>
                <FaUsersSlash className="icon" />
              </div>
              <div className="cardname">{this.state.data.limit_customer}</div>
              <div className="cardfooter">Limit Customer</div>
            </div>
          </div>
          <div className="card">
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <div>
                <FaUserSlash className="icon" />
              </div>
              <div className="cardname">{this.state.data.limit_worker}</div>
              <div className="cardfooter">Limit Worker</div>
            </div>
          </div>
          <div className="card">
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <div>
                <FcLowBattery className="icon" />
              </div>
              <div className="cardname">{this.state.low_bal}</div>
              <div className="cardfooter">Low Balance</div>
            </div>
          </div>
          <div className="card">
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <div>
                <FcExpired className="icon" />
              </div>
              <div className="cardname">
                {moment(this.state.data.expiry_date).format("DD-MM-YYYY")}
              </div>
              <div className="cardfooter">Service Validity</div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
