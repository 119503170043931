import React from "react";
import { Main_Color } from "../../Theme/Theme";
import "./Plan.css";
const PlansCard = (props) => {
  return (
    <div
      className="card"
      style={{
        height: "350px",
        width: "300px",
        backgroundColor: "#ffffff",
        borderRadius: 20,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div
        style={{
          flex: 1,
          justifyContent: "center",
          alignItems: "center",
          display: "flex",
          fontSize: 35,
        }}
      >
        {props.title}
      </div>

      <div
        style={{
          flex: 1,
          justifyContent: "center",
          alignItems: "center",
          display: "flex",
        }}
      >
        <div
          style={{
            fontSize: 16,
            color: "gray",
            width: "70%",
            textAlign: "center",
          }}
        >
          {props.msg}
        </div>
      </div>

      <div
        style={{
          flex: 1,
          justifyContent: "center",
          alignItems: "center",
          display: "flex",
          fontSize: 35,
        }}
      >
        <div style={{ fontSize: 55, marginLeft: "5px" }}>{props.month}</div>
        <div
          style={{
            fontSize: 20,
            color: "gray",
            display: "flex",
            alignItems: "flex-end",
            height: "40px",
            marginLeft: "5px",
          }}
        >
          {"Days"}
        </div>
      </div>
      <div
        style={{
          flex: 1,
          justifyContent: "center",
          alignItems: "center",
          display: "flex",
        }}
      >
        <div
          style={{
            height: "55px",
            width: "200px",
            backgroundColor: Main_Color,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            color: "#ffffff",
            fontSize: 22,
            borderRadius: 7,
          }}
        >
          {props.price}
        </div>
      </div>
    </div>
  );
};

export default PlansCard;
