const api_link = "https://api.shreeramtech.com/admin_api_new.php";
const access_key = "123";

const api_link_worker = "https://api.shreeramtech.com/worker_api_new.php";
const access_key_worker =
  "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJPbmxpbmUgSldUIEJ1aWxkZXIiLCJpYXQiOjE1NTg0NTI4NjcsImV4cCI6MTU4OTk4ODg2NywiYXVkIjoid3d3LmV4YW1wbGUuY29tIiwic3ViIjoianJvY2tldEBleGFtcGxlLmNvbSIsIkdpdmVuTmFtZSI6IkpvaG5ueSIsIlN1cm5hbWUiOiJSb2NrZXQiLCJFbWFpbCI6Impyb2NrZXRAZXhhbXBsZS5jb20iLCJSb2xlIjpbIk1hbmFnZXIiLCJQcm9qZWN0IEFkbWluaXN0cmF0b3IiXX0.wCw15_auQ5nnEXxdKv40DDHwz_uLebvhwdjAAtluUXw";

export default class Bakend {
  Login(data) {
    return fetch(api_link, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        admin_login: "1",
        access_key: access_key,
        mobile: data.mobile,
        password: data.password,
      }),
    }).then((response) => response.json());
  }

  dashboard(data) {
    return fetch(api_link, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        dashboard: "1",
        admin_id: data.admin_id,
        access_key: access_key,
        authtoken: data.token,
      }),
    }).then((response) => response.json());
  }

  load_report(data) {
    return fetch(api_link, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        load_report: "1",
        admin_id: data.admin_id,
        access_key: access_key,
        authtoken: data.token,
        datefrom: data.datefrom,
        dateto: data.dateto,
      }),
    }).then((response) => response.json());
  }

  get_card_all(data) {
    return fetch(api_link, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        get_card_all: "1",
        admin_id: data.admin_id,
        access_key: access_key,
        data: data,
        authtoken: data.token,
      }),
    }).then((response) => response.json());
  }

  get_bill_all(data) {
    return fetch(api_link, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        get_bill_all: "1",
        admin_id: data.admin_id,
        access_key: access_key,
        data: data,
        authtoken: data.token,
      }),
    }).then((response) => response.json());
  }

  get_bill(data) {
    return fetch(api_link, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        get_bill: "1",
        admin_id: data.admin_id,
        access_key: access_key,
        data: data,
        authtoken: data.token,
      }),
    }).then((response) => response.json());
  }

  get_card(data) {
    return fetch(api_link, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        get_card: "1",
        admin_id: data.admin_id,
        access_key: access_key,
        data: data,
        authtoken: data.token,
      }),
    }).then((response) => response.json());
  }

  load_customer(data) {
    return fetch(api_link, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        load_customer: "1",
        admin_id: data.admin_id,
        access_key: access_key,
        data: data,
        authtoken: data.token,
        frompc: "1",
      }),
    }).then((response) => response.json());
  }

  load_route(data) {
    return fetch(api_link, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        load_route: "1",
        admin_id: data.admin_id,
        access_key: access_key,
        authtoken: data.token,
      }),
    }).then((response) => response.json());
  }
  load_image(data) {
    return fetch(api_link, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: JSON.stringify({
        load_image: "1",
        admin_id: data.admin_id,
        access_key: access_key,
        authtoken: data.token,
        newdata: data.newdata,
      }),
    }).then((response) => response.json());
  }

  load_route_report(data) {
    return fetch(api_link, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        load_route_report: "1",
        admin_id: data.admin_id,
        access_key: access_key,
        authtoken: data.token,
        route: data.route,
      }),
    }).then((response) => response.json());
  }

  load_report_customer(data) {
    return fetch(api_link, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        load_report_customer: "1",
        admin_id: data.admin_id,
        access_key: access_key,
        authtoken: data.token,
        datefrom: data.datefrom,
        dateto: data.dateto,
        customer_id: data.customer_id,
      }),
    }).then((response) => response.json());
  }

  load_worker(data) {
    return fetch(api_link, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        load_worker: "1",
        admin_id: data.admin_id,
        access_key: access_key,
        authtoken: data.token,
      }),
    }).then((response) => response.json());
  }

  load_report_worker(data) {
    return fetch(api_link, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        load_report_worker: "1",
        admin_id: data.admin_id,
        access_key: access_key,
        authtoken: data.token,
        datefrom: data.datefrom,
        dateto: data.dateto,
        worker_id: data.worker_id,
      }),
    }).then((response) => response.json());
  }

  add_product(data) {
    return fetch(api_link, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        add_product: "1",
        admin_id: data.admin_id,
        access_key: access_key,
        authtoken: data.token,
        data: data,
      }),
    }).then((response) => response.json());
  }

  edit_bulk_rate(data) {
    return fetch(api_link, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        edit_bulk_rate: "1",
        admin_id: data.admin_id,
        access_key: access_key,
        authtoken: data.token,
        data: data,
      }),
    }).then((response) => response.json());
  }

  edit_stock(data) {
    return fetch(api_link, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        edit_stock: "1",
        admin_id: data.admin_id,
        access_key: access_key,
        authtoken: data.token,
        data: data,
      }),
    }).then((response) => response.json());
  }

  edit_customer_route(data) {
    return fetch(api_link, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        edit_customer_route: "1",
        admin_id: data.admin_id,
        access_key: access_key,
        authtoken: data.token,
        data: data,
      }),
    }).then((response) => response.json());
  }

  edit_customer_products(data) {
    return fetch(api_link, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        edit_customer_products: "1",
        admin_id: data.admin_id,
        access_key: access_key,
        authtoken: data.token,
        data: data,
      }),
    }).then((response) => response.json());
  }

  edit_product(data) {
    return fetch(api_link, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        edit_product: "1",
        admin_id: data.admin_id,
        access_key: access_key,
        authtoken: data.token,
        data: data,
      }),
    }).then((response) => response.json());
  }

  edit_worker_product(data) {
    return fetch(api_link, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        edit_worker_products: "1",
        admin_id: data.admin_id,
        access_key: access_key,
        authtoken: data.token,
        data: data,
      }),
    }).then((response) => response.json());
  }

  load_product(data) {
    return fetch(api_link, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        load_product: "1",
        admin_id: data.admin_id,
        access_key: access_key,
        authtoken: data.token,
        data: data,
      }),
    }).then((response) => response.json());
  }

  load_customer_single(data) {
    return fetch(api_link, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        load_customer_single: "1",
        admin_id: data.admin_id,
        access_key: access_key,
        authtoken: data.token,
        data: data,
      }),
    }).then((response) => response.json());
  }

  load_worker_single(data) {
    return fetch(api_link, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        load_worker_single: "1",
        admin_id: data.admin_id,
        access_key: access_key,
        authtoken: data.token,
        worker_id: data.worker_id,
      }),
    }).then((response) => response.json());
  }

  edit_worker_route(data) {
    return fetch(api_link, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        edit_worker_route: "1",
        admin_id: data.admin_id,
        access_key: access_key,
        authtoken: data.token,
        route: data.route,
        worker_id: data.worker_id,
      }),
    }).then((response) => response.json());
  }

  edit_worker(data) {
    return fetch(api_link, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        edit_worker: "1",
        admin_id: data.admin_id,
        access_key: access_key,
        authtoken: data.token,
        worker_name: data.worker_name,
        status: data.status,
        password: data.password,
        mobile: data.mobile,
        worker_id: data.worker_id,
        // route: data.route,
      }),
    }).then((response) => response.json());
  }

  edit_customer(data) {
    return fetch(api_link, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        edit_customer: "1",
        admin_id: data.admin_id,
        access_key: access_key,
        authtoken: data.token,
        data: data,
      }),
    }).then((response) => response.json());
  }

  add_customer(data) {
    return fetch(api_link, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        add_customer: "1",
        admin_id: data.admin_id,
        access_key: access_key,
        authtoken: data.token,
        data: data,
      }),
    }).then((response) => response.json());
  }

  add_worker(data) {
    return fetch(api_link, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        add_worker: "1",
        admin_id: data.admin_id,
        access_key: access_key,
        authtoken: data.token,
        data: data,
      }),
    }).then((response) => response.json());
  }

  clear_device(data) {
    return fetch(api_link, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        clear_device: "1",
        admin_id: data.admin_id,
        access_key: access_key,
        authtoken: data.token,
        worker_id: data.worker_id,
      }),
    }).then((response) => response.json());
  }

  load_route_sequence(data) {
    return fetch(api_link, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        load_route_sequence: "1",
        admin_id: data.admin_id,
        access_key: access_key,
        authtoken: data.token,
      }),
    }).then((response) => response.json());
  }

  load_customer_sequence(data) {
    return fetch(api_link, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        load_customer_sequence: "1",
        admin_id: data.admin_id,
        access_key: access_key,
        authtoken: data.token,
        route: data.route,
      }),
    }).then((response) => response.json());
  }

  new_sequencer(data) {
    return fetch(api_link, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        new_sequencer: "1",
        admin_id: data.admin_id,
        access_key: access_key,
        authtoken: data.token,
        newdata: data.newdata,
      }),
    }).then((response) => response.json());
  }
  delete_entry(data) {
    return fetch(api_link, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        delete_entry: "1",
        admin_id: data.admin_id,
        access_key: access_key,
        authtoken: data.token,
        entry_id: data.entry_id,
      }),
    }).then((response) => response.json());
  }

  single_bill(data) {
    return fetch(api_link, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        single_bill_pdf_format: "1",
        admin_id: data.admin_id,
        access_key: access_key,
        authtoken: data.token,
        customer_id: data.customer_id,
        from_date: data.from_date,
        invoice_date: data.invoice_date,
      }),
    }).then((response) => response.json());
  }

  single_card(data) {
    return fetch(api_link, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        single_card_pdf_format: "1",
        admin_id: data.admin_id,
        access_key: access_key,
        authtoken: data.token,
        customer_id: data.customer_id,
        from_date: data.from_date,
      }),
    }).then((response) => response.json());
  }

  all_bill(data) {
    return fetch(api_link, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        all_bill_pdf_format: "1",
        admin_id: data.admin_id,
        access_key: access_key,
        authtoken: data.token,
        from_date: data.from_date,
        invoice_date: data.invoice_date,
      }),
    }).then((response) => response.json());
  }
  all_card(data) {
    return fetch(api_link, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        all_card_pdf_format: "1",
        admin_id: data.admin_id,
        access_key: access_key,
        authtoken: data.token,
        from_date: data.from_date,
      }),
    }).then((response) => response.json());
  }

  addTransaction(data) {
    return fetch(api_link_worker, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: JSON.stringify({
        admin: "1",
        add_transaction: "1",
        access_key: access_key_worker,
        device_id: data.device_id,
        admin_id: data.admin_id,
        customer_id: data.customer_id,
        bottles: data.bottles,
        worker_id: data.worker_id,
        milliseconds: data.milliseconds,
        cash: data.cash,
        datetime: data.date,
      }),
    }).then((response) => response.json());
  }

  addpayment(data) {
    return fetch(api_link_worker, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: JSON.stringify({
        admin: "1",
        add_payment: "1",
        access_key: access_key_worker,
        device_id: data.device_id,
        admin_id: data.admin_id,
        customer_id: data.customer_id,
        pay_amt: data.pay_amt,
        worker_id: data.worker_id,
        milliseconds: data.milliseconds,
        datetime: data.date,
      }),
    }).then((response) => response.json());
  }

  load_generated_bills(data) {
    return fetch(api_link, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: JSON.stringify({
        load_generated_bills: "1",
        access_key: access_key,
        authtoken: data.token,
        admin_id: data.admin_id,
      }),
    }).then((response) => response.json());
  }
}
