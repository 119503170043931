import React from "react";
import { BsFillFileEarmarkPlusFill } from "react-icons/bs";
import { FiEdit } from "react-icons/fi";
import { Main_Color } from "../../Theme/Theme";
const RawItemsCard = (props) => {
  const i = props.item;
  return (
    <div key={i.id}>
      <div
        style={{
          display: "flex",
          flex: 1,
          backgroundColor: "#ffffff",
          justifyContent: "space-between",
          alignItems: "center",
          borderRadius: 10,
          padding: 10,
          marginTop: 10,
        }}
      >
        <div style={{ paddingLeft: 20, display: "flex", alignItems: "center" }}>
          <div
            onClick={() => props.image(i)}
            style={{ paddingRight: 20, color: "#585858" }}
          >
            <img src={i.image} style={{ height: "45px", width: "45px" }} />
          </div>
          <div>
            <div style={{ color: "#000000", fontSize: 18 }}>
              {i.product_name}
            </div>
            <div
              style={{
                color: i.stock >= 0 ? "green" : "red",
                fontSize: 16,
                marginTop: "5px",
              }}
            >
              Stock - {i.stock}
            </div>
          </div>
        </div>
        <div
          style={{
            marginRight: 40,
            display: "flex",
            justifyContent: "space-around",
            alignItems: "center",
          }}
        >
          {/* <div
            onClick={() => props.edit_modal(i)}
            style={{
              height: "40px",
              width: "55px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <FiEdit style={{ fontSize: 30, color: "green" }} />
          </div> */}
          <div
            onClick={() => props.stock(i)}
            style={{
              height: "40px",
              width: "55px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <BsFillFileEarmarkPlusFill
              style={{ fontSize: 30, color: Main_Color }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default RawItemsCard;
