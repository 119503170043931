import React, { Component } from "react";
import { Main_Color } from "../../Theme/Theme";
import Backend from "../../Backend/Backend";
import "./Register.css";
import { Link } from "react-router-dom";

const backend = new Backend();

export default class Register extends Component {
  constructor() {
    super();
    this.state = {
      mobile: "",
      password: "",
      name: "",
      email: "",
      company_name: "",
    };
  }

  onAccountRegiter() {
    if (this.state.name == "") {
      alert("Invalid Name");
    } else if (this.state.email == "") {
      alert("Invalid Email");
    } else if (this.state.company_name == "") {
      alert("Invalid Company Name");
    } else if (this.state.mobile == "" || this.state.mobile.length < 10) {
      alert("Invalid Mobile Number");
    } else if (this.state.password == "") {
      alert("Invalid Password");
    } else {
      let data = {
        name: this.state.name,
        company_name: this.state.company_name,
        mobile_number: this.state.mobile,
        email: this.state.email,
        password: this.state.password,
      };
      backend.register(data).then((r) => {
        if (r.error == "False") {
          alert(r.message);
          setTimeout(() => {
            window.location = "/Login";
          }, 2000);
        } else {
          alert(r.message);
        }
      });
    }
  }

  render() {
    return (
      <div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flex: 1,
            height: "100vh",
            backgroundColor: "#f2f2f2",
          }}
        >
          <div
            style={{
              height: "600px",
              width: "500px",
              backgroundColor: "#ffffff",
              justifyContent: "center",
              alignItems: "center",
              display: "flex",
              flexDirection: "column",
              borderRadius: 10,
            }}
          >
            <div
              style={{
                flex: 1,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <img
                src={require("../../Image/count.png")}
                style={{ borderRadius: 20, height: 80 }}
              />
            </div>
            <div style={{ flex: 0.5 }}>
              <div
                style={{
                  fontSize: 30,
                  color: "#585858",
                  fontWeight: "bold",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                Create your free account
              </div>
            </div>

            <div style={{ flex: 1 }}>
              <div
                style={{
                  color: "#585858",
                  marginBottom: 7,
                  fontWeight: "bold",
                  fontSize: 13,
                }}
              >
                Name
              </div>
              <div>
                <input
                  autofocus="true"
                  onChange={(e) => {
                    this.setState({ name: e.target.value });
                  }}
                  value={this.state.name}
                  style={{
                    height: "40px",
                    width: "43vh",
                    borderRadius: 5,
                    borderStyle: "solid",
                    borderWidth: 2,
                    borderColor: "#585858",
                    paddingLeft: 10,
                    fontSize: 18,
                    fontWeight: "bold",
                  }}
                />
              </div>
            </div>
            <div style={{ flex: 1 }}>
              <div
                style={{
                  color: "#585858",
                  marginBottom: 7,
                  fontWeight: "bold",
                  fontSize: 13,
                }}
              >
                Email
              </div>
              <div>
                <input
                  onChange={(e) => {
                    this.setState({ username: e.target.value });
                  }}
                  value={this.state.username}
                  style={{
                    height: "40px",
                    width: "43vh",
                    borderRadius: 5,
                    borderStyle: "solid",
                    borderWidth: 2,
                    borderColor: "#585858",
                    paddingLeft: 10,
                    fontSize: 18,
                    fontWeight: "bold",
                  }}
                />
              </div>
            </div>
            <div style={{ flex: 1 }}>
              <div
                style={{
                  color: "#585858",
                  marginBottom: 7,
                  fontWeight: "bold",
                  fontSize: 13,
                }}
              >
                Company Name
              </div>
              <div>
                <input
                  onChange={(e) => {
                    this.setState({ password: e.target.value });
                  }}
                  value={this.state.password}
                  style={{
                    height: "40px",
                    width: "43vh",
                    borderRadius: 5,
                    borderStyle: "solid",
                    borderWidth: 2,
                    borderColor: "#585858",
                    paddingLeft: 10,
                    fontSize: 18,
                    fontWeight: "bold",
                  }}
                />
              </div>
            </div>
            <div style={{ flex: 1 }}>
              <div
                style={{
                  color: "#585858",
                  marginBottom: 7,
                  fontWeight: "bold",
                  fontSize: 13,
                }}
              >
                Mobile Number
              </div>
              <div>
                <input
                  onChange={(e) => {
                    this.setState({ mobile: e.target.value });
                  }}
                  value={this.state.mobile}
                  style={{
                    height: "40px",
                    width: "43vh",
                    borderRadius: 5,
                    borderStyle: "solid",
                    borderWidth: 2,
                    borderColor: "#585858",
                    paddingLeft: 10,
                    fontSize: 18,
                    fontWeight: "bold",
                  }}
                />
              </div>
            </div>
            <div style={{ flex: 1 }}>
              <div
                style={{
                  color: "#585858",
                  marginBottom: 7,
                  fontWeight: "bold",
                  fontSize: 13,
                }}
              >
                Password
              </div>
              <div>
                <input
                  onChange={(e) => {
                    this.setState({ mobile: e.target.value });
                  }}
                  value={this.state.mobile}
                  type="password"
                  style={{
                    height: "40px",
                    width: "43vh",
                    borderRadius: 5,
                    borderStyle: "solid",
                    borderWidth: 2,
                    borderColor: "#585858",
                    paddingLeft: 10,
                    fontSize: 18,
                    fontWeight: "bold",
                  }}
                />
              </div>
            </div>
            <div
              style={{
                flex: 1,
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <div
                onClick={() => this.onAccountRegiter()}
                style={{
                  color: "#fff",
                  marginBottom: 7,
                  height: "45px",
                  width: "40vh",
                  backgroundColor: Main_Color,
                  borderRadius: 10,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  fontWeight: "bold",
                  fontSize: "18",
                }}
              >
                Submit
              </div>
              <Link
                to="/Login"
                style={{ textDecoration: "none", color: "#585858" }}
              >
                <div
                  style={{
                    fontWeight: "bold",
                    color: Main_Color,
                    fontSize: 14,
                    marginTop: 10,
                    marginBottom: 10,
                  }}
                >
                  Back To Login ?
                </div>
              </Link>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
