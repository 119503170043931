import * as React from "react";
import { useAlert } from "react-alert";
import Modal from "@mui/material/Modal";
import Add from "./Add/Add";
import Edit from "./Edit/Edit";
import Product from "./Product/Product";
import { Main_Color } from "../../Theme/Theme";
import { BsPlusSquareFill } from "react-icons/bs";
import { FcManager } from "react-icons/fc";
import CaptainCard from "./CaptainCard";
import Backend from "../../Backend/Backend";
import { AiFillCloseSquare } from "react-icons/ai";
import { InputText } from "primereact/inputtext";
import hotkeys from "hotkeys-js";

const backend = new Backend();
export default function Captain() {
  const input1 = React.useRef();

  const [open, setOpen] = React.useState(false);
  const [data, setData] = React.useState([]);
  const [data_active, setData_Active] = React.useState([]);
  const [data_deactive, setData_Deactive] = React.useState([]);
  const [data_search, setData_Search] = React.useState([]);
  const [route, setRoute] = React.useState([]);
  const [loading, setLoading] = React.useState(true);
  const [token, setToken] = React.useState(localStorage.getItem("token"));
  const [adminid, setAdminid] = React.useState(localStorage.getItem("adminid"));
  const [edit_modal, setEdit_modal] = React.useState(false);
  const [product_modal, setProduct_modal] = React.useState(false);
  const [editdata, setEditData] = React.useState("");
  const [search, setSearch] = React.useState("");
  const [active, setActive] = React.useState(false);
  const alert = useAlert();

  React.useEffect(() => {
    hotkeys("esc", async (event, handler) => {
      event.preventDefault();
      handleClose();
      handleClose_edit();
    });
    hotkeys("s", async (event, handler) => {
      event.preventDefault();
      input1.current.focus();
    });
    loadconent();
  }, []);

  const loadconent = () => {
    setLoading(true);
    var data = {
      token: token,
      admin_id: adminid,
    };
    backend.load_worker(data).then((r) => {
      if (r.error === "False") {
        var data_active = r.data.filter((x) => x.status == 1);
        var data_deactive = r.data.filter((x) => x.status == 0);
        setData_Active(data_active);
        setData_Deactive(data_deactive);
        setData(data_active);
        setData_Search(r.data);
        let arrayObj = r.route;
        arrayObj = arrayObj.map((item) => {
          return {
            value: item,
            label: item,
          };
        });
        setRoute(arrayObj);
        setLoading(false);
      } else {
        alert.error(r.message);
        setLoading(false);
      }
    });
  };

  const handleOpen = () => setOpen(true);

  const handleClose = () => setOpen(false);

  const handleOpen_edit = (i) => {
    setEditData(i);
    setEdit_modal(true);
  };

  const handleClose_edit = () => {
    setEdit_modal(false);
    setEditData("");
  };
  const handleOpen_Product = (i) => {
    setEditData(i);
    setProduct_modal(true);
  };

  const handleClose_Product = () => {
    setProduct_modal(false);
    setEditData("");
  };

  const ActiveActive = () => {
    setActive(false);
    setData(data_active);
  };
  const ActiveDeactive = () => {
    setActive(true);
    setData(data_deactive);
  };

  const onSearch = (e) => {
    setSearch(e);
    if (e.length > 1) {
      let filteredData = [];
      for (var i = 0; i < data_search.length; i++) {
        if (data_search[i].name.toLowerCase().includes(e.toLowerCase())) {
          filteredData.push(data_search[i]);
        }
      }
      setData(filteredData);
    } else {
      setData(data_search);
    }
  };

  return (
    <div style={{ flex: 1, backgroundColor: "#f2f2f2" }}>
      <div
        style={{
          display: "flex",
          height: "100px",
          backgroundColor: "#ffffff",
          flex: 1,
          alignItems: "center",
        }}
      >
        <div
          style={{
            display: "flex",
            padding: 20,
            color: Main_Color,
            fontSize: 22,
            paddingLeft: 40,
            flex: 1,
            alignItems: "center",
            justifyContent: "flex-start",
          }}
        >
          <FcManager style={{ fontSize: 55 }} />
          <div style={{ paddingLeft: 20, fontSize: 22 }}>Captain</div>

          <div
            style={{
              paddingRight: 20,
              fontSize: 22,
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
              flex: 1,
            }}
          >
            <div
              onClick={() => (active ? ActiveActive() : ActiveDeactive())}
              style={{
                marginRight: 20,
                fontSize: 22,
                backgroundColor: active ? "green" : "red",
                padding: 10,
                borderRadius: 7,
                color: "#ffffff",
              }}
            >
              {active ? "Active" : "Deactive"}
            </div>
            <InputText
              ref={input1}
              placeholder="Captain Search...."
              value={search}
              style={{
                width: "300px",
                borderRadius: 0,
                borderTopLeftRadius: 10,
                borderBottomLeftRadius: 10,
              }}
              onChange={(e) => onSearch(e.target.value)}
            />

            <div
              onClick={() => {
                setSearch("");
                loadconent();
              }}
              style={{
                height: "45px",
                backgroundColor: Main_Color,
                width: "45px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                borderTopRightRadius: 10,
                borderBottomRightRadius: 10,
              }}
            >
              <AiFillCloseSquare
                style={{
                  color: "#ffffff",
                  height: 40,
                  width: 40,
                }}
              />
            </div>
          </div>
        </div>
        <div
          onClick={handleOpen}
          style={{
            display: "flex",
            padding: 20,
            color: Main_Color,
            fontSize: 22,
            paddingRight: 40,
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <BsPlusSquareFill style={{ fontSize: 40 }} />
        </div>
      </div>
      <div style={{ padding: 10 }}>
        <div
          style={{
            flex: 1,
            height: "83vh",
            borderRadius: 10,
            overflowY: "scroll",
          }}
        >
          {loading === true ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flex: 1,
                backgroundColor: "#ffffff",
                height: "100%",
              }}
            >
              <img src={require("../../Image/loading.gif")} />
            </div>
          ) : data.length > 0 ? (
            data.map((i, t) => {
              return (
                <CaptainCard
                  item={i}
                  index={t}
                  edit_modal={(i) => handleOpen_edit(i)}
                  product_modal={(i) => handleOpen_Product(i)}
                />
              );
            })
          ) : (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flex: 1,
                backgroundColor: "#ffffff",
                height: "100%",
                flexDirection: "column",
              }}
            >
              <img src={require("../../Image/noData.gif")} height={"300px"} />
              <div style={{ fontSize: 22, color: "gray" }}>
                No Captain Found
              </div>
            </div>
          )}
        </div>
      </div>

      <Modal open={open} onClose={handleClose}>
        <Add
          close={(a) => {
            if (a == "a") {
              handleClose(false);
            } else {
              handleClose(false);
              loadconent();
            }
          }}
          route={route}
        />
      </Modal>
      <Modal open={edit_modal} onClose={handleClose_edit}>
        <Edit
          close={(a) => {
            if (a == "a") {
              handleClose_edit(false);
            } else {
              loadconent();
              handleClose_edit(false);
            }
          }}
          data={editdata}
          route={route}
        />
      </Modal>

      <Modal open={product_modal} onClose={handleClose_Product}>
        <Product
          close={(a) => {
            if (a == "a") {
              handleClose_Product(false);
            } else {
              loadconent();
              handleClose_Product(false);
            }
          }}
          data={editdata}
          route={route}
        />
      </Modal>
    </div>
  );
}
