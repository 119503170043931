import React, { Component } from "react";
import { FcManager } from "react-icons/fc";
import { Main_Color } from "../../Theme/Theme";
import PlansCard from "./PlansCard";

const ActivePlans = () => {
  return (
    <div style={{ flex: 1, backgroundColor: "#f2f2f2" }}>
      <div
        style={{
          display: "flex",
          height: "100px",
          backgroundColor: "#ffffff",
          flex: 1,
          alignItems: "center",
        }}
      >
        <div
          style={{
            display: "flex",
            padding: 20,
            color: Main_Color,
            fontSize: 22,
            paddingLeft: 40,
            flex: 1,
            alignItems: "center",
            justifyContent: "flex-start",
          }}
        >
          <FcManager style={{ fontSize: 55 }} />
          <div style={{ paddingLeft: 20, fontSize: 22 }}>Plans</div>
          <div
            style={{
              paddingLeft: 20,
              fontSize: 25,
              flex: 1,
              textAlign: "right",
              fontWeight: "bold",
              color: "#000000",
              paddingRight: "20px",
            }}
          >
            Choose your plan
          </div>
        </div>
      </div>

      <div style={{ height: "100px", padding: "10px" }}>
        <div
          style={{
            backgroundColor: "#ffffff",
            height: "100%",
            borderRadius: "10px",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            paddingLeft: "20px",
          }}
        >
          <div style={{ flex: 1 }}>
            <div style={{ fontSize: 16, color: "gray" }}>
              Planning to stick around for while?
            </div>
            <div style={{ fontSize: 20, color: "#000000", marginTop: "7px" }}>
              Get 1 months free with new register.
            </div>
          </div>
          <div
            style={{
              paddingRight: "20px",
              fontSize: 22,
              color: "red",
              display: "flex",
            }}
          >
            <div>Contact</div>
            <div> - 8128980289</div>
          </div>
        </div>
      </div>

      <div style={{ padding: 10 }}>
        <div
          style={{
            flex: 1,
            borderRadius: 10,
            overflowY: "scroll",
            display: "flex",
            flex: 1,
          }}
        >
          <div
            style={{
              flex: 1,
              justifyContent: "center",
              alignItems: "center",
              display: "flex",
            }}
          >
            <PlansCard
              month={28}
              title={"Startup"}
              price={"Free"}
              msg={"ideal to get started with perfect delivery management app"}
            />
          </div>
          <div
            style={{
              flex: 1,
              justifyContent: "center",
              alignItems: "center",
              display: "flex",
            }}
          >
            <PlansCard
              month={180}
              title={"Growth"}
              price={"₹ 3000"}
              msg={"ideal to get started with perfect delivery management app"}
            />
          </div>
          <div
            style={{
              flex: 1,
              justifyContent: "center",
              alignItems: "center",
              display: "flex",
            }}
          >
            <PlansCard
              title={"Bussiness"}
              month={365}
              price={"₹ 5000"}
              msg={"ideal to get started with perfect delivery management app"}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ActivePlans;
