import React, { useEffect, useState } from "react";
import { AiOutlineCloudDownload } from "react-icons/ai";
import Backend from "../../Backend/Backend";
import { Main_Color } from "../../Theme/Theme";
import { useAlert } from "react-alert";
const backend = new Backend();

export default function History() {
  const alert = useAlert();
  const [selected, setSelected] = useState(0);
  const [data, setData] = useState([]);
  const [all_bills, setall_bills] = useState([]);
  const [all_cards, setall_cards] = useState([]);
  const [single_bills, setsingle_bills] = useState([]);
  const [single_cards, setsingle_cards] = useState([]);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    loadconent();
  }, []);

  const loadconent = () => {
    setLoading(true);
    var data = {
      token: localStorage.getItem("token"),
      admin_id: localStorage.getItem("adminid"),
    };
    backend.load_generated_bills(data).then((r) => {
      if (r.error === "False") {
        setData(r.single_bills);
        setall_bills(r.all_bills);
        setall_cards(r.all_cards);
        setsingle_bills(r.single_bills);
        setsingle_cards(r.single_cards);
        setSelected(1);
        setLoading(false);
      } else {
        alert.error("You just broke something!");
        setLoading(false);
      }
    });
  };

  const filterFile = (a) => {
    if (a == "1") {
      setSelected(1);
      setData(single_bills);
    } else if (a == "2") {
      setSelected(2);
      setData(all_bills);
    } else if (a == "3") {
      setSelected(3);
      setData(single_cards);
    } else if (a == "4") {
      setSelected(4);
      setData(all_cards);
    }
  };

  return (
    <div style={{ padding: 10, flex: 1 }}>
      <div
        style={{
          height: "55px",
          display: "flex",
          justifyContent: "space-around",
          alignItems: "center",
        }}
      >
        <div
          onClick={() => filterFile("1")}
          style={{
            height: "35px",
            width: "100px",
            backgroundColor: selected == 1 ? Main_Color : "#ffffff",
            borderRadius: 10,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            color: selected == 1 ? "#ffffff" : Main_Color,
          }}
        >
          Bill Single
        </div>
        <div
          onClick={() => filterFile("2")}
          style={{
            height: "35px",
            width: "100px",
            backgroundColor: selected == 2 ? Main_Color : "#ffffff",
            borderRadius: 10,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            color: selected == 2 ? "#ffffff" : Main_Color,
          }}
        >
          Bill All
        </div>
        <div
          onClick={() => filterFile("3")}
          style={{
            height: "35px",
            width: "100px",
            backgroundColor: selected == 3 ? Main_Color : "#ffffff",
            borderRadius: 10,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            color: selected == 3 ? "#ffffff" : Main_Color,
          }}
        >
          Card Single
        </div>
        <div
          onClick={() => filterFile("4")}
          style={{
            height: "35px",
            width: "100px",
            backgroundColor: selected == 4 ? Main_Color : "#ffffff",
            borderRadius: 10,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            color: selected == 4 ? "#ffffff" : Main_Color,
          }}
        >
          Card All
        </div>
      </div>
      <div style={{ height: "70vh", overflowY: "scroll" }}>
        {data.length > 0
          ? data.map((i, t) => {
              console.log(i);
              return (
                <div
                  style={{
                    backgroundColor: "#ffffff",
                    flex: 1,
                    padding: 10,
                    borderRadius: 10,
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "center",
                    marginBottom: "10px",
                  }}
                >
                  <div>{i.image_name}</div>
                  <div onClick={() => window.open(i.path)}>
                    <AiOutlineCloudDownload
                      style={{
                        fontSize: 30,
                        color: Main_Color,
                        marginRight: 20,
                      }}
                    />
                  </div>
                </div>
              );
            })
          : null}
      </div>
    </div>
  );
}
