import React, { useEffect, useState } from "react";
import "./Product.css";
import { InputText } from "primereact/inputtext";
import { Main_Color } from "./../../../Theme/Theme";
import { AiFillCloseCircle } from "react-icons/ai";
import Backend from "../../../Backend/Backend";
import ReactLoading from "react-loading";
import { useAlert } from "react-alert";
import { Checkbox } from "primereact/checkbox";
const backend = new Backend();

const Product = (props) => {
  const [token, setToken] = useState(localStorage.getItem("token"));
  const [adminid, setAdminid] = useState(localStorage.getItem("adminid"));
  const [product, setProduct] = useState([]);
  const [loading, setLoading] = useState(false);

  const alert = useAlert();

  useEffect(() => {
    load_worker_single();
  }, []);

  const load_worker_single = () => {
    setLoading(true);
    let data = {
      token: token,
      admin_id: adminid,
      worker_id: props.data.id,
    };

    backend.load_worker_single(data).then((r) => {
      setLoading(false);
      if (r.error == "False") {
        setProduct(r.data.products);
      } else {
        alert.show(r.message);
      }
    });
  };

  const edit_product = () => {
    var new_product = [];
    var final_product = "";
    product.map((i, t) => {
      if (i.value.activate == 1) {
        new_product.push(i.value.product_id);
      }
    });

    final_product = new_product;

    let data = {
      token: token,
      admin_id: adminid,
      worker_id: props.data.id,
      products: JSON.stringify(final_product),
    };
    setLoading(true);

    backend.edit_worker_product(data).then((r) => {
      setLoading(false);
      if (r.error == "False") {
        props.close();
        alert.show(r.message);
      } else {
        alert.show(r.message);
      }
    });
  };

  const MultiSelect = (a, b) => {
    var check = 0;
    if (a == true) {
      check = 1;
    } else {
      check = 0;
    }
    var newone = product;

    newone.map((i, t) => {
      if (i.id == b) {
        newone[t].value.activate = check;
        setProduct(newone);
      }
    });
    setProduct([]);
    setTimeout(() => {
      setProduct(newone);
    }, 100);
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flex: 1,
        height: "100vh",
      }}
    >
      <div
        style={{
          height: "90%",
          width: "50%",
          backgroundColor: "#f2f2f2",
          borderRadius: 10,
          display: "flex",
          alignSelf: "center",
          flexDirection: "column",
        }}
      >
        <div
          style={{
            padding: 20,
            justifyContent: "space-between",
            display: "flex",
            backgroundColor: Main_Color,
            borderTopLeftRadius: 10,
            borderTopRightRadius: 10,
            color: "#ffffff",
            alignItems: "center",
          }}
        >
          <div style={{ fontSize: 22 }}>Product List</div>
          <div onClick={() => props.close("a")}>
            <AiFillCloseCircle style={{ height: "40px", width: "40px" }} />
          </div>
        </div>
        <div
          style={{
            height: "55vh",
            overflow: "scroll",
            padding: "10px",
            paddingLeft: "30px",
            paddingRight: "30px",
          }}
        >
          {loading ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flex: 1,
                backgroundColor: "#f2f2f2",
                height: "100%",
              }}
            >
              <ReactLoading
                type="spin"
                color={Main_Color}
                height={"40px"}
                width={"40px"}
              />
            </div>
          ) : (
            product.map((i, t) => {
              return (
                <div
                  key={i.id + new Date()}
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flex: 1,
                  }}
                >
                  <div
                    style={{
                      height: "45px",
                      backgroundColor: "#ffffff",
                      marginBottom: "10px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      flex: 1,
                      borderRadius: 10,
                    }}
                  >
                    <div style={{ paddingLeft: "20px" }}>
                      <img
                        src={i.value.image}
                        style={{ height: "30px", width: "30px" }}
                      />
                    </div>
                    <div style={{ flex: 1, paddingLeft: "20px" }}>
                      {i.value.product_name}
                    </div>
                    <div style={{ paddingRight: "20px" }}>
                      <Checkbox
                        onChange={(e) => MultiSelect(e.checked, i.id)}
                        checked={product[t].value.activate == 0 ? false : true}
                      ></Checkbox>
                    </div>
                  </div>
                </div>
              );
            })
          )}
        </div>
        {loading === true ? (
          <div
            style={{
              alignSelf: "center",
              marginTop: 40,
              height: "50px",
              width: "200px",
              backgroundColor: Main_Color,
              borderRadius: 10,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              color: "#ffffff",
              fontSize: 18,
            }}
          >
            <ReactLoading type={"bars"} color={"#fff"} height={45} width={45} />
          </div>
        ) : (
          <div
            onClick={() => edit_product()}
            style={{
              alignSelf: "center",
              marginTop: 40,
              height: "50px",
              width: "200px",
              backgroundColor: Main_Color,
              borderRadius: 10,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              color: "#ffffff",
              fontSize: 18,
            }}
          >
            Submit
          </div>
        )}
      </div>
    </div>
  );
};

export default Product;
